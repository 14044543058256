import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import images from '../img/images';
import moment from 'moment';
import {formatType, getUnits, round} from '../actions';
import Feedback from './Interview/Feedback';

class SummaryRow extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if(this.props.closed) {
            this.setState({hideFirst: true})
        }
    }

    renderFirstOrder() {
        const {first, products, taxTotal, coupon} = this.props;
        const {hideFirst} = this.state;

        if(first) {
            let subtotal = 0;
            const rows = [];
             for(let i = 0; i < products.length; i++) {
                const product = products[i];
                const {
                    articles,
                    moreThanAMonth,
                    pricePerArticle,
                    brandName,
                    displayName
                } = product;
                const price = articles * pricePerArticle /100;
                const units = getUnits(product.articleType, articles).toLowerCase();

                subtotal += price;
                const formattedType = formatType(product.productType);
                rows.push(
                    <Row className="summary-product-row no-margin">
                        <Col xs={4} className="prediction-thumbnail">
                            <img src={product.imageUrl} alt={brandName}/>
                        </Col>
                        <Col xs={8}>
                            <Row className="prediction-product-name">
                                <div>
                                    <div><b>{product.brand}</b> {formattedType}<br/>
                                    <span className="prediction-product-name-detail">{displayName}</span></div>
                                    <div className="summary-product-price">${price.toFixed(2)} <span className="prediction-product-name-detail">({product.articles} {units} @ ${(pricePerArticle/100).toFixed(2)} each)</span></div>
                                    <div className={!moreThanAMonth ? 'hide' : 'summary-removed-item'}>*{articles > 1 ? 'These' : 'This'} should last you more<br/>than a month!</div>
                                </div>
                            </Row>
                        </Col>
                        <Col xs={12} className={i === (products.length - 1) ? "summary-indented-hr" : ""}>
                            <hr />
                        </Col>
                    </Row>
                )
            }

            if(coupon && taxTotal === undefined) {
                rows.push(
                    <Row className="no-margin">
                        <Col xs={8} className="summary-subtotal-text">Subtotal:</Col>
                        <Col xs={4} className="summary-subtotal-price">${round(subtotal).toFixed(2)}</Col>
                    </Row>
                )
                rows.push(
                    <Row className="no-margin">
                        <Col xs={8} className={`green ${'summary-subtotal-text'}`}>Savings ({coupon.percentDiscount}% off):</Col>
                        <Col xs={4} className={`green ${'summary-subtotal-price'}`}>-${round(coupon.percentDiscount * subtotal/100).toFixed(2)}</Col>
                    </Row>
                )
                rows.push(
                    <Row className="no-margin">
                        <Col xs={6} className="summary-total-text">Pre-Tax Total:</Col>
                        <Col xs={6} className="summary-total-price text-center">${(round(subtotal) - round(coupon.percentDiscount * subtotal/100)).toFixed(2)}</Col>
                    </Row>
                )
            } else if(taxTotal !== undefined) {
                rows.push(
                    <Row className="no-margin">
                        <Col xs={8} className="summary-subtotal-text">Tax:</Col>
                        <Col xs={4} className="summary-subtotal-price">${round(taxTotal).toFixed(2)}</Col>
                    </Row>
                )
                rows.push(
                    <Row className="no-margin">
                        <Col xs={8} className="summary-subtotal-text">Subtotal:</Col>
                        <Col xs={4} className="summary-subtotal-price">${round(subtotal).toFixed(2)}</Col>
                    </Row>
                )
                if(coupon) {
                    rows.push(
                        <Row className="no-margin">
                            <Col xs={8} className={`green summary-subtotal-text`}>Savings ({coupon.percentDiscount}% off):</Col>
                            <Col xs={4} className={`green summary-subtotal-price`}>-${round(coupon.percentDiscount * subtotal/100).toFixed(2)}</Col>
                        </Row>
                    )
                }
                rows.push(
                    <Row className="no-margin">
                        <Col xs={6} className="summary-total-text">Total:</Col>
                        <Col xs={6} className="summary-total-price text-center">${coupon ? (round(subtotal) - round(coupon.percentDiscount * subtotal/100) + round(taxTotal)).toFixed(2) : (round(subtotal) + round(taxTotal)).toFixed(2)}</Col>
                    </Row>
                )
            } else {
                rows.push(
                    <Row className="no-margin">
                        <Col xs={6} className="summary-total-text">Subtotal:</Col>
                        <Col xs={6} className="summary-total-price text-center">${round(subtotal).toFixed(2)}</Col>
                    </Row>
                )
            }

            return (
                <div className="summary-group">
                    {this.props.expectedDeliveryDate ? <h2 className="interview-question-header">Arriving {moment.utc(this.props.expectedDeliveryDate).format('MMM Do')}</h2> : null}
                    <p className={ this.props.complete ? "interview-question-detail" : "hide"}>Now leave the rest to us</p>
                    <Row className={`no-margin ${this.props.complete ? "" : "hide"}`}>
                        <Col xs={8}>
                            <div className={!hideFirst ? "hide" : "prediction-options-label"}>View Supply Drop Contents</div>
                        </Col>
                        <Col xs={4} className="prediction-options-chevron">
                            <span onClick={() => this.setState({hideFirst: !hideFirst})} className={`fa ${!hideFirst ? "fa-chevron-up" : "fa-chevron-down"}`} />
                        </Col>
                    </Row>
                    <div className={hideFirst ? "hide" : "summary-rows-container"}>{rows}</div>
                </div>
            )
        }
    }

    renderComingOrder() {
        const {showOptions} = this.state;
        const {first, products, taxRate, following} = this.props;
        if(!first) {
            let subtotal = 0;
            const rows = [];
            for(let i = 0; i < products.length; i++) {
                const product = products[i];
                const {articles} = product;
                const units = getUnits(product.articleType, articles).toLowerCase();

                const price = articles * product.price;
                subtotal += price;
                const formattedType = formatType(product.type);
                rows.push(
                    <Row className={`summary-product-row ${articles ? '' : 'zero'}`}>
                        <Col xs={4} className="prediction-thumbnail">
                            <img src={product.imageUrl} alt={product.brand}/>
                        </Col>
                        <Col xs={8}>
                            <Row className="prediction-product-name">
                                <div>
                                <div><b>{product.brand}</b> {formattedType}<br/>
                                    <span className="prediction-product-name-detail">{product.name}</span></div>
                                    <div className="summary-product-price">${price.toFixed(2)} <span className="prediction-product-name-detail">({product.articles} {units} @ ${(product.price).toFixed(2)} each)</span></div>
                                    <div className="summary-removed-item">{articles ? '' : `*You should still be good on ${formattedType}!`}</div>
                                </div>
                            </Row>
                        </Col>
                        <Col xs={12} className={i === (products.length - 1) ? "hide" : ""}>
                            <hr />
                        </Col>
                    </Row>
                )
            }

            const taxTotal = taxRate * subtotal;
            return (
                <div className="summary-group">
                    <Feedback question="summary-projection" isPostInterview={true} />
                    <p className="interview-section-header">WHAT'S NEXT?</p>
                    {this.props.expectedDeliveryDate ? <h2 className="interview-question-header">Arriving {moment.utc(this.props.expectedDeliveryDate).format('MMM Do')}</h2> : null }
                    {!following ? this.renderComingOrderText() : null}
                    <hr className="summary-full-hr" />
                    <Row>
                        <Col xs={8}>
                            <div className={showOptions ? "hide" : "prediction-options-label"}>View Supply Drop Contents</div>
                        </Col>
                        <Col xs={4} className="prediction-options-chevron">
                            <span onClick={() => this.setState({showOptions: !showOptions})} className={`fa ${showOptions ? "fa-chevron-up" : "fa-chevron-down"}`} />
                        </Col>
                    </Row>
                    <div className={showOptions ? '' : 'hide'}>{rows}</div>
                    <div className="summary-indented-hr bottom">
                        <hr/>
                    </div>
                    <Row>
                        <Col xs={6} className="summary-total-text">Estimate:</Col>
                        <Col xs={6} className="summary-total-price text-center">${subtotal.toFixed(2)}</Col>
                    </Row>
                </div>
            )
        }
    }

    renderComingOrderText() {
        return (
                <p className="interview-question-detail">
                    We've already calculated your next shipment. We’ll check in a week before it ships to make sure you’re supplied with everything you need! Feel free to text us if you need to change it!
                </p>
            )       
    }

    render() {
        return (
            <div>
                {this.renderFirstOrder()}
                {this.renderComingOrder()}
            </div>
        )
    }
}

export default SummaryRow;