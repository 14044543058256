import React, { Fragment } from "react";
import * as Sentry from '@sentry/browser';
import { CartSummary } from "./cart-summary";
import {createOrder} from '../../actions';
import {connect} from 'react-redux';
import images from "../../img/images";
import { Alert } from "react-bootstrap";

class ConfirmationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleResize = this.handleResize.bind(this);
        this.continue = this.continue.bind(this);
    }

    componentDidUpdate() {
        const {pushing, sending} = this.state;
        const {createOrderSuccess, createOrderError} = this.props;
        if(createOrderSuccess && !pushing) {
            this.setState({pushing: true})
            sessionStorage.setItem('stripeOrder', JSON.stringify(createOrderSuccess))
            this.props.history.push('/ordercomplete')
        }
        if(createOrderError && sending) {
            this.setState({sending: false})
        }
    }

    componentDidMount() {
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const source = JSON.parse(sessionStorage.getItem('source'));

        if(customer && !this.state.customer) {
            this.setState({customer: customer})
        }

        if(source && !this.state.source) {
            this.setState({source})
        }
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    renderDesktopCartSummary() {
        const {isMobile} = this.state;
        if(!isMobile) {
            return (
                <div className="desktop-cart-summary">
                    <CartSummary
                        coupon={this.props.coupon}
                        totals={this.props.totals}
                        lineItems={this.props.items}
                    />
                </div>
            );   
        }
    }

    renderError() {
        const {createOrderError, sending} = this.props;
        if(createOrderError && !sending) {
            try {
                if(createOrderError.data === "customer already has an order") {
                    return(
                        <Alert variant="danger" className="checkout-error">
                            Seems like you may already have a pending order.<br/>Please ensure information is entered correctly and try again.
                        </Alert>
                    )
                } else {
                    return(
                        <Alert variant="danger" className="checkout-error">
                            Something went wrong.<br/>Please ensure information is entered correctly and try again.
                        </Alert>
                    )
                }
            } catch(err) {
                Sentry.captureException(err);
                console.error(err);
                return(
                    <Alert variant="danger" className="checkout-error">
                        Something went wrong.<br/>Please ensure information is entered correctly and try again.
                    </Alert>
                )
            }
        }
    }

    continue() {
        this.setState({sending: true})
        try {
            const order = JSON.parse(sessionStorage.getItem('order'));
            if(order) {
                this.props.createOrder(order);
            } else {
                this.setState({error: "Data missing. Please reenter billing information and try again.", sending: false})
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
            this.setState({error: "Data missing. Please reenter billing information and try again.", sending: false})
        }
    }

    renderCustomerDetails() {
        const {
            customer,
            source
        } = this.state;
        if(customer && source) {
            const {
                shipping,
                phone,
                firstName,
                lastName,
                email
            } = customer;

            const {
                name,
                address,
                city,
                state,
                zipcode,
                deliveryInstructions
            } = shipping;

            const {
                card,
                owner
            } = source;

            const {
                brand,
                exp_month,
                exp_year,
                last4,
            } = card;

            return (
                <div id="confirmation-main">
                    <div id="confirmation-header">Confirmation</div>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span className="checkout-stage-name">Account</span>
                    </div>
                    <div className="checkout-confirm-details">
                        <p>
                            {firstName} {lastName}<br/><br/>
                            {phone}<br/><br/>
                            {email}
                        </p>
                    </div>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">2</div>
                        <span className="checkout-stage-name">Deliver To</span>
                    </div>
                    <div className="checkout-confirm-details">
                        <p>
                            {name}<br/><br/>
                            {address}<br/><br/>
                            {city}, {state} {zipcode}<br/><br/>
                            {deliveryInstructions ? 
                                <p>
                                    <u>Delivery Instructions</u><br/>
                                    {deliveryInstructions}
                                </p> :
                                null
                            }
                        </p>
                    </div>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">3</div>
                        <span className="checkout-stage-name">Billing</span>
                    </div>
                    <div className="ms-row vc checkout-confirm-details">
                        <p>
                            {owner.name}<br/><br/>
                            {brand} ending in {last4}<br/><br/>
                            Exp: {parseInt(exp_month) < 9 ? `0${exp_month}` : exp_month}/{exp_year}
                        </p>
                    </div>
                </div>
            );
        }
    }

    render() {        
        const {sending} = this.state;
        if(!sending) {
            return (
                <Fragment>
                    <div className="checkout-main">
                        {this.renderCustomerDetails()}
                        <button className="dashboard-btn dashboard-save cta" id="complete-order-btn" onClick={this.continue}>Complete Order</button>
                        {this.renderError()}
                    </div>
                    {this.renderDesktopCartSummary()}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="checkout-main">
                        <div className="text-center">
                            <img src={images.predicting} className="pulse animated-super-slow infinite delay1" />
                        </div>
                        <div className="interview-copy">
                            <b>Processing your order...</b><br/>
                            Please do not close or navigate away from window.
                        </div>
                        {this.renderError()}
                    </div>
                    {this.renderDesktopCartSummary()}
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const {createOrderSuccess, createOrderError, creatingOrder} = state.order;
    return {createOrderSuccess, createOrderError, creatingOrder};
}

export default connect(mapStateToProps, {
    createOrder
})(ConfirmationForm);