import React, { Component } from 'react';
import '../Style/docDialog.css'

class DocDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            agreed: false
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', (e) => this.handleKeydown(e));
    }

    handleKeydown(e) {
        if(e.keyCode === 27) {
            this.props.hide();
        }
    }

    renderDoc() {
        const {doc} = this.props;
        if(!!doc) {
            return (
                <iframe id="doc-frame" src={`https://${window.location.host}/${doc}.html`} />
            )
        }
    }

    render() {
        const {doc, hide} = this.props;

        let docName = ""
        if(doc === 'Privacy_Policy') {
            docName = 'Privacy Policy'
        }  if(doc === "Terms_of_Use") {
            docName = 'Terms of Use';
        }
       
        // TODO: Check if needAgreement on terms
        return (
            <div className="modal-container">
                <div className="doc-dialog flex-column text-center">
                    <div className="dialog-title-text">
                        {docName}
                    </div>
                    <div className="dialog-body">
                        {this.renderDoc()}
                        <div className="flex-row flex-space-between button-wrapper">
                        <button className="interview-continue-button" onClick={hide}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DocDialog;