import React from 'react';
import images from '../../img/images';

const Saving = ({success, error, rounded, close, body, tryAgain}) => {
    const renderTryAgain = () => {
        return (
            <button className="btn btn-small btn-add manifest-add-btn" onClick={tryAgain}>Try again</button>
        )
    }

    if(error) {
        return (
            <div className="modal-container light">
                <div className="signin-modal-body">
                    <div className="signin-modal-tab-container">
                        <div onClick={close} className={`signin-modal-tab active ${rounded ? 'rounded' : ''} ${close ? "" : "hide"}`}>CLOSE [-]</div>
                    </div>
                    <img src={images.crooked_parachute} className="signin-modal-landing-parachute pulse animated-super-slow infinite delay1" />
                    <img src={images.modal_cloud_dark} className="signin-modal-cloud-dark pulse animated-slow infinite delay1" />
                    <img src={images.cloud_card_six} className="signin-modal-cloud-light pulse animated-slow infinite" />
                    <div className="signin-modal-section-header red bold">WHOOPS! SOMETHING WENT WRONG</div>
                    <div className="signin-modal-main-header">{body ? body : <span>Your changes<br/>were not saved.</span>}</div>
                    {tryAgain ? renderTryAgain() : ""}
                </div>
            </div>
        )
    } else if(success) {
        return (
            <div className="modal-container light">
                <div className="signin-modal-body">
                    <div className="signin-modal-tab-container">
                        <div onClick={close} className={`signin-modal-tab active ${rounded ? 'rounded' : ''} ${close ? "" : "hide"}`}>CLOSE [-]</div>
                    </div>
                    <img src={images.crooked_parachute} className="signin-modal-landing-parachute pulse animated-super-slow infinite delay1" />
                    <img src={images.modal_cloud_dark} className="signin-modal-cloud-dark pulse animated-slow infinite delay1" />
                    <img src={images.cloud_card_six} className="signin-modal-cloud-light pulse animated-slow infinite" />
                    <div className="signin-modal-section-header">YOUR CHANGES HAVE BEEN SAVED</div>
                    <div className="signin-modal-main-header">Success!</div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="modal-container light">
                <div className="signin-modal-body">
                    <div className="signin-modal-tab-container">
                        <div onClick={close} className={`signin-modal-tab active ${rounded ? 'rounded' : ''} ${close ? "" : "hide"}`}>CLOSE [-]</div>
                    </div>
                    <img src={images.crooked_parachute} className="signin-modal-landing-parachute pulse animated-super-slow infinite delay1" />
                    <img src={images.modal_cloud_dark} className="signin-modal-cloud-dark pulse animated-slow infinite delay1" />
                    <img src={images.cloud_card_six} className="signin-modal-cloud-light pulse animated-slow infinite" />
                    <div className="signin-modal-section-header">JUST <i>ONE</i> MOMENT PLEASE</div>
                    <div className="signin-modal-main-header">Saving Changes...</div>
                </div>
            </div>
        )
    }
}

export default Saving;