import React, {Component, Fragment} from 'react';
import Header from '../Header';
import Countdown from '../Countdown';
import images from "../../img/images";
import * as Sentry from '@sentry/browser';
import {Alert} from 'react-bootstrap';
import {Auth} from 'aws-amplify';
import {
    marginForCountdown,
    validateCheckoutForm,
    validateCityAndNames,
    validateStreetAddress,
    validateZip,
    clusterProductsForCart
} from '../../actions';
import {CartSummary} from './cart-summary';
import AddressForm from './address-form';
import {States} from './states';
import Feedback from '../Interview/Feedback';

class Shipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: {
                currency: "",
                subTotal: "",
                taxTotal: "",
                ultimateTotal: ""
            },
            shippingAddress: {
                city: "",
                line1: "",
                line2: "",
                name: "",
                state: States[0].iso,
                zipcode: "",
                deliveryInstructions: ""
            },
            isLine1Valid: true,
            isLine2Valid: true,
            isCityValid: true,
            isZipValid: true,
            isStateValid: true,
            isAddressNameValid: true,
        };

        this.handleResize = this.handleResize.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateAddress = this.validateAddress.bind(this);
        this.continue = this.continue.bind(this);
    }

    componentDidMount() {
        const aptCampaignShipping = JSON.parse(sessionStorage.getItem('aptCampaignShipping'));
        if(aptCampaignShipping) {
            const {firstName, lastName, line1, line2, state, city, zipcode} = aptCampaignShipping;
            this.setState({shippingAddress: {name: `${firstName} ${lastName}`, line1, line2, state, city, zipcode}});
        }
        const { totals } = this.state;
        const products = JSON.parse(sessionStorage.getItem('products'));

        let total = 0;
        const selectedProducts = [];

        Auth.currentAuthenticatedUser().then(data => {
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
        });

        if(products) {
            clusterProductsForCart(products).then(res => {
                const {sorted, total} = res;
                // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
                // if(coupon) {
                //     this.setState({coupon});
                // }
                // totals.ultimateTotal = coupon ? totals.subTotal - (totals.subTotal * coupon.percentDiscount/100) : totals.subTotal;
        
                totals.ultimateTotal = totals.subTotal = total;
                this.setState({options: sorted})
            })
        } else {
            this.props.history.push('/checkout');
        }

        if (!this.state.order) {
            const order = JSON.parse(sessionStorage.getItem('order'));
            this.setState({ order, totals })
        }

        const dropoffZipcodes = JSON.parse(sessionStorage.getItem('dropoffZipcodes'));
        if (dropoffZipcodes) this.setState({ dropoffZipcodes })
        else this.props.getLanding();


        const initZipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({initZipcode})

        if(initZipcode) marginForCountdown();
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    componentDidUpdate() {
        const { landing } = this.props;
        const { error, validating } = this.props;

        if (error && !this.state.error && !validating) {
            this.setState({ error });
        }

        if (landing && !this.state.dropoffZipcodes) {
            this.setState({ dropoffZipcodes: landing.dropoffZipcodes });
        }
    }

    validateField(name, value) {
        const {dropoffZipcodes, shippingAddress, zipcodes} = this.state;
        const goldenTicket = JSON.parse(sessionStorage.getItem('goldenTicket'));
        let rc = false;
        if(goldenTicket && typeof goldenTicket === 'string' && goldenTicket.toLowerCase() === "sd4me") {
            rc = true;
        }

        const zips = dropoffZipcodes ? dropoffZipcodes : zipcodes;

        try {
            switch(name) {
                case 'name':
                    this.setState({ isAddressNameValid: validateCityAndNames(value), showErrors: false });
                    break;
                case 'line1':
                    this.setState({ isLine1Valid: validateStreetAddress(value), showErrors: false });
                    break;
                case 'city':
                    this.setState({ isCityValid: validateCityAndNames(value), showErrors: false });
                    break;
                case 'state':
                    shippingAddress.state = value;
                    this.setState({shippingAddress});
                    if(value !== "TX" && !rc) {
                        this.setState({isStateValid: false, errorMessage: "Supply Drop does not currently serve your area.", showErrors: true})
                    } else {
                        this.setState({isStateValid: true, errorMessage: "", showErrors: false})
                    }
                    break;
                case 'zipcode':
                    if(zips && zips.length) {
                        const index = zips.indexOf(value);
                        if(!(index > -1) && !rc) this.setState({isZipValid: false, errorMessage: "Supply Drop does not currently serve your area.", showErrors: true})
                        else this.setState({ isZipValid: validateZip(value), errorMessage: "", showErrors: false })
                    }
                    else this.setState({ isZipValid: validateZip(value), errorMessage: "", showErrors: false })
                    break;
                default:
                    break;
            }
        }
        catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    validateAddress() {
        let {
            shippingAddress,
            isAddressNameValid,
            isLine1Valid,
            isCityValid,
            isStateValid,
            isZipValid
        } = this.state;

        const {
            line1,
            name,
            city,
            state,
            zipcode
        } = shippingAddress;

        if(isAddressNameValid) isAddressNameValid = name;
        if(isLine1Valid) isLine1Valid = line1;
        if(isCityValid) isCityValid = city;
        if(isStateValid) isStateValid = state;
        if(isZipValid) isZipValid = zipcode;

        const fields = [
            isAddressNameValid,
            isLine1Valid,
            isCityValid,
            isStateValid,
            isZipValid,
        ];

        this.setState({
            isAddressNameValid,
            isLine1Valid,
            isCityValid,
            isStateValid,
            isZipValid
        })
        
        return validateCheckoutForm(fields);
    }

    async continue() {
        const {shippingAddress, loading} = this.state;
        if(!loading) {
            this.setState({loading: true});

            const invalid = await this.validateAddress()
            if(!invalid) {
                const customer = JSON.parse(sessionStorage.getItem('customer'));
                try {
                    const order = JSON.parse(sessionStorage.getItem('order'));    
                    // const {coupon} = this.props;
                    order.customer = customer;
                    order.customer.shipping = shippingAddress;
                    order.customer.shipping.address = shippingAddress.line1;
                    if(shippingAddress.deliveryInstructions) {
                        order.customer.shipping.deliveryInstructions = shippingAddress.deliveryInstructions;
                    } else {
                        delete order.customer.shipping.deliveryInstructions;
                    }
                    order.customer.shipping.address += shippingAddress.line2 ? `, ${shippingAddress.line2}` : '';
                    delete order.customer.shipping.line1;
                    delete order.customer.shipping.line2;
                    // order.coupon = coupon ? coupon.id : undefined;
                    sessionStorage.setItem('customer', JSON.stringify(order.customer));
                    sessionStorage.setItem('order', JSON.stringify(order));
                    this.props.history.push('/checkout/billing');
                } catch(err) {
                    console.error(err);

                }
            } else {
                this.setState({showErrors: true, loading: false})
            }
        }
    }

    renderCountdown() {
        if(this.state.initZipcode) return  <Countdown />
    }

    renderDesktopCartSummary() {
        const {isMobile, options, totals, coupon} = this.state;
        if(!isMobile && totals && options) {
            return (
                <div className="desktop-cart-summary">
                    <CartSummary
                        coupon={coupon}
                        totals={totals}
                        lineItems={options}
                    />
                </div>
            );   
        }
    }
    renderErrorMessage() {
        const {
            isAddressNameValid,
            isLine1Valid,
            isCityValid,
            isZipValid,
            isStateValid,
            showErrors,
            errorMessage
        } = this.state;
        if(showErrors) {
            let errors = 0;
            let validations = [
                isAddressNameValid,
                isLine1Valid,
                isCityValid,
                isZipValid,
                isStateValid
            ]
            validations.forEach(v => {
                if(!v) errors++
            })
            if(errorMessage) {
                return (
                    <div className="alert alert-danger">{errorMessage}</div>
                )
            } else if(errors > 1) {
                return (
                    <div className="alert alert-danger">Please fill in all required fields</div>
                )
            } else {
                if(!isAddressNameValid) {
                    return (
                        <div className="alert alert-danger">Please enter name for shipping address</div>
                    )
                } else if (!isLine1Valid) {
                    return (
                        <div className="alert alert-danger">Please enter valid street address</div>
                    )
                } else if (!isCityValid) {
                    return (
                        <div className="alert alert-danger">Please enter valid city</div>
                    )
                } else if (!isZipValid) {
                    return (
                        <div className="alert alert-danger">Please enter valid zipcode</div>
                    )
                } else {
                    return (
                        <div className="alert alert-danger">Please select a state</div>
                    )
                }
            }
        }
    }

    renderMobileCartSummary() {
        const {isMobile, coupon, totals, options} = this.state;
        if(isMobile && totals && options) {
            return (
                <div className="checkout-body space-bottom">
                    <CartSummary
                        coupon={coupon}
                        totals={totals}
                        lineItems={options}
                    />
                </div>
            );   
        }
    }

    renderForm() {
        const {
            shippingAddress,
            isAddressNameValid,
            isLine1Valid,
            isCityValid,
            isZipValid,
            isStateValid
        } = this.state;
        return (
            <Fragment>
                <form>
                    <div className="input-wrapper flex-row flex-wrap">
                        <AddressForm
                            show={true}
                            type="shipping"
                            address={shippingAddress}
                            isAddressNameValid= {isAddressNameValid}
                            isLine1Valid= {isLine1Valid}
                            isCityValid= {isCityValid}
                            isZipValid= {isZipValid}
                            isStateValid= {isStateValid}
                            onChange={a => this.setState({ shippingAddress: a, errorMessage: '' })}
                            onBlur={(name, value) => this.validateField(name, value)}
                        />
                    </div>
                    {this.renderErrorMessage()}
                </form>
            </Fragment>
        )
    }

    render() {
        const { initZipcode } = this.state;
        if (this.state.options && !this.state.isBilling) {
            return (
                <div>
                    {this.renderCountdown()}
                    <Header history={this.props.history} spaceTop={initZipcode} />
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.goBack()} className="interview-icon interview-back-arrow" />
                        <div></div>
                    </div>
                    <div className="checkout-container">
                        <div className="checkout-body">
                            <div className="checkout-main">
                                <div className="checkout-stage-container">
                                    <div className="checkout-stage-number">2</div>
                                    <span className="checkout-stage-name">Deliver To</span>
                                </div>
                                {this.renderForm()}
                                <button className="dashboard-btn dashboard-save cta" onClick={this.continue}>Continue</button>
                            </div>
                            {this.renderDesktopCartSummary()}
                        </div>
                        {this.renderMobileCartSummary()}
                    </div>
                    <Feedback question="shipping" />
                </div>
            )
        } else {
            return <Header history={this.props.history} />
        }
    }
}

export default Shipping;