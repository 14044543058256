import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import Cleaning from './Cleaning';
import Cooking from './Cooking';
import DishwashingProducts from './DishwashingProducts';
import DishwashingFrequency from './DishwashingFrequency';

class KitchenFlow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: "onCooking"
        };

        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.clearUnsavedChanges = this.clearUnsavedChanges.bind(this);
    }
    
    componentDidMount() {
        const {interview} = this.props;
        this.setState({interview});
    }

    componentDidUpdate() {
        const {unsavedChanges} = this.props;
        if(unsavedChanges && !this.state.unsavedChanges) {
            this.setState({unsavedChanges})
        }
    }

    componentWillUnmount() {
        const {cookingFrequency, dishwashing, housekeeping} = this.state;
        const kitchenQuestions = {
            cookingFrequency,
            housekeeping,
            dishwashing
        };
        this.props.updateInterview(kitchenQuestions);
    }

    sendInterview(housekeeping) {
        const {cookingFrequency, dishwashing} = this.state;
        const kitchenQuestions = {
            cookingFrequency,
            housekeeping,
            dishwashing
        };
        this.props.updateInterview(kitchenQuestions);
    }

    next(question) {
        try {
            const questionKey = Object.keys(question)[0];
            const value = Object.values(question)[0];
            let currentQuestion;
            let complete;
            switch(questionKey) {
                case 'cookingFrequency':
                    currentQuestion = 'onDishwashingFrequency';
                    break;
                case 'dishwashing':
                    // currentQuestion = this.state.currentQuestion === 'onDishwashingProducts' || !value.frequency ? 'onHousekeeping' : 'onDishwashingProducts';
                    currentQuestion = 'onHousekeeping';
                    break;
                default:
                    complete = true;
                    break;
            }
            this.setState({[questionKey]: value})
            if(complete) {
                this.sendInterview(value);
            } else {
                this.setState({currentQuestion})
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    back() {
        const {currentQuestion} = this.state;
        try {
            switch(currentQuestion) {
                case 'onDishwashingFrequency':
                    this.setState({currentQuestion: 'onCooking'});
                    break;
                // case 'onDishwashingProducts':
                //     this.setState({currentQuestion: 'onDishwashingFrequency'});
                //     break;
                case 'onHousekeeping':
                    // if(this.state.dishwashingFrequency) {
                    //     this.setState({currentQuestion: 'onDishwashingProducts'});
                    // } else {
                        this.setState({currentQuestion: 'onDishwashingFrequency'});
                    // }
                    break;
                default:
                    this.props.setUnsavedChanges();
                    break;
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    clearUnsavedChanges() {
        this.props.clearUnsavedChanges();
        this.setState({unsavedChanges: false});
    }

    renderCurrentQuestion() {
        const {currentQuestion, unsavedChanges} = this.state;
        if(!unsavedChanges) {
            switch(currentQuestion) {
                case 'onDishwashingFrequency':
                    return <DishwashingFrequency isModal={true} next={this.next} back={this.back} />
                // case 'onDishwashingProducts':
                //     return <DishwashingProducts isModal={true} next={this.next} back={this.back} />
                case 'onHousekeeping':
                    return <Cleaning isModal={true} next={this.next} back={this.back} />
                default:
                    return <Cooking isModal={true} next={this.next} back={this.back} />
            }
        }
    }

    renderCancelModal() {
        const {unsavedChanges, onDishwashingFrequency, onDishwashingProducts, onCoooking, cookingFrequency} = this.state;
        if(unsavedChanges) {
            let questions;
            if(cookingFrequency === undefined) {
                questions = "There are only 3-4 questions";
            } else if(onDishwashingFrequency) {
                questions = "There are only 2 questions"
                // questions = "There are only 2-3 questions"
            // } else if(onDishwashingProducts) {
            //     questions = "There are only 2 questions"
            } else {
                questions = "This is the last question"
            }
            return (
                <div>
                    <div className="signin-modal-tab-container">
                        <div className="cancel-modal-body">
                            <p className="modal-title">
                                Are you sure you want to cancel?
                            </p>
                            <hr />
                            <p className="modal-p">
                                {questions} before we have your recommendation for kitchen & cleaning supplies!
                            </p>
                        </div>
                    </div>
                    <div className="cancel-modal-button-row">
                        <button className="dashboard-btn dashboard-submit white" onClick={this.clearUnsavedChanges}>Don't cancel and go back</button>
                        <button className="dashboard-btn dashboard-cancel-btn" onClick={this.props.close}>Yes, cancel</button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderCurrentQuestion()}
                {this.renderCancelModal()}    
            </div>
        )
    }
}

export default KitchenFlow;