import React from 'react';
import images from '../../img/images';

const MoreInfo = ({close, copy}) => {
    return (
        <div className="modal-container light">
            <div className="interview-outer">
                <div className="interview-nav-container">
                    <div className="interview-top-row">
                        <div></div>
                        <img src={images.x_icon} onClick={close} className="interview-icon interview-info-icon" />
                    </div>
                </div>
                <div className="interview-inner">
                    <div className="interview-copy more-info-copy">
                        {copy}
                    </div>
                    <div className="interview-continue-wrapper">
                        <button className="interview-continue-button" onClick={close}>Go Back</button>
                        <p className="interview-enter-text">press <b>enter</b></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoreInfo;