import React, { Fragment } from 'react';
import { States } from './states';
import {Col, Row} from 'react-bootstrap';

export default function AddressForm({
    type,
    address,
    onChange,
    onBlur,
    isAddressNameValid,
    isLine1Valid,
    isCityValid,
    isZipValid,
    isStateValid,
    show
 }) {
    
    const states = States.map(s => (
        <option key={s.iso} value={s.iso}>
            {s.name}
        </option>
    ));
    if(show) {
        return (
            <Fragment>
                <div className="modal-input-container full">
                    <label className="modal-label">Name</label>
                    <input
                        className={`modal-input${isAddressNameValid ? '' : ' invalid'}`}
                        type="text"
                        placeholder="Name or company name*"
                        id="name"
                        value={address.name}
                        onChange={e => onChange({ ...address, name: e.currentTarget.value })}
                        onBlur={e => onBlur('name', e.currentTarget.value)}
                    />
                </div>
                <div className="modal-input-container">
                    <label className="modal-label">Street Address 1</label>
                    <input
                        className={`modal-input${isLine1Valid ? '' : ' invalid'}`}
                        type="text"
                        id="line1"
                        placeholder="Address*"
                        value={address.line1}
                        onChange={e => onChange({ ...address, line1: e.currentTarget.value })}
                        onBlur={e => onBlur('line1', e.currentTarget.value)}
                    />
                </div>
                <div className="modal-input-container">
                    <label className="modal-label">Street Address 2</label>
                    <input
                        className="modal-input"
                        type="text"
                        id="line2"
                        placeholder="Apt, suite, etc."
                        value={address.line2}
                        onChange={e => onChange({ ...address, line2: e.currentTarget.value })}
                        onBlur={e => onBlur('line2', e.currentTarget.value)}
                    />
                </div>
                <div className="modal-input-container">
                    <label className="modal-label">City</label>
                    <input
                        className={`modal-input${isCityValid ? '' : ' invalid'}`}
                        type="text"
                        placeholder="City*"
                        id="city"
                        value={address.city}
                        onChange={e => onChange({ ...address, city: e.currentTarget.value })}
                        onBlur={e => onBlur('city', e.currentTarget.value)}
                    />
                </div>
                <div className="modal-input-container">
                    <label className="modal-label">State</label>
                    <select
                        name="state"
                        id="state"
                        className={`modal-input${isStateValid ? '' : ' invalid'}`}
                        value={address.state}
                        onChange={e => onBlur('state', e.currentTarget.value)}
                    >
                        {states}
                    </select>
                </div>
                <div className="modal-input-container">
                    <label className="modal-label">Zipcode</label>
                    <input
                        className={`modal-input${isZipValid ? '' : ' invalid'}`}
                        type="text"
                        id="zipcode"
                        placeholder="Postal code*"
                        value={address.zipcode}
                        onChange={e => onChange({ ...address, zipcode: e.currentTarget.value })}
                        onBlur={e => onBlur('zipcode', e.currentTarget.value)}
                    />
                </div>
                {type === "shipping" ? <div className="modal-input-container full">
                    <label className="modal-label">Delivery Instuctions</label>
                    <textarea
                        className="modal-input"
                        type="text"
                        name="delivery-instructions"
                        id="delivery-instructions"
                        placeholder="Delivery instructions"
                        value={address.deliveryInstructions}
                        onChange={e => onChange({ ...address, deliveryInstructions: e.currentTarget.value })}
                        onBlur={e => onBlur('deliveryInstructions', e.currentTarget.value)}
                    />
                </div> : null }
            </Fragment>
        );
    } else {
        return <div></div>
    }
}