const images = {
    logo_dark: require('./logo-supplydrop-orange.svg'),
    logo_light: require('./logo-supplydrop-white.png'),
    logo_beta: require('./logo_beta.svg'),
    summary_interstitial: require('./summary_interstitial.svg'),
    crooked_parachute: require('./icon-supplydrop-orange-light.svg'),
    confetti: require('./confetti.svg'),
    torn_border: require('./border-torn-paper.svg'),
    cloud_top_left: require('./cloud-light.png'),
    cloud_top_right: require('./cloud-transparent2.svg'),
    cloud_bottom_left: require('./cloud_half.png'),
    cloud_bottom_right: require('./cloud-transparent3.svg'),
    cloud_card_one: require('./cloud-transparent4.png'),
    cloud_card_two: require('./cloud-transparent2.svg'),
    cloud_card_six: require('./cloud-transparent6.png'),
    modal_cloud_light: require('./modal_cloud_light.svg'),
    modal_cloud_dark: require('./modal_cloud_dark.svg'),
    flying_box: require('./flying_box.svg'),
    flying_box_bg: require('./flying_box_bg.png'),
    predicting: require('./predicting.svg'),

    // landing
    landing_setup: require('./landing_setup.svg'),
    landing_personalized: require('./landing_personalized.svg'),
    landing_shipping: require('./landing_shipping.svg'),
    availability: require('./location-pin.svg'),
    availability_denied: require('./location-pin-close.svg'),

    // 404
    not_found: require('./img-404-tp.gif'),

    // cluster images
    everyday: require('./everyday.svg'),
    kitchen: require('./kitchen.svg'),
    everyday_cluster: require('./everyday_cluster.svg'),
    kitchen_cluster: require('./kitchen_cluster.svg'),
    laundry_cluster: require('./laundry_cluster.svg'),
    coming_soon: require('./coming_soon.png'),

    // interview header images
    household: require('./household.svg'),
    house: require('./house.svg'),
    bathrooms: require('./bathrooms.svg'),
    trashbag: require('./trashbag.svg'),
    cog: require('./loading_cog.svg'),
    laundryFrequency: require('./laundryFrequency.svg'),

    // kitchen header images
    cookingFrequency: require('./chefs.svg'),
    dishwashingFrequency: require('./DishwashingFrequency.svg'),
    dishwashingProducts: require('./sparkle.svg'),
    housekeeping: require('./broom.svg'),

    // product icons
    handSoap: require('./handSoap.svg'),
    handWipes: require('./handWipes.svg'),
    trashBin: require('./trashBin.svg'),
    tissues: require('./tissues.svg'),
    login: require('./login.svg'),

    // icons
    edit_gray: require('./menu_icons/edit_gray.svg'),
    edit_selected: require('./menu_icons/edit_selected.svg'),
    info: require('./info_icon.svg'),
    x_icon: require('./x_icon.svg'),
    back_arrow: require('./back_arrow.svg'),
    male_adult: require('./male_adult.svg'),
    female_adult: require('./female_adult.svg'),
    male_child: require('./male_child.svg'),
    female_child: require('./female_child.svg'),
    dog: require('./dog.svg'),
    cat: require('./cat.svg'),
    btn_close_x: require('./btn-close-x.svg'),
    checkmark: require('./checkmark.png'),
    accountHeader: require('./account-header-img.png'),
    pause: require('./pause-icon.png'),

    // tier emojis (badges)
    EcoFriendly: require('./EcoFriendly.png'),
    NationallyRecognizedBrandLead: require('./NationallyRecognizedBrandLead.png'),
    SupplyDropPick: require('./SupplyDropPick.png'),
    AllNatural: require('./AllNatural.png'),
    SensitiveSkin: require('./SensitiveSkin.png'),
    Value: require('./Value.png'),
    SocialGood: require('./SocialGood.png'),

    // BETA
    discount: require('./beta.svg'),

    // carousel
    carousel_parachute: require('./carousel-parachute.svg'),
    carousel_stocked: require('./carousel_stocked.svg'),
    carousel_direct: require('./carousel_direct.svg'),
    carousel_clock: require('./carousel_clock.png'),

    // Video
    landing_video: require('./landing_video.mp4'),
    hero: require('./hero-poster-image.jpg')
};

export default images;
