import React, {Component} from 'react';
import ProgressBar from '../ProgressBar';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import MoreInfo from '../MoreInfo';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreInfoCopy: (
                <div>You will receive an initial delivery of Kitchen & Cleaning Essentials you opt into followed by a monthly replenishment of those supplies based on your specific households needs and product preferences. Currently we offer hand soap, dish soap and dishwasher detergent.</div>
            )
        };
        this.handleKeydown = this.handleKeydown.bind(this);
        this.continue = this.continue.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    componentDidMount() {
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        if (hasKitchen === null) this.setState({hasKitchen: undefined});
        else this.setState({hasKitchen})

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    handleKeydown(e) {
        const {showInfo, hasKitchen} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else if (hasKitchen !== undefined) {
                sessionStorage.setItem('hasKitchen', JSON.stringify(hasKitchen));
                this.props.history.push('/start/1');
            }
        }
    }
    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderCountdown() {
        const {zipcode} = this.state;
        if(zipcode) return <Countdown spaceTop={true} />
    }

    continue(hasKitchen) {
        sessionStorage.setItem('hasKitchen', JSON.stringify(hasKitchen));
        this.props.history.push('/start/1')
    }

    render() {
        const {hasKitchen, zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={20} />
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row">
                            <img src={images.back_arrow} onClick={() => this.props.history.push('/start')} className="interview-icon interview-back-arrow" />
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                        </div>
                    </div>
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img"><img src={images.kitchen} alt="House" /></div>
                        <div className="interview-copy-container">
                            <h2 className="cluster-header">Kitchen & Cleaning Essentials</h2>
                            <p className="interview-copy">
                                We can also take care of your Kitchen & Cleaning Essentials.
                                Would you like to add any of the following supplies?
                                Don't worry, you will have an opportunity to adjust your order prior to checkout.
                            </p>
                            <h4>
                                What's Included
                            </h4>
                            <div className="checked-list">
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Hand Soap</div>
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Dish Soap</div>
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Dishwasher Detergent</div>
                            </div>
                        </div>
                        <div className="kitchen-pills-container">
                            <div className="tier-pill-wrapper">
                                <div id="brand-btn" className={`interview-pill ${ hasKitchen ? 'selected' : ''}`} onClick={() => this.continue(true)}>Yes! More convenience.</div>
                            </div>
                            <div className="tier-pill-wrapper">
                                <div id="brand-btn" className={`interview-pill ${ hasKitchen === false ? 'selected' : ''}`} onClick={() => this.continue(false)}>No. I got what I need.</div>
                            </div>
                        </div>
                    </div>
                    <Feedback question="kitchenStart" />
                </div>
            </div>
        )
    }
}

export default Start;