import React, {Component} from 'react';
import {Col, Row, Alert} from 'react-bootstrap';
import Feedback from '../Feedback';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';
import images from '../../../img/images';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Household extends Component {
    constructor(props) {
        super(props);

        this.state = {
            family: [],
            pets: {
                Cat: 0,
                Dog: 0
            },
            id: 0,
            moreInfoCopy: (
                <div>
                    Every household is unique. 
                    Some people have pets.
                    Some people work from home.
                    Some people have 8.5 bathrooms!
                    Understanding who lives in your home allows us to curate the perfect order just for you.
                </div>
            )
        };

        this.handleChange = this.handleChange.bind(this);
        this.renderPetRows = this.renderPetRows.bind(this);
        this.continue = this.continue.bind(this);
        this.addMember = this.addMember.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo, inputtingAge} = this.state;

        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } if(inputtingAge) {
                document.activeElement.blur();
                this.setState({inputtingAge: false, activeMember: undefined})
            } else {
                this.continue();
            }
        }
    }

    componentDidMount() {
        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();

        window.scrollTo(0, 0);
        const family = JSON.parse(sessionStorage.getItem('family'));
        const pets = JSON.parse(sessionStorage.getItem('pets'));

        if(family) this.setState({family, id: family.length});
        
        if(pets) this.setState({pets});
    }

    handleChange(age, id) {
        const {family} = this.state;
        const member = family.filter(m => m.id === id)[0];
        const newFamily = family.filter(m => m.id !== id);
        member.age = age;
        
        newFamily.push(member);
        newFamily.sort((a, b) => {
            return a.id - b.id;
        });

        this.setState({family: newFamily, needAges: false})
    }

    continue() {
        const {family, pets} = this.state;
        if(family.length) {
            let ages = true;
            family.forEach(member => {
                if(!member.age) {
                    ages = false;
                }
            })
            if(ages) {
                sessionStorage.setItem('family', JSON.stringify(family));
                sessionStorage.setItem('pets', JSON.stringify(pets));
                this.props.history.push('/start/2');
            } else {
                this.setState({needAges: true})
            }
        }
    }

    renderAlert() {
        const {needAges} = this.state;
        if(needAges) {
            return (
                <Alert key={0} variant="warning">Please specify an age for each family member</Alert>
            )
        }
    }

    renderPetRows() {
        const {pets} = this.state;
        const rows = [];       
        
        if(pets.Dog) {
            for(let i = 0; i < pets.Dog; i++){
                rows.push(
                    <div className="household-pet-wrapper">
                        <img src={images.x_icon} className="pet-x-icon" onClick={() => this.removePet("Dog")} />
                        <div className="interview-household-img"><img src={images.dog} className="household-icon"/></div>
                    </div>
                )
            }
        }
        if(pets.Cat) {
            for(let i = 0; i < pets.Cat; i++){
                rows.push( 
                    <div className="household-pet-wrapper">
                        <img src={images.x_icon} className="pet-x-icon" onClick={() => this.removePet("Cat")} />
                        <div className="interview-household-img"><img src={images.cat} className="household-icon"/></div>
                    </div>
                )
            }
        }

        return (
            <div className="household-icons-row">
                {rows}
            </div>
        )
    }

    removeFamilyMember(item) {
        const {family} = this.state;
        const index = family.map( item => { return item.id }).indexOf(item.id);
        const newFamily = [ ...family ];
        newFamily.splice(index, 1);

        this.setState({ family: newFamily });
    }

    removePet(type) {
        const {pets} = this.state;
        pets[type]--;
        this.setState({ pets });
    }

    renderTopRow() {
        return (
            <div className="interview-nav-container">
                <div className="interview-top-row">
                    <img src={images.back_arrow} onClick={() => this.props.history.push('/kitchen')} className="interview-icon interview-back-arrow" />
                    <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                </div>
            </div>
        )
    }

    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderFamily() {
        const {family, activeMember} = this.state;
        const rows = [];
        if(family.length) {
            family.map(member => {
                rows.push(
                    <div className="household-pill-container">
                        <img src={images.x_icon} className="household-x-icon" onClick={() => this.removeFamilyMember(member)} />
                        <div className={`household-pill ${activeMember === member.id ? "active" : ""}`} onClick={() => document.getElementById(`age-input-${member.id}`).focus()}>
                            <div className="household-pill-inner img"><img src={images[`${member.gender.toLowerCase()}_${member.type.toLowerCase()}`]} className="household-icon"/></div>
                            <div>
                                <input 
                                    id={`age-input-${member.id}`}
                                    autoFocus
                                    className="household-age-input"
                                    autoFocus
                                    type="number"
                                    value={member.age !== undefined ? member.age : ""}
                                    placeholder="Age"
                                    max="130"
                                    min="0"
                                    onSelect={() => this.setState({inputtingAge: true, activeMember: member.id})}
                                    onChange={(e) => this.handleChange(parseInt(e.target.value), member.id)}
                                />
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className="household-rows-container">
                {rows}
            </div>
        )
    }

    renderContinue() {
        const {family} = this.state;
        return (
            <div className="interview-continue-wrapper">
                <button disabled={!family.length} className="interview-continue-button" onClick={this.continue}>Continue</button>
                <p className={`interview-enter-text ${!family.length ? 'disabled': ''}`}>press <b>enter</b></p>
            </div>
        );
    }

    addMember(type, gender) {
        const {id, pets, family} = this.state;
        if(type === "Adult" || type === "Child") {
            family.push({type, gender, id, age: undefined, staysHome: false});
            this.setState({family, id: id + 1 });
        } else {
            pets[type]++;
            this.setState({pets});
        }
        const element = document.getElementById(`${type}${gender ? gender : ""}`);
        element.classList.add("fadeOutDown");
        setTimeout(function(){ element.classList.remove("fadeOutDown"); }, 1000);
    }

    renderAddButtons() {
        return (
            <div className="household-icons-container">
                <div className="household-icons-row">
                    <div id="AdultFemale" className="interview-household-img animated-medium" onClick={() => this.addMember("Adult", "Female")}><img src={images.female_adult} className="household-icon"/></div>
                    <div id="AdultMale" className="interview-household-img animated-medium" onClick={() => this.addMember("Adult", "Male")}><img src={images.male_adult} className="household-icon"/></div>
                    <div id="ChildFemale" className="interview-household-img animated-medium" onClick={() => this.addMember("Child", "Female")}><img src={images.female_child} className="household-icon"/></div>                
                    <div id="ChildMale" className="interview-household-img animated-medium" onClick={() => this.addMember("Child", "Male")}><img src={images.male_child} className="household-icon"/></div>
                    <div id="Dog" className="interview-household-img animated-medium" onClick={() => this.addMember("Dog")}><img src={images.dog} className="household-icon"/></div>    
                    <div id="Cat" className="interview-household-img animated-medium" onClick={() => this.addMember("Cat")}><img src={images.cat} className="household-icon"/></div>    
                </div>
            </div>
        )
    }

    renderMyHousehold() {
        const {family, pets} = this.state;
        let hasPets = 0;
        Object.values(pets).map(value => {
            hasPets += value;
        })
        if(family.length || hasPets) {
            return (
                <p className="interview-copy">My household:</p>
            )
        }
    }

    renderBirthdateCopy() {
        const {family} = this.state;
        if(family.length) {
            return (
                <p className="interview-copy">Your age matters because it helps us<br/>predict what you'll need.</p>
            )
        }
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown spaceTop={true} />
    }

    render() {
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        const {zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={hasKitchen ? 30 : 40} />
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
                <div className="interview-outer">
                    {this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img"><img src={images.household} alt="Household" /></div>
                        <p className="interview-copy">Who lives in your home?</p>
                        {this.renderAddButtons()}
                        {this.renderMyHousehold()}
                        {this.renderFamily()}
                        {this.renderPetRows()}
                        {this.renderBirthdateCopy()}
                        {this.renderAlert()}
                        {this.renderContinue()}
                    </div>
                    <Feedback question="household" />
                </div>
            </div>
        )
    }
}

export default Household;