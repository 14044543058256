import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import {Row, Col} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { formatType } from '../../actions';

class PastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const {
            expectedShipDate,
            expectedDeliveryDate,
            type,
            status,
            items,
            products,
            createdAt
        } = this.props;

        this.setState({
            expectedShipDate,
            expectedDeliveryDate,
            type,
            status,
            items,
            products,
            createdAt
        });
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 768 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth > 767 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    renderMobileProductsRow() {
        const {showProducts, isMobile} = this.state;
        if(isMobile && showProducts) {
            return (
                <Row className="order-row">
                    <Col xs={12}><div className="order-products-row">{this.renderProductsRow()}</div></Col>
                </Row>

            )
        }
    }

    renderProductsRow() {
        const {products} = this.state;
        if(products) {
            const topRow = [];
            Object.values(products).forEach(product => {
                const {articlesPerPackage, packages, articleType, imageUrl, brand, displayName, pricePerArticle, groupName, type} = product;
                const articles = articlesPerPackage * packages;
                const units = articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's');
                topRow.push(
                    <div className="order-product-container past" onClick={() => this.props.showDescription(product)}>
                        <div className="order-product">
                            <div className="order-frame-container">
                                <div className="order-product-frame">
                                    <img src={imageUrl} alt={`${brand} ${displayName}`} className="order-product-thumbnail" />
                                    <div className="order-product-details">
                                        <div><b>{brand}</b> {displayName}</div>
                                        <div>{formatType(type)}</div>
                                        <div>{articles} {units}</div>
                                        <div><b>${(articles * pricePerArticle/100).toFixed(2)}</b> @ ${(pricePerArticle/100).toFixed(2)} ea.</div>
                                    </div> 
                                    <div className="info-container">
                                        <svg className="" id={`${groupName}-svg`} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8"/>
                                            <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8"/>
                                            <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
            return topRow;
        }
    }

    renderDesktopProductsRow() {
        const {isMobile} = this.state;
        if(!isMobile) {
            return this.renderProductsRow();
        }
    }

    renderOrder() {
        const {
            expectedShipDate,
            expectedDeliveryDate,
            type,
            status,
            items,
            products,
            createdAt,
             showProducts
        } = this.state;
        if(
            expectedShipDate,
            expectedDeliveryDate,
            type,
            status,
            items,
            products,
            createdAt
        ) {
            try {
                let tax = 0;
                
                for(let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if(item.description === "Sales tax") {
                        tax = item.amount/100;
                        break;
                    }
                }
                let subTotal = 0;
                products.forEach(product => {
                    const {articlesPerPackage, packages, pricePerArticle} = product
                    subTotal += (articlesPerPackage * pricePerArticle * packages / 100)
                })
                return (
                    <div>
                        <Row className="order-row">
                            <Col xs={6} md={2} onClick={() => this.setState({showProducts: !showProducts})} className="order-label">{moment(expectedDeliveryDate).format('LL')}</Col>
                            <Col xs={8} className="desktop"><div className="order-products-row">{this.renderDesktopProductsRow()}</div></Col>
                            <Col xs={6} md={2} onClick={() => this.setState({showProducts: !showProducts})} className="text-right order-label">
                                <div className="order-total">
                                    ${(subTotal + tax).toFixed(2)}
                                    <svg data-tip data-for="manifest-total"  width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="interview-icon interview-info-icon">
                                        <circle className="info-circle" cx="10.5" cy="10" r="9.5" stroke="#183041" stroke-opacity="0.8"/>
                                        <path d="M13.5387 7.92904C13.5387 8.25456 13.491 8.54536 13.3955 8.80143C13.3 9.05751 13.1633 9.29405 12.9854 9.51107C12.8074 9.72808 12.5166 9.98416 12.113 10.2793L11.5921 10.6634C11.2796 10.8891 11.0474 11.1278 10.8955 11.3796C10.7436 11.627 10.6655 11.8982 10.6611 12.1934H9.52181C9.53049 11.8939 9.57606 11.6313 9.65853 11.4056C9.74533 11.1799 9.85601 10.9803 9.99056 10.8066C10.1251 10.633 10.277 10.479 10.4463 10.3444C10.6156 10.2055 10.787 10.0753 10.9606 9.95378C11.1342 9.82791 11.3035 9.70421 11.4684 9.58268C11.6377 9.45681 11.7874 9.31793 11.9176 9.16602C12.0479 9.01411 12.152 8.84266 12.2301 8.65169C12.3126 8.46072 12.3538 8.2372 12.3538 7.98112C12.3538 7.48633 12.1846 7.0957 11.846 6.80924C11.5118 6.52279 11.0409 6.37956 10.4333 6.37956C9.82563 6.37956 9.34386 6.53147 8.98796 6.83529C8.63205 7.13911 8.42372 7.55577 8.36296 8.08529L7.16504 8.00716C7.27789 7.14779 7.61643 6.48806 8.18066 6.02799C8.7449 5.56793 9.49143 5.33789 10.4202 5.33789C11.3881 5.33789 12.1498 5.56793 12.7054 6.02799C13.261 6.48372 13.5387 7.1174 13.5387 7.92904ZM9.46973 14.6478V13.3392H10.7393V14.6478H9.46973Z" fill="#183041" fillOpacity="0.8"/>
                                    </svg>
                                    <ReactTooltip
                                        id="manifest-total"
                                        place="bottom"
                                        effect="solid"
                                    >
                                        <div id="manifest-total-header">What's included?</div>
                                        <div className="manifest-total-row">Subtotal = ${subTotal.toFixed(2)}</div>
                                        <hr />
                                        <div className="manifest-total-row">Tax = ${tax.toFixed(2)}</div>
                                        <hr />
                                        <div className="manifest-total-row">Shipping = Free</div>
                                    </ReactTooltip>
                                </div>
                            </Col>
                        </Row>
                        {this.renderMobileProductsRow()}
                    </div>
                )
            } catch(err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    render() {
        return (
            <div>
                {this.renderOrder()}
            </div>
        )
    }
}

export default PastOrder;