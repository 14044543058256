import { combineReducers } from 'redux';
import interviewReducer from './interviewReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';
import productReducer from './productReducer';

export default combineReducers({
    interview: interviewReducer,
    order: orderReducer,
    user: userReducer,
    product: productReducer
});
