import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import {
    marginForCountdown,
    clusterProductsForCart
} from '../../actions';
import React, {Component} from 'react';
import {CartSummary} from './cart-summary';
import ConfirmationForm from './Confirmation-Form';
import { Option } from "@nozzlegear/railway";
import Header from "../Header";
import Countdown from '../Countdown';
import images from '../../img/images';
import Feedback from '../Interview/Feedback';

class Confirmation extends Component {
    constructor(props) {
        super (props);
        this.state = {
            shippingAddress : {},
            totals: {
                currency: "",
                subTotal: "",
                taxTotal: "",
                ultimateTotal: ""
            },
            options: undefined,
            order: undefined,
            interview: undefined
        }

        this.continue = this.continue.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const { totals } = this.state;
        const products = JSON.parse(sessionStorage.getItem('products'));
        // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));

        // if(coupon) {
        //     this.setState({coupon});
        // }
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const {shipping} = customer;
        const shippingAddress = {
            name: shipping.name,
            line1: shipping.address,
            line2: "",
            city: shipping.city,
            zipcode: shipping.zipcode,
            state: shipping.state
        }

        clusterProductsForCart(products).then(res => {
            const {sorted, total} = res;
            // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
            // if(coupon) {
            //     this.setState({coupon});
            // }
            // totals.ultimateTotal = coupon ? totals.subTotal - (totals.subTotal * coupon.percentDiscount/100) : totals.subTotal;
            // if(shippingAddress.city === "Austin") {
            //     totals.subTotal = total;
            //     totals.taxTotal = total * .0825
            //     totals.ultimateTotal = totals.taxTotal + total;
            // } else {
                totals.ultimateTotal = totals.subTotal = total;
            // }
            this.setState({options: sorted})
        })

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }
    
    continue() {
        try {
            const order = JSON.parse(sessionStorage.getItem('order'));
            if(order) {
                this.props.createOrder(order);
                this.setState({createOrderError: false, sending: true})
            } else {
                this.setState({error: "Data missing. Please reenter billing information and try again."})
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
            this.setState({error: "Data missing. Please reenter billing information and try again."})
        }
    }

    generateCartSummary() {
        const controls = Option.ofNone();
        return (
            <CartSummary
                coupon={this.props.coupon}
                totals={this.props.totals}
                shippingTotal={this.state.selectedRate.bind(r => Option.ofSome(r.value))}
                lineItems={this.props.items}
                controls={controls}
            />
        );
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown />
    }

    renderMobileCartSummary() {
        const {isMobile, coupon, totals, options} = this.state;
        if(isMobile && totals && options) {
            return (
                <div className="checkout-body space-bottom">
                    <CartSummary
                        coupon={coupon}
                        totals={totals}
                        lineItems={options}
                    />
                </div>
            );   
        }
    }

    render() {
        const {options, sending, zipcode} = this.state;
        const {createOrderError, creatingOrder} = this.props;
        if(options) {
            return (
                <div>
                    {this.renderCountdown()}
                    <Header history={this.props.history} spaceTop={zipcode} />
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.goBack()} className="interview-icon interview-back-arrow" />
                        <div></div>
                    </div>
                    <div className="checkout-container">
                        <div className="checkout-body">
                            <ConfirmationForm
                                coupon={this.state.coupon}
                                totals={this.state.totals}
                                items={this.state.options}
                                history={this.props.history}
                                error={this.state.error}
                                createOrderError={createOrderError}
                                order={this.state.order}
                                sending={sending}
                                creatingOrder={creatingOrder}
                            />
                        </div>
                        {this.renderMobileCartSummary()}
                    </div>
                    <Feedback question="/checkout/confirmation" />
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

export default Confirmation;