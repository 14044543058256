import React from 'react';
import * as Sentry from '@sentry/browser';

const Welcome = ({customer}) => 
{
    try {
        return (
            <div className="dashboard-headers-container">
                <h2 className="dashboard-main-header">Hi {customer.nickname ? customer.nickname : customer.firstName}, welcome to your account dashboard.</h2>
                <p className="dashboard-sub-header">From your account dashboard you can view your recent and edit your upcoming <a className="inline-link" href="/#/dashboard/orders">Supply Drops</a>, manage your <a className="inline-link" href="/#/dashboard/account">shipping and billing addresses</a>, and <a className="inline-link" href="/#/dashboard/account">edit your account details.</a></p>
            </div>
        )
    } catch(err) {
        Sentry.captureException(err);
        console.error(err);
        return <div className="space-top"></div>
    }
}

export default Welcome;