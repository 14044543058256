import React, {Component, Fragment} from 'react';
import Menu from './Menu';
import Header from '../Header';
import Footer from '../Footer';
import {Auth} from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import Loading from './Loading';
import Saving from './Saving';
import {connect} from 'react-redux';
import '../../Style/dashboard.css';
import '../../Style/checkout.css';
import images from '../../img/images';
import StripeForm from './StripeForm';
import { Elements } from 'react-stripe-elements';
import { States } from '../checkout/states';
import InputMask from 'react-input-mask';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ErrorMessage from '../ErrorMessage';
import moment from 'moment';
import {
    getUser,
    validateZip,
    validateStreetAddress,
    validateCityAndNames,
    updateCustomer,
    clearCustomerState,
    updateSource,
    validateEmail,
    getOrders
} from '../../actions';

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValid: true,
            addressValid: true,
            zipcodeValid: true,
            cityValid: true,
            stateValid: true,
            firstNameValid: true,
            lastNameValid: true,
            emailValid: true,
            phoneValid: true,
            passwordValid: true,
            confirmPasswordValid: true,
            confirmPasswordsMatch: true,
            password: '',
            confirmPassword: ''
        };

        this.setToEdit = this.setToEdit.bind(this);
        this.onShippingChange = this.onShippingChange.bind(this);
        this.cancelShippingEdits = this.cancelShippingEdits.bind(this);
        this.cancelBillingEdits = this.cancelBillingEdits.bind(this);
        this.cancelDetailsEdits = this.cancelDetailsEdits.bind(this);
        this.updateShipping = this.updateShipping.bind(this);
        this.validateShipping = this.validateShipping.bind(this);
        this.updateBilling = this.updateBilling.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.confirmPasswordsMatch = this.confirmPasswordsMatch.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
        this.validateDetails = this.validateDetails.bind(this);
    }

    componentDidMount() {
        // if user is not logged in, render sign in modal
        Auth.currentAuthenticatedUser().then(data => {
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
            this.setState({signedIn: true})
            this.props.getUser();
            if(!this.props.orders) {
                this.props.getOrders();
            }
        }).catch(err => {
            this.props.history.push('/login');
        })
        const states = States.map(s => {
            if(s.iso) {
                return (
                    <option key={s.iso} value={s.iso}>
                        {s.name}
                    </option>
                )   
            }
        });
        this.setState({states});
    }

    componentDidUpdate() {
        const {
            user,
            putCustomerError,
            putCustomerSuccess,
            updatingCustomer,
            putSourceSuccess,
            putSourceError,
            updatingSource,
            orders
        } = this.props;
        if(user && !this.state.user) {
            const shipping = {};
            const {customer} = user;
            Object.entries(customer.shipping).map(entry => {
                shipping[entry[0]] = entry[1];
            });
            const {
                firstName,
                lastName,
                email,
                phone
            } = customer;

            const deformattedPhone = this.reverseFormatPhone(phone);

            this.setState({
                user,
                shipping,
                firstName,
                lastName,
                email,
                phone: deformattedPhone
            });
            if(user.customer.shipping.state === "TX") {
                this.setState({isTax: true})
            }
        }
        if(this.state.updating && !updatingCustomer && !updatingSource) {
            if(putCustomerSuccess) {
                this.props.clearCustomerState('all');
                setTimeout(() => {
                    this.setState({updating: false, editing: false, user: undefined, shippingEdit: false, detailsEdit: false});
                    this.props.getUser();
                }, 3000);
            }
            if(putCustomerError) {
                setTimeout(() => {
                    this.setState({error: putCustomerError});
                    this.setState({updating: false});
                    this.props.clearCustomerState();
                }, 3000);
            }
            if(putSourceSuccess) {
                this.props.clearCustomerState('all');
                setTimeout(() => {
                    this.setState({updating: false, editing: false, billingEdit: false, user: undefined});
                    this.props.getUser();
                }, 3000);
            }
            if(putSourceError) {
                setTimeout(() => {
                    this.setState({error: putCustomerError});
                    this.setState({updating: false});
                    this.props.clearCustomerState();
                }, 3000);
            }
        }
        if(orders && !this.state.orders) {
            try {
                const order = orders.sort((a,b) => {
                    if(moment(a.order.expectedShipDate).valueOf() > moment(b.order.expectedShipDate).valueOf()) { return -1; }
                    if(moment(a.order.expectedShipDate).valueOf() < moment(b.order.expectedShipDate).valueOf()) { return 1; }
                    return 0;
                })[0];
                this.setState({orders, order})
            } catch(err) {
                console.error(err);
            }
        }
    }

    setToEdit(section) {
        this.setState({ [section]: true, editing: true });
    }

    onShippingChange(field, value) {
        const {shipping} = this.state;
        shipping[field] = value;
        this.setState({shipping, [`${field}Valid`]: true});
    }

    onDetailsChange(field, value) {
        this.setState({[field]: value});
    }

    validateDetails(field, value) {
        try {
            switch(field) {
                case 'firstName':
                    this.setState({firstNameValid: validateCityAndNames(value)})
                    break;
                case 'lastName':
                    this.setState({lastNameValid: validateCityAndNames(value)})
                    break;
                case 'email':
                    this.setState({emailValid: validateEmail(value)})
                    break;
                case 'phone':
                    const formattedPhone = this.formatPhone(value);
                    this.setState({phoneValid: isValidPhoneNumber(formattedPhone)})
                    break;
                default: 
                    console.log(field, value)
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    } 

    confirmPasswordsMatch() {
        const {password, confirmPassword} = this.state;
        if(password !== confirmPassword) {
            this.setState({passwordsMatch: false})
        }
    }

    cancelShippingEdits() {
        const {user} = this.state;
        const shipping = {};
        Object.entries(user.customer.shipping).map(entry => {
            shipping[entry[0]] = entry[1];
        });
        this.setState({
            shipping,
            editing: false,
            shippingEdit: false,
            cityValid: true,
            nameValid: true,
            addressValid: true,
            zipcodeValid: true
        });
    }

    cancelDetailsEdits() {
        const {user} = this.state;
        const {customer} = user;
        const {firstName, lastName, phone, email} = customer;
        this.setState({
            firstName,
            lastName,
            phone: this.reverseFormatPhone(phone),
            email,
            editing: false,
            detailsEdit: false,
            emailValid: true,
            phoneValid: true,
            firstNameValid: true,
            lastNameValid: true
        })
    }

    cancelBillingEdits() {
        this.setState({editing: false, billingEdit: false})
    }

    updateShipping() {
        const {shipping} = this.state;
        const cityValid = validateCityAndNames(shipping.city);
        const nameValid = validateCityAndNames(shipping.name);
        const addressValid = validateStreetAddress(shipping.address);
        const zipcodeValid = validateZip(shipping.zipcode);
        if(
            cityValid &&
            nameValid &&
            addressValid &&
            zipcodeValid
        ) {
            this.props.updateCustomer({shipping});
            this.setState({updating: true})
        } else {
            this.setState({
                cityValid,
                nameValid,
                addressValid,
                zipcodeValid
            });
        }
    }

    updateBilling(source) {
        this.props.updateSource(source);
        this.setState({updating: true})
    }

    validateShipping(field, value) {
        try {
            switch(field) {
                case 'name':
                    this.setState({nameValid: validateCityAndNames(value)})
                    break;
                case 'city':
                    this.setState({cityValid: validateCityAndNames(value)})
                    break;
                case 'address':
                    this.setState({addressValid: validateStreetAddress(value)})
                    break;
                case 'state':
                    this.setState({stateValid: !!value})
                    break;
                case 'zipcode':
                    this.setState({zipcodeValid: validateZip(value)})
                    break;
                default: 
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    updateDetails() {
        const {
            user,
            firstName,
            lastName,
            email,
            phone,
            emailValid,
            phoneValid,
            firstNameValid,
            lastNameValid
        } = this.state;

        const {customer} = user;

        const formattedPhone = this.formatPhone(phone);
        const changes = {};

        
        if(emailValid && firstNameValid && lastNameValid && phoneValid) {
            if(customer.firstName !== firstName) changes.firstName = firstName;
            if(customer.lastName !== lastName) changes.lastName = lastName;
            if(customer.email !== email) changes.email = email;
            if(customer.phone !== formattedPhone) changes.phone = formattedPhone;
            this.props.updateCustomer(changes);
            this.setState({
                updating: true,
                emailValid,
                firstNameValid,
                lastNameValid,
                phoneValid
            })
        }
        
    }

    formatPhone(value) {
        let num = '';
        num = "+1" + value.slice(1,4) + value.slice(6, 9) + value.slice(10,14);
        return num;
    }

    reverseFormatPhone(value) {
        let num = '';
        num = `(${value.slice(2,5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    renderPutModal() {
        const {updating} = this.state;
        const {
            putCustomerSuccess,
            putCustomerError,
            putSourceError,
            putSourceSuccess
        } = this.props;

        if (updating) {
            return (
                <Saving success={(putCustomerSuccess || putSourceSuccess)} error={(putCustomerError || putSourceError)} />
            )
        }
    }

    renderShippingSection() {
        const {
            user,
        } = this.state;
        const {customer} = user;
        const {shipping} = customer;
        const {name, address, city, state, zipcode, deliveryInstructions} = shipping;
        return (
            <p className="no-margin">
                {name}<br/>
                {address}<br/>
                {city}, {state} {zipcode}
                {deliveryInstructions ? 
                    <Fragment>
                        <br/><br/>
                        <u>Delivery Instructions:</u><br/>
                        {deliveryInstructions}
                    </Fragment> : null
                }
            </p>
        )
    }

    renderShippingModal() {
        const {
            shippingEdit,
            updating,
            shipping,
            states,
            nameValid,
            addressValid,
            zipcodeValid,
            stateValid,
            cityValid
        } = this.state;

        if(shippingEdit && !updating) {
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={this.cancelShippingEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                        <div className="signin-modal-tab-container">
                            <div className="cancel-modal-body">
                                <p className="modal-title">
                                    Update Delivery Info
                                </p>
                                <hr />
                                <div className="input-wrapper flex-row flex-wrap account-modal">
                                    <div className="modal-input-container full">
                                        <label className={`modal-label ${nameValid ? '' : 'invalid'}`}>Name:</label>
                                        <input
                                            className={`modal-input ${nameValid ? '' : 'invalid'}`}
                                            id="full-name"
                                            value={shipping.name}
                                            type="text"
                                            onChange={(e) => this.onShippingChange('name', e.target.value)}
                                            onBlur={(e) => this.validateShipping('name', e.target.value)}
                                        />
                                    </div>
                                    <div className="modal-input-container full">
                                        <label className={`modal-label ${addressValid ? '' : 'invalid'}`}>Address:</label>
                                        <input
                                            className={`modal-input ${addressValid ? '' : 'invalid'}`}
                                            id="modal-line1"
                                            value={shipping.address}
                                            type="text"
                                            onBlur={(e) => this.validateShipping('address', e.target.value)}
                                            onChange={(e) => this.onShippingChange('address', e.target.value)}
                                        />
                                    </div>
                                    <div className="modal-input-container">
                                        <label className={`modal-label ${cityValid ? '' : 'invalid'}`}>City:</label>
                                        <input
                                            className={`modal-input ${cityValid ? '' : 'invalid'}`}
                                            id="modal-city"
                                            value={shipping.city}
                                            type="text"
                                            onBlur={(e) => this.validateShipping('city', e.target.value)}
                                            onChange={(e) => this.onShippingChange('city', e.target.value)}
                                        />
                                    </div>
                                    <div className="modal-input-container">
                                        <label className="modal-label">State:</label>
                                        <select
                                            className={`modal-input${stateValid ? "" : " invalid"}`}
                                            id="modal-state"
                                            value={shipping.state}
                                            onBlur={(e) => this.validateShipping('state', e.target.value)}
                                            onChange={e => this.onShippingChange('state', e.currentTarget.value)}
                                        >
                                            {states}
                                        </select>
                                    </div>
                                    <div className="modal-input-container">
                                        <label className={`modal-label ${zipcodeValid ? '' : 'invalid'}`}>Zipcode:</label>
                                        <input
                                            className={`modal-input ${zipcodeValid ? '' : 'invalid'}`}
                                            value={shipping.zipcode}
                                            id="zipcode"
                                            type="number"
                                            onBlur={(e) => this.validateShipping('zipcode', e.target.value)}
                                            onChange={(e) => this.onShippingChange('zipcode', e.target.value)}
                                        />
                                    </div>
                                    <div className="modal-input-container full">
                                        <label className={`modal-label`}>Delivery Instructions:</label>
                                        <textarea
                                            className={`modal-input`}
                                            value={shipping.deliveryInstructions}
                                            id="delivery-instructions"
                                            type="text"
                                            onChange={(e) => this.onShippingChange('deliveryInstructions', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.renderError()}
                        </div>
                        <div className="cancel-modal-button-row">
                            <button onClick={this.cancelShippingEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                            <button onClick={this.updateShipping} className="dashboard-btn dashboard-save">Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

    renderError() {
        const {
            nameValid,
            addressValid,
            cityValid,
            stateValid,
            zipcodeValid,
            firstNameValid,
            lastNameValid,
            emailValid,
            phoneValid
        } = this.state;

        const fields = [
            nameValid,
            addressValid,
            cityValid,
            stateValid,
            zipcodeValid,
            firstNameValid,
            lastNameValid,
            emailValid,
            phoneValid
        ];
        let errors = 0;
        fields.forEach(field => {
            if(!field) errors++;
        })
        if(errors) {
            if(errors > 1) {
                return <ErrorMessage body="Please provide valid answers for all required fields" />
            } else {
                if(!nameValid) return <ErrorMessage body="Please enter valid name" />
                if(!addressValid) return <ErrorMessage body="Please enter valid address" />
                if(!cityValid) return <ErrorMessage body="Please enter valid city" />
                if(!stateValid) return <ErrorMessage body="Please select a state" />
                if(!zipcodeValid) return <ErrorMessage body="Please enter valid zipcode" />
                if(!firstNameValid) return <ErrorMessage body="Please enter valid first name" />
                if(!lastNameValid) return <ErrorMessage body="Please enter valid last name" />
                if(!emailValid) return <ErrorMessage body="Please enter valid email" />
                if(!phoneValid) return <ErrorMessage body="Please enter valid phone number" />
            }
        }
    }

    renderBilling() {
        const {
            user,
        } = this.state;

        if(user) {
            const {customer} = user;
            const {paymentSource} = customer;
            return (
                <p className="no-margin">
                    {paymentSource.brand} ending in {paymentSource.last4}<br/>
                    Exp. {paymentSource.expirationMonth}/{paymentSource.expirationYear}
                </p>
            )
        }
    }

    renderBillingModal() {
        const {
            billingEdit,
            user,
            states,
            updating
        } = this.state;
        if(billingEdit && !updating) {
            return (
                <Elements>
                    <StripeForm                    
                        customer={user.customer}
                        editing={billingEdit}
                        states={states}
                        cancel={this.cancelBillingEdits}
                        updateSource={this.updateBilling}
                    />
                </Elements>
            )
        }
    }

    renderDetailsModal() {
        const {
            detailsEdit,
            user,
            firstName,
            lastName,
            email,
            phone,
            firstNameValid,
            lastNameValid,
            emailValid,
            updating,
            phoneValid,
        } = this.state;
        if(user) {
            if(detailsEdit && !updating) {
                return (
                    <div className="modal-container transparent">
                        <div className="interview-question-modal">
                            <img onClick={this.cancelDetailsEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                            <div className="interview-modal-body$ no-padding">
                                <div className="signin-modal-tab-container">
                                    <div className="cancel-modal-body">
                                        <p className="modal-title">
                                            Update Personal Info
                                        </p>
                                        <hr />
                                        <div className="input-wrapper flex-row flex-wrap account-modal">
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${firstNameValid ? '' : 'invalid'}`}>First Name:</label>
                                                <input
                                                    className={`modal-input ${firstNameValid ? '' : 'invalid'}`}
                                                    value={firstName}
                                                    type="text"
                                                    onChange={(e) => this.onDetailsChange('firstName', e.target.value)}
                                                    onBlur={(e) => this.validateDetails('firstName', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${lastNameValid ? '' : 'invalid'}`}>Last Name:</label>
                                                <input
                                                    className={`modal-input ${lastNameValid ? '' : 'invalid'}`}
                                                    value={lastName}
                                                    type="text"
                                                    onBlur={(e) => this.validateDetails('lastName', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('lastName', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${emailValid ? '' : 'invalid'}`}>Email:</label>
                                                <input
                                                    className={`modal-input ${emailValid ? '' : 'invalid'}`}
                                                    value={email}
                                                    type="email"
                                                    onBlur={(e) => this.validateDetails('email', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('email', e.target.value)}
                                                />
                                            </div>
                                            <div className="modal-input-container">
                                                <label className={`modal-label ${phoneValid ? '' : 'invalid'}`}>Phone:</label>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    value={phone}
                                                    onBlur={(e) => this.validateDetails('phone', e.target.value)}
                                                    onChange={(e) => this.onDetailsChange('phone', e.target.value)}
                                                >
                                                {() => <input
                                                        className={`modal-input ${phoneValid ? '' : 'invalid'}`}
                                                        type="tel"
                                                        placeholder="Mobile number"
                                                    />}
                                                </InputMask>
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderError()}
                                </div>
                                <div className="cancel-modal-button-row">
                                    <button onClick={this.cancelDetailsEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                    <button onClick={this.updateDetails} className="dashboard-btn dashboard-save">Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    renderDetails() {
        const {
            user,
            phone,
        } = this.state;
        const {customer} = user;
        return (
            <p className="no-margin">
                {customer.firstName} {customer.lastName}<br/>
                {phone}<br/>
                {customer.email}
            </p>
        )
    }

    renderCustomer() {
        const {user, detailsEdit, shippingEdit, billingEdit, editing} = this.state;
        if(user) {
            return (
                <div className="account-sections-container">
                    <div className="account-section-header-container"><h4>Account Info</h4></div>
                    <div className="dashboard-section-container">
                        <div className="dashboard-section-header-row">
                            <div className="account-section-subheader">Personal Info</div>
                            <div className="account-section-edit" onClick={() => this.setToEdit('detailsEdit')}>Update Info</div>
                        </div>
                        {this.renderDetails()}
                    </div>
                    <div className="account-section-header-container"><h4>Payment</h4></div>
                    <div className="dashboard-section-container">
                        <div className="dashboard-section-header-row">
                            <div className="account-section-subheader">Payment Info</div>
                            <div className="account-section-edit" onClick={() => this.setToEdit('billingEdit')}>Update Info</div>
                        </div>
                        {this.renderBilling()}
                    </div>
                    <div className="account-section-header-container"><h4>Delivery</h4></div>
                    <div className="dashboard-section-container space-bottom lg">
                        <div className="dashboard-section-header-row">
                            <div className="account-section-subheader">Delivery Info</div>
                            <div className="account-section-edit" onClick={() => this.setToEdit('shippingEdit')}>Update Info</div>
                        </div>
                        {this.renderShippingSection()}
                        {this.renderOrderHistory()}
                        {/* <hr/>
                        <div className="account-section-edit text-center"><span><img id="pause-icon" src={images.pause} alt="pause" /></span>Pause or Change Delivery Date</div> */}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <Loading />
                </div>
            )
        }
    }

    renderOrderHistory() {
        const {order} = this.state;
        if(order) {
            return (
                <Fragment>
                    <hr />
                    <div className="dashboard-section-header-row">
                        <div className="account-section-subheader">Order History</div>
                        <div className="account-section-edit" onClick={() => this.props.history.push('/dashboard/orders')}>View History</div>
                    </div>
                    <p className="no-margin">
                        Last delivery sent on {moment(order.order.expectedShipDate).format('ll')}
                    </p>
                </Fragment>
            )
        }
    }

    render() {
        // TODO: Fix max-width on account sections to be more visually pleasing
        const {signedIn} = this.state;
        if(signedIn) {
            return (
                <div className="no-cloud">
                    {this.renderPutModal()}
                    <Menu history={this.props.history} signedIn={() => {this.setState({signedIn: true})}} />
                    <div id="dashboard-body-container">
                        <div className="account-header-container">
                            <img src={images.accountHeader} alt="My Account" />
                            <h3 id="account-header">My Account</h3>
                        </div>
                        {this.renderCustomer()}
                        {this.renderShippingModal()}
                        {this.renderBillingModal()}
                        {this.renderDetailsModal()}
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return (
                <div>
                    <Header history={this.props.history}/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const {
        user,
        error,
        putCustomerSuccess,
        putCustomerError,
        updatingCustomer,
        putSourceSuccess,
        putSourceError,
        updatingSource
    } = state.user;
    const { orders, getOrdersError } = state.order;

    return {
        user,
        error,
        putCustomerSuccess,
        putCustomerError,
        updatingCustomer,
        putSourceSuccess,
        putSourceError,
        updatingSource,
        orders,
        getOrdersError
    }
}


export default connect(mapStateToProps, {
    getUser,
    getOrders,
    updateCustomer,
    clearCustomerState,
    updateSource
})(Account);