import React from 'react';
import CustomerForm from './CustomerForm';
import Header from '../Header';
import {postInterview, clearInterviews, marginForCountdown, clusterProductsForCart} from '../../actions';
import {connect} from 'react-redux';
import '../../Style/interview.css';
import { Auth } from 'aws-amplify';
import Countdown from '../Countdown';
import images from '../../img/images';
import {CartSummary} from './cart-summary';
import Feedback from '../Interview/Feedback';

class CustomerInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            totals: {
                currency: "",
                subTotal: "",
                taxTotal: "",
                ultimateTotal: ""
            },
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const { totals } = this.state;
        const products = JSON.parse(sessionStorage.getItem('products'));
        if(products) {
            clusterProductsForCart(products).then(res => {
                const {sorted, total} = res;
                // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
                // if(coupon) {
                //     this.setState({coupon});
                // }
                // totals.ultimateTotal = coupon ? totals.subTotal - (totals.subTotal * coupon.percentDiscount/100) : totals.subTotal;
        
                totals.ultimateTotal = totals.subTotal = total;
                this.setState({options: sorted})
            })
        } else {
            this.props.history.push('/prediction')
        }

        if(!this.state.unauthedId) {
            Auth.currentCredentials().then(data=> {
                this.setState({unauthedId: data._identityId});
            })
        }

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    componentDidUpdate() {
        const {interviewPostError} = this.props;
        if(interviewPostError && !this.state.interviewPostError) {
            this.setState({interviewPostError})
        }

        if(this.state.interviewPostError && this.props.interviewPostError) {
            this.props.clearInterviews();
        } 
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown />
    }

    renderMobileCartSummary() {
        const {isMobile, coupon, totals, options} = this.state;
        if(isMobile && totals && options) {
            return (
                <div className="checkout-body space-bottom">
                    <CartSummary
                        coupon={coupon}
                        totals={totals}
                        lineItems={options}
                    />
                </div>
            );   
        }
    }

    render() {
        const {
            options,
            interviewPostError,
            coupon,
            totals,
            unauthedId,
            zipcode
        } = this.state;
        if(options) {
            return (
                <div>
                    {this.renderCountdown()}
                    <Header history={this.props.history} spaceTop={zipcode}/>
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.goBack()} className="interview-icon interview-back-arrow" />
                        <div></div>
                    </div>
                    <div className="checkout-container">
                        <div className="checkout-body">
                            <CustomerForm
                                coupon={coupon}
                                totals={totals}
                                items={options}
                                history={this.props.history}
                                unauthedId={unauthedId}
                                postInterview={this.props.postInterview}
                                signIn={() => {this.setState({signedIn: true})}}
                                interviewPostError={interviewPostError}
                            />
                        </div>
                        {this.renderMobileCartSummary()}
                    </div>
                    <Feedback question="/checkout" />
                </div>
            )
        } else {
            return (
                <div>

                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { interviewPosted, interviewPostError } = state.interview;
    return { interviewPosted, interviewPostError };
}

export default connect(mapStateToProps, {
    postInterview,
    clearInterviews
})(CustomerInfo);