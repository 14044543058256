import * as Sentry from '@sentry/browser';
export const formatType = (type) => {
    try {
        let baseChar = 0;
        let newType = '';
        if(type !== 'TrashBagsKitchen' && type !== 'TrashBagsOutdoor') {
            for(let i = 1; i < type.length; i++) {
                if(type[i] === type[i].toUpperCase()) {
                    if(newType.length) newType += ' ';
                    newType += type.substring(baseChar, i);
                    baseChar += type.substring(baseChar, i).length;
                }
            }
            if(type.length > newType.length) {
                newType += ` ${type.substring(newType.length, type.length)}`
            }
        } else {
            newType = type === 'TrashBagsKitchen' ? 'Kitchen Trash Bags' : 'Outdoor Trash Bags';
        }
        return newType;
    } catch(err) {
        Sentry.captureException(err);
        console.error(err);
        return type;
    }
}

export const getUnits = (articleType, articles, capitalizeFirst) => {
    const lastChar = articleType.slice(-1).toLowerCase();
    if(articles > 1){
        if(lastChar === 'x' || lastChar === 's' || lastChar === 'o') {
            articleType += "es";
        } else {
            articleType += 's';
        }
    }

    if(capitalizeFirst)
        return articleType.charAt(0).toUpperCase() + articleType.slice(1)
    else
        return articleType.toUpperCase();
}

export const round = (num) => {
    return +(Math.round(num + "e+2")  + "e-2");
}

export const formatInterview = (interview) => {
    try {
        const {
            bathrooms,
            dishwashingFrequency,
            dishwashingProducts,
            cookingFrequency,
            family,
            housekeepingFrequency,
            laundryFrequency,
            laundryPreferences,
            pets,
            scentPreferences,
            tierPreferences,
            trash,
            trashPickups
        } = interview

        const formattedInterview = {
            bathrooms,
            dishwashing: {
                frequency: dishwashingFrequency,
                products: dishwashingProducts
            },
            laundry: {
                frequency: laundryFrequency,
                preferences: laundryPreferences
            },
            cookingFrequency,
            family,
            pets,
            cookingFrequency,
            housekeeping: housekeepingFrequency,
            trashPreferences: trash.length ? trash :[{
                type: "Kitchen",
                bags: trashPickups,
                frequency: 1   
            }],
            tierPreferences
        }
        return {interview: formattedInterview}
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        return {error}
    }
}

export const marginForCountdown = (remove) => {
    const body = document.getElementsByTagName('body')[0];
    if(!body.classList.contains('countdown-margin') && !remove)
        body.classList.add('countdown-margin');
    else if (body.classList.contains('countdown-margin') && remove)
        body.classList.remove('countdown-margin');
}

export async function clusterProductsForCart(products) {
    const kitchenTypes = [
        "dishsoap",
        "dishwasherdetergent",
        "surfacecleaner",
        "handsoap"
    ]
    const everydayTypes = [
        "laundrydetergent",
        "toiletpaper",
        "papertowels",
        "trashbagskitchen",
        "handwipes",
        "trashbagsoutdoor"
    ]
    let total = 0;
    const everydayRows = [];
    const handwipes = [];
    const kitchenRows = [];

    Object.values(products).map(product => {
        const {selected} = product;
        if(selected.articles) {
            const {productType} = selected;
            total += parseFloat(selected.pricePerArticle * selected.articles/100);
            if(kitchenTypes.includes(productType.toLowerCase()))
                kitchenRows.push(selected)
            else if(selected.productType.toLowerCase() === "handwipes")
                handwipes.push(selected)
            else
                everydayRows.push(selected)
        }
    })
    const sorted = [...everydayRows, ...handwipes, ...kitchenRows]
    return {sorted, total};
}