import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import '../Style/order.css';
import moment from 'moment';
import {Row, Col} from 'react-bootstrap';
import { formatType, getUnits, round } from '../actions';
import Counter from './Interview/Counter';
import images from '../img/images';
import ReactTooltip from 'react-tooltip';

class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kitchenTypes: [
                "dishsoap",
                "dishwasherdetergent",
                "surfacecleaner",
                "handsoap"
            ],
            everydayTypes: [
                "laundrydetergent",
                "toiletpaper",
                "papertowels",
                "trashbagskitchen",
                "handwipes",
                "trashbagsoutdoor"
            ]
        };

        // this.showProducts = this.showProducts.bind(this);
        this.setSelectedProduct = this.setSelectedProduct.bind(this);
        this.handleAdditionalClick = this.handleAdditionalClick.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        const {productTypes, productsMap, coupon} = this.props;     
        this.setState({productTypes, productsMap, coupon});
    }

    componentDidUpdate() {
        const {productTypes, productsMap, selectedProduct} = this.state;
        if(selectedProduct) {
            try {
                const product = productTypes[productsMap[selectedProduct].productType].edited;
                if(product.groupName !== selectedProduct) {
                    this.setState({selectedProduct: product.groupName});
                }
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
        
    }

    setSelectedProduct(groupName) {
        const {selectedProduct} = this.state;
        if(selectedProduct === groupName) {
            this.setState({selectedProduct: undefined})
        } else {
            this.setState({selectedProduct: groupName})
        }
        this.props.clearLimits();
    }

    renderProductsRow() {
        const {productTypes, selectedProduct, coupon, kitchenTypes, everydayTypes} = this.state;
        const {subtotal} = this.props;
        const {isTax} = this.props;
        if(productTypes && Object.values(productTypes).length) {
            const bottomRow = [];
            const kitchenRows = [];
            const everydayRows = [];
            const handWipes = [];
            let productCount = 0;
            Object.entries(productTypes).forEach(entry => {
                const productType = entry[0];
                const product = entry[1];
                const {edited} = product;
                if(edited && Object.values(edited).length) {
                    productCount++;
                    const {articles, articleType, imageUrl, brandName, displayName, pricePerArticle, groupName} = edited;
                    const units = articles === 1 ? articleType : articleType + (articleType.toLowerCase() === 'box' ? 'es' : 's');
                    if(kitchenTypes.indexOf(productType.toLowerCase()) > -1) {
                        kitchenRows.push(
                            <div>
                                <div className={`order-product-container${selectedProduct === groupName ? " open" : ""}${selectedProduct === groupName ? " border-bottom" : ""}`} onClick={() => this.setSelectedProduct(groupName)}>
                                    <div className={`order-product${selectedProduct === groupName ? " open" : " collapsed"}`}>
                                        <div className="order-frame-container">
                                            <div className="order-product-frame">
                                                <div className="mobile-edit-container">
                                                    <a className={`product-mobile-edit${selectedProduct === groupName ? " hide" : ""}`}>More Options</a>
                                                </div>
                                                <img src={imageUrl} alt={`${brandName} ${displayName}`} className="order-product-thumbnail" />
                                                <div className="order-product-details">
                                                    <div><b>{brandName}</b> {displayName}</div>
                                                    <div>{formatType(productType)}</div>
                                                    <div>{articles} {units}</div>
                                                    <div><b>${(articles * pricePerArticle/100).toFixed(2)}</b> @ ${(pricePerArticle/100).toFixed(2)} ea.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProduct === groupName ? this.renderEditingRow(true) : null}
                            </div>
                        )
                    } else if (productType.toLowerCase() !== "handwipes") {
                        everydayRows.push(
                            <div>
                                <div className={`order-product-container${selectedProduct === groupName ? " open" : ""}${selectedProduct === groupName ? " border-bottom" : ""}`} onClick={() => this.setSelectedProduct(groupName)}>
                                    <div className={`order-product${selectedProduct === groupName ? " open" : " collapsed"}`}>
                                        <div className="order-frame-container">
                                            <div className="order-product-frame">
                                                <div className="mobile-edit-container">
                                                    <a className={`product-mobile-edit${selectedProduct === groupName ? " hide" : ""}`}>More Options</a>
                                                </div>
                                                <img src={imageUrl} alt={`${brandName} ${displayName}`} className="order-product-thumbnail" />
                                                <div className="order-product-details">
                                                    <div><b>{brandName}</b> {displayName}</div>
                                                    <div>{formatType(productType)}</div>
                                                    <div>{articles} {units}</div>
                                                    <div><b>${(articles * pricePerArticle/100).toFixed(2)}</b> @ ${(pricePerArticle/100).toFixed(2)} ea.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProduct === groupName ? this.renderEditingRow(true) : null}
                            </div>
                        )
                    } else {
                        handWipes.push(
                            <div>
                                <div className={`order-product-container${selectedProduct === groupName ? " open" : ""}${selectedProduct === groupName ? " border-bottom" : ""}`} onClick={() => this.setSelectedProduct(groupName)}>
                                    <div className={`order-product${selectedProduct === groupName ? " open" : " collapsed"}`}>
                                        <div className="order-frame-container">
                                            <div className="order-product-frame">
                                                <div className="mobile-edit-container">
                                                    <a className={`product-mobile-edit${selectedProduct === groupName ? " hide" : ""}`}>More Options</a>
                                                </div>
                                                <img src={imageUrl} alt={`${brandName} ${displayName}`} className="order-product-thumbnail" />
                                                <div className="order-product-details">
                                                    <div><b>{brandName}</b> {displayName}</div>
                                                    <div>{formatType(productType)}</div>
                                                    <div>{articles} {units}</div>
                                                    <div><b>${(articles * pricePerArticle/100).toFixed(2)}</b> @ ${(pricePerArticle/100).toFixed(2)} ea.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProduct === groupName ? this.renderEditingRow(true) : null}
                            </div>
                        )
                    }
                }
            })
            if(productCount !== Object.keys(productTypes).length) {
                bottomRow.push(
                    <div className="add-products-btn-container">
                        <button className="dashboard-btn dashboard-submit" onClick={this.props.addProducts}>
                            Add Products +
                        </button>
                    </div>
                )
            }

                let discount = 0;
                if(coupon && coupon.percentDiscount) {
                    discount = round(coupon.percentDiscount/100 * subtotal);
                }
                const tax = isTax ? round(0.0825 * subtotal) : 0;
                bottomRow.push(
                    <Row className="mobile-totals">
                        <Col className="subtotal-text" xs={6}>Subtotal</Col><Col className="subtotal-price" xs={6}>${subtotal.toFixed(2)}</Col>
                        {window.location.hash === "#/prediction" ? null : <Col className="subtotal-text" xs={6}>Estimated Tax</Col>}
                        {window.location.hash === "#/prediction" ? null : <Col className="subtotal-price" xs={6}>${tax.toFixed(2)}</Col>}
                        <Col className="subtotal-text" xs={6}>Shipping</Col><Col className="subtotal-price" xs={6}>FREE!</Col>
                        {discount ? <Col className="subtotal-text green" xs={6}>Discount</Col> : null }
                        {discount ? <Col className="subtotal-price green" xs={6}>${discount.toFixed(2)}</Col> : null }
                        <Col className="total-text" xs={6}>{window.location.hash !== "#/prediction" ? "Total" : "Pre-Tax Total"}</Col><Col className="total-price" xs={6}><b>${(subtotal + tax - discount).toFixed(2)}</b></Col>
                    </Row>
                )
            return (
                <Row className={`order-row${window.location.hash !== "#/prediction" ? "" : " no-border"}`}>
                    <Col xs={12} className="no-padding">
                        <div className="order-products-row">
                            <div className="text-center">
                                <div className="order-cluster-img"><img src={images.everyday} alt="Everyday Essentials" /></div>
                                <h2 className="order-cluster-header">Everyday Essentials</h2>
                            </div>
                            {everydayRows}
                            {handWipes}
                            {
                                kitchenRows.length ?
                                <div>
                                    <div className="text-center space-top">
                                        <div className="order-cluster-img"><img src={images.kitchen} alt="Kitchen Essentials" /></div>
                                        <h2 className="order-cluster-header">Kitchen & Cleaning Essentials</h2>
                                    </div>
                                    {kitchenRows}
                                </div>
                                :
                                null
                            }
                            {bottomRow}
                    </div>
                    </Col>
                </Row>
            )
        }
    }

    handleAdditionalClick(groupName) {
        const {productsMap} = this.state;
        const element = document.getElementById(`${groupName}-svg`);
        if(element.matches(':hover')) {
            this.props.showDescription(productsMap[groupName])
        } else {
            this.props.updateSelection(productsMap[groupName])
        }
    }

    renderEditingRow(noMargin) {
        const {selectedProduct, productsMap, productTypes} = this.state;
        const {precheckout} = this.props;
        if(selectedProduct) {
            try {
                const product = productsMap[selectedProduct];
                const {additional} = productTypes[product.productType];
                const rows = [];
                const additionalRows = [];

                Object.values(additional).map(a => {
                    const additionalProduct = productsMap[a.groupName];
                    const {imageUrl, brandName, groupName, pricePerArticle, displayName, articles, articleType} = additionalProduct;
                    if(groupName !== selectedProduct) {
                        additionalRows.push(
                            <div className="order-additional-product-container" onClick={() => this.handleAdditionalClick(groupName)}>
                                <div className="order-additional-product text-center">
                                    <svg id={`${groupName}-svg`} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8"/>
                                        <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8"/>
                                        <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8"/>
                                    </svg>
                                    <img className="order-edit-img additional" src={imageUrl} alt={`${brandName} ${displayName}`}/>
                                    <div className="text-center">{brandName}</div>
                                    <div className="text-center">{articles} {getUnits(articleType, articles, true)} ${(pricePerArticle / 100 * articles).toFixed(2)}</div>
                                    <div className="text-center">@ ${(pricePerArticle/100).toFixed(2)} each</div>
                                </div>
                            </div>
                        )
                    } 
                })
                
                const {articles, articleType, imageUrl, brandName, displayName, pricePerArticle, groupName, productType} = product;
                rows.push(
                    <div className="order-product edit text-center">
                        <img className="order-edit-img text-center" src={imageUrl}  alt={`${brandName} ${displayName}`}/>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.props.showDescription(productsMap[groupName])}>
                            <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8"/>
                            <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8"/>
                            <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8"/>
                        </svg>
                        <div className="order-product-details text-center">
                            <div><b>{brandName}</b> {formatType(productType)}</div>
                            <Counter type="prediction" min={0} count={articles} increment={() => this.props.increment(productsMap[groupName])} units={getUnits(articleType, articles)} decrement={() => this.props.decrement(productsMap[groupName])} />
                            <div><span className="summary-product-price">${(articles * pricePerArticle/100).toFixed(2)}</span> @ ${(pricePerArticle/100).toFixed(2)} each</div>
                        </div>
                    </div>
                )

                const renderAdditional = () => {
                    if(additionalRows.length) {
                        return (
                            <div className="products-option-container">
                                <div className="other-options-text">Other Options:</div>
                                {additionalRows}
                            </div>
                        )
                    } else return (
                        <div className="products-option-container">
                            <div className="prediction-only-option">We're currently only offering {brandName} {formatType(productType)}.</div>
                        </div>
                    )
                }

                const cannotDelete = productType === "ToiletPaper" || productType === "PaperTowels" || productType === "TrashBagsKitchen";

                return (
                    <Row className={`order-edit-row${noMargin ? ' no-margin' : ''}`}>
                        {rows}
                        <p className={ additionalRows.length ? "interview-copy small" : "hide"}>Products come in different sizes and we are showing you your estimated quantity for each specific product</p>
                        {renderAdditional()}
                        <div className={precheckout && cannotDelete ? "hide": "btn-row"}>
                            <div onClick={() => this.props.removeProduct(product)} className="dashboard-cancel-link">Remove</div>
                        </div>
                    </Row>
                )

            } catch(err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    cancel() {
        this.props.cancelChanges();
        this.setState({selectedProduct: undefined})
    }

    saveChanges() {
        this.props.confirmChanges();
        this.setState({selectedProduct: undefined})
    }
    
    renderBtnRow() {
        const {edits} = this.props;
        if(edits) {
            return (
                <div className="order-row btn-row">
                    <div>
                        <button className="dashboard-btn dashboard-save" onClick={this.saveChanges}>Save Changes</button>
                        <button className="dashboard-btn dashboard-cancel-link" onClick={this.cancel}>Cancel</button>
                    </div>
                </div>
            )
        }
    }

    renderOrderTotal() {
        const {deliverAt, isTax, precheckout, subtotal, coupon} = this.props;
        const tax = isTax ? round(0.0825 * subtotal) : 0;
        let discount = 0;
        if(coupon && coupon.percentDiscount) {
            discount = round(coupon.percentDiscount/100 * subtotal);
        }
        if(window.location.hash !== "#/prediction") {
            return (
                <div>
                    <Row className="order-row top">
                        {deliverAt ? <Col xs={6} className="text-left order-label bold">Delivery Date</Col> : null}
                        <Col xs={deliverAt ? 6 : 12} className="text-right order-label bold">Total</Col>
                    </Row>
                    <Row className="order-row">
                        { deliverAt? <Col xs={6} md={2} onClick={this.showProducts} className="order-label">{moment(deliverAt).format('LL')}</Col> : null}
                        <Col xs={deliverAt ? 8 : 10} className="desktop"><div className="order-products-row"></div></Col>
                        <Col xs={deliverAt ? 6 : 12} md={2} onClick={this.showProducts} className="text-right order-label">
                            <div className="order-total">
                                ${(subtotal + tax - discount).toFixed(2)}
                                <svg data-tip data-for="manifest-total"  width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="interview-icon interview-info-icon">
                                    <circle className="info-circle" cx="10.5" cy="10" r="9.5" stroke="#183041" stroke-opacity="0.8"/>
                                    <path d="M13.5387 7.92904C13.5387 8.25456 13.491 8.54536 13.3955 8.80143C13.3 9.05751 13.1633 9.29405 12.9854 9.51107C12.8074 9.72808 12.5166 9.98416 12.113 10.2793L11.5921 10.6634C11.2796 10.8891 11.0474 11.1278 10.8955 11.3796C10.7436 11.627 10.6655 11.8982 10.6611 12.1934H9.52181C9.53049 11.8939 9.57606 11.6313 9.65853 11.4056C9.74533 11.1799 9.85601 10.9803 9.99056 10.8066C10.1251 10.633 10.277 10.479 10.4463 10.3444C10.6156 10.2055 10.787 10.0753 10.9606 9.95378C11.1342 9.82791 11.3035 9.70421 11.4684 9.58268C11.6377 9.45681 11.7874 9.31793 11.9176 9.16602C12.0479 9.01411 12.152 8.84266 12.2301 8.65169C12.3126 8.46072 12.3538 8.2372 12.3538 7.98112C12.3538 7.48633 12.1846 7.0957 11.846 6.80924C11.5118 6.52279 11.0409 6.37956 10.4333 6.37956C9.82563 6.37956 9.34386 6.53147 8.98796 6.83529C8.63205 7.13911 8.42372 7.55577 8.36296 8.08529L7.16504 8.00716C7.27789 7.14779 7.61643 6.48806 8.18066 6.02799C8.7449 5.56793 9.49143 5.33789 10.4202 5.33789C11.3881 5.33789 12.1498 5.56793 12.7054 6.02799C13.261 6.48372 13.5387 7.1174 13.5387 7.92904ZM9.46973 14.6478V13.3392H10.7393V14.6478H9.46973Z" fill="#183041" fillOpacity="0.8"/>
                                </svg>
                                <ReactTooltip
                                    id="manifest-total"
                                    place="bottom"
                                    effect="solid"
                                >
                                    <div id="manifest-total-header">What's included?</div>
                                    <div className="manifest-total-row">Subtotal = ${subtotal.toFixed(2)}</div>
                                    {discount ? <hr/> : null}
                                    {discount ? <div className="manifest-total-row green bold">Discount = ${discount.toFixed(2)}</div>
                                    : null}
                                    <hr />
                                    <div className="manifest-total-row">Estimated Tax = ${tax.toFixed(2)}</div>
                                    <hr />
                                    <div className="manifest-total-row">Shipping = Free</div>
                                </ReactTooltip>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="order container">
                {this.renderOrderTotal()}
                {this.renderProductsRow()}
                {this.renderBtnRow()}
            </div>
        )
    }
}

export default Order;