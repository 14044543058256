import React, {Component} from 'react';
import Feedback from '../Feedback';
import MoreInfo from '../MoreInfo';
import ProgressBar from '../ProgressBar';
import Counter from '../Counter';
import Header from '../../Header';
import images from '../../../img/images';
import Countdown from '../../Countdown';

class AddOns extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outdoorTrashFrequency: 0,
            moreInfoCopy: (
                <div>
                    We can take care of even more of your household needs by selecting any of these add-on products.
                    We will select specific products based on the preferences you previously provided.
                    And don't worry, you can still make any changes prior to checkout.
                </div>
            )
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.continue = this.continue.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        if(e.keyCode && e.keyCode === 13) {
            this.continue();
        }
    }

    componentDidMount() {
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        const outdoorTrashFrequency = JSON.parse(sessionStorage.getItem('outdoorTrashFrequency'));
        const addOns = JSON.parse(sessionStorage.getItem('addOns'));
        if(addOns) {
            let index = addOns.indexOf("FacialTissue")
            if(index > -1) {
                this.setState({tissues: true})
            }
            index = addOns.indexOf("HandWipes")
            if(index > -1) {
                this.setState({handWipes: true})
            }
        }
        if(outdoorTrashFrequency) this.setState({outdoorTrashFrequency})
        this.setState({hasKitchen});

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})
    }

    increment() {
        const {outdoorTrashFrequency} = this.state;
        this.setState({outdoorTrashFrequency: outdoorTrashFrequency + 1});
    }

    decrement() {
        const {outdoorTrashFrequency} = this.state;
        this.setState({outdoorTrashFrequency: outdoorTrashFrequency - 1})
    }

    continue() {
        const {
            handWipes,
            outdoorTrashFrequency,
            tissues
        } = this.state;

        const addOns = [];

        if(handWipes) addOns.push('HandWipes');
        if(tissues) addOns.push('FacialTissue');
        if(outdoorTrashFrequency) addOns.push('TrashBagsOutdoor');

        window.removeEventListener('keydown', this.handleKeydown, false)
        sessionStorage.setItem('outdoorTrashFrequency', JSON.stringify(outdoorTrashFrequency));
        sessionStorage.setItem('addOns', JSON.stringify(addOns));
        this.props.setAddOns(addOns);
    }

    renderTopRow() {
        const {hasKitchen} = this.state;
		return (
            <div className="interview-nav-container">
                <div className="interview-top-row">
                    <img src={images.back_arrow} onClick={() => this.props.history.push(hasKitchen ? '/kitchen/3' : '/start/5')} className="interview-icon interview-back-arrow" />
                    <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                </div>
			</div>
		)
    }

    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderContinue() {
        return (
            <div className="interview-continue-wrapper">
                <button className="interview-continue-button" onClick={this.continue}>Continue</button>
                <p className="interview-enter-text">press <b>enter</b></p>
            </div>
        )
    }

    renderAddOns() {
        const {
            handWipes,
            tissues,
            outdoorTrashFrequency
        } = this.state;

        return (
            <div className="tiers-container">
                <div className="tier-pill-wrapper">
                    <div className="product-icon"><img src={images.tissues} className="tier-emoji" /></div>
                    <div id="brand-btn" className={`interview-pill ${ tissues ? 'selected' : ''}`} onClick={() => this.setState({tissues: !tissues})}>Facial Tissues</div>
                    <div className="tier-rank"></div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="product-icon"><img src={images.handWipes} className="tier-emoji" /></div>
                    <div id="brand-btn" className={`interview-pill ${ handWipes ? 'selected' : ''}`} onClick={() => this.setState({handWipes: !handWipes})}>Hand Wipes</div>
                    <div className="tier-rank"></div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="product-icon"><img src={images.trashBin} className="tier-emoji" /></div>
                    <div id="brand-btn" className={`interview-pill ${ outdoorTrashFrequency ? 'selected' : ''}`} onClick={() => this.setState({outdoorTrashFrequency: outdoorTrashFrequency ? 0 : 1})}>Large (39 gal) Trash Bags</div>
                    <div className="tier-rank"></div>
                </div>
            </div>
        )
    }
    
    renderCounter() {
		const {outdoorTrashFrequency} = this.state;
        if(outdoorTrashFrequency) {
            return (
                <div>
                    <p className="interview-copy">
                        How many Outdoor Trash Bags do you use each week?
                    </p>
                    <Counter type="interview" min={0} count={outdoorTrashFrequency} increment={this.increment} decrement={this.decrement} />
                </div>
            )
        }
    }
    
    renderCountdown() {
        if(this.state.zipcode) return <Countdown spaceTop={true} />
    }

    render() {
        const {zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={95}/>
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
                <div className="interview-outer">
                    {this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img relative">
                            <img src={images.house} alt="House" id="loading-house"/>
                            <img src={images.cog} alt="Cog" id="loading-cog"/>
                        </div>
                        <div className="interview-copy-container">
                            <p className="interview-copy header">
                                While we calculate the exact home supplies you need, would you also like us to take care of the following items for your home?
                            </p>
                        </div>
                        {this.renderAddOns()}
                        {this.renderCounter()}
                        {this.renderContinue()}
                    </div>
                    <Feedback question={'tierPreferences'} />
                </div>              
            </div>   
        )
    }
}

export default AddOns;