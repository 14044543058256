'use strict';
import config from '../config';
import { Auth } from 'aws-amplify';
const {signApiGatewayRequest} = require('ags4');

const prod = config.prod.hostNames.includes(window.location.hostname);

export default async function getSignature(method, payload, path, queryParams) {
    try{
        const credentials = await Auth.currentCredentials();
        
        let request = {
            host: prod ? config.prod.apiHostName : config.dev.apiHostName,
            method: method,
            data: payload,
            path: prod ? `${path}` : `${path}`,
            queryParams: queryParams ? queryParams : {},
            headers: {
              'content-type': 'application/json',
            }
          }

          Auth.currentSession().then(data => {
              request.headers['X-SupplyDrop-CustomerId'] = data.idToken.jwtToken
            }).catch(() => {
              // it's cool
            })

        request = signApiGatewayRequest(request, credentials);

        return {success: true, request};
    }
    catch(err) {
        return {success: false, error: err}; 
    }
}
