import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';

export const handleApiError = (error, type) => {
    if(error.response && error.response.data) {
        Sentry.addBreadcrumb({
            category: "error response",
            message: error.response.data,
            level: Sentry.Severity.Info
        })
    }
    Sentry.captureException(error);
    return (dispatch) => dispatch({type,error})
} 

export const getAuthHeaders = (additionalHeaders) => new Promise((resolve, reject) => {
    Auth.currentSession().then(data => {
        const headers = { 'Authorization': data.idToken.jwtToken };
        if(additionalHeaders) {
            Object.entries(additionalHeaders).forEach(entry => {
                headers[entry[0]] = entry[1];
            });
        }
        resolve({headers});
    }).catch(err => {
        Sentry.captureException(err);
        reject(err);
    })
})