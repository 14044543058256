import keys from './config';

const prod = keys.prod.hostNames.includes(window.location.hostname);

export default {
  Auth: {
    region: 'us-east-1',
    identityPoolId: prod ?  'us-east-1:5aa9abf0-360e-41d6-a7cd-778237f36d3b' : 'us-east-1:dbc8e631-320a-47a0-b3d1-901c3f0ada80',
    userPoolId: prod ? 'us-east-1_EyJXuHdVy' : 'us-east-1_hGgf53hXd',
    userPoolWebClientId: prod ? '3ldka62qfer0ctaogg14fakosj' : '69d9c7d4jqo1jqnjm9160g3kcq'
  }
};
