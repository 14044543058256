import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { getRounder, formatType, clearRounder, getProductGroups, round, marginForCountdown } from '../../actions';
import Feedback from './Feedback';
import ProgressBar from './ProgressBar';
import ProductDetail from './ProductDetail';
import MoreInfo from './MoreInfo';
import Order from '../Order';
import AddOns from './Everyday/AddOns';
import Header from '../Header';
import Saving from '../user/Saving';
import images from '../../img/images';
import {Alert} from 'react-bootstrap';
import AddProducts from '../user/AddProducts';
import Countdown from '../Countdown';
import '../../Style/order.css';
import keys from '../../config';
const prod = keys.prod.hostNames.includes(window.location.hostname);

class OrderPrediction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            options: undefined,
            isSaving: false,
            showRemoveDialog: false,
            selectedItem: undefined,
            quantityChanges: {},
            productChanges: {},
            subtotal: 0,
            addOns: [],
            everydayProductsSet: [
                "ToiletPaper",
                "PaperTowels",
                "TrashBagsKitchen"
            ],
            productsMap: {},
            kitchenProductsSet: [],
            addOns: [],
            products: {}
        }

        this.renderRowResults = this.renderRowResults.bind(this);
        this.onConfirmOrderClick = this.onConfirmOrderClick.bind(this);
        this.setOptions = this.setOptions.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.retryRounder = this.retryRounder.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
        this.handleQuantityChangeReason = this.handleQuantityChangeReason.bind(this);
        this.handleProductChangeReason = this.handleProductChangeReason.bind(this);
        this.validateQuantityModal = this.validateQuantityModal.bind(this);
        this.submitProductChange = this.submitProductChange.bind(this);
        this.showDescription = this.showDescription.bind(this);
        this.setAddOns = this.setAddOns.bind(this);
        this.viewAddOns = this.viewAddOns.bind(this);
        this.addProducts = this.addProducts.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.removeKeydown = this.removeKeydown.bind(this);
        this.addKeydown = this.addKeydown.bind(this);
        this.cancelAddingProducts = this.cancelAddingProducts.bind(this);
        this.setToAddingProducts = this.setToAddingProducts.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.clearLimits = this.clearLimits.bind(this);
        this.setSubtotal = this.setSubtotal.bind(this);
    }

    componentWillUnmount() {
        this.removeKeydown();
    }

    addKeydown() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }
    
    removeKeydown() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo, viewedAddOns} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else if(viewedAddOns) {
                this.onConfirmOrderClick();
            }
        }
    }

    componentDidMount() {
        if(prod) window.fbq('trackCustom', 'InterviewCompleted');
        window.scrollTo(0, 0);

        let interview;

        const bathrooms = JSON.parse(sessionStorage.getItem('bathrooms'));
        const family = JSON.parse(sessionStorage.getItem('family'));
        const trashFrequency = JSON.parse(sessionStorage.getItem('trashFrequency'));
        const outdoorTrashFrequency = JSON.parse(sessionStorage.getItem('outdoorTrashFrequency'));
        const tierPreferences = JSON.parse(sessionStorage.getItem('tierPreferences'));
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        const viewedAddOns = JSON.parse(sessionStorage.getItem('viewedAddOns'));
        const productsMap = JSON.parse(sessionStorage.getItem('productsMap'));
        const addOns = JSON.parse(sessionStorage.getItem('addOns'));
        const kitchenProductsSet = [];
        const dishwashing = JSON.parse(sessionStorage.getItem('dishwashing'));
        const housekeeping = JSON.parse(sessionStorage.getItem('housekeeping'));
        const cookingFrequency = JSON.parse(sessionStorage.getItem('cookingFrequency'));
        interview = JSON.parse(sessionStorage.getItem('interview'));

        if(!interview) {
            if(!family) this.props.history.push('/start/1');
            else if(!bathrooms) this.props.history.push('/start/3');
            else if(!trashFrequency) this.props.history.push('/start/4');
            else if(!tierPreferences) this.props.history.push('/start/5');
            else {
                const pets = JSON.parse(sessionStorage.getItem('pets'));
                const newFamily = [];
        
                family.forEach(member => {
                    const {gender, age, staysHome, isolated} = member
                    newFamily.push({gender, age, staysHome, isolated})
                });
        
                interview = {
                    family: newFamily,
                    pets,
                    bathrooms,
                    trashPreferences: [{
                        type: "Kitchen",
                        frequency: trashFrequency,
                        bags: 1
                    }],
                    tierPreferences
                }
        
                if(outdoorTrashFrequency) {
                    interview.trashPreferences.push({
                        type: "Outdoor",
                        frequency: outdoorTrashFrequency,
                        bags: 1
                    })
                }
                if(dishwashing) interview.dishwashing = dishwashing;
                if(housekeeping) interview.housekeeping = housekeeping;
                if(cookingFrequency) interview.cookingFrequency = cookingFrequency;
            }
        } else {
            this.props.getRounder(interview);
        }

        if(!productsMap) {
            this.props.getProductGroups();
        } else {
            this.setState({productsMap})
        }
            
        if(hasKitchen) {
            kitchenProductsSet.push("DishSoap", "HandSoap");
            if(dishwashing.frequency) kitchenProductsSet.push("DishwasherDetergent");
        }

        const products = JSON.parse(sessionStorage.getItem('products'));
        if(products) {
            this.setState({products})
        }

        this.setState({interview, kitchenProductsSet, hasKitchen, outdoorTrashFrequency});
        sessionStorage.setItem('interview', JSON.stringify(interview));
        
        // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
        // if(coupon) {
        //     this.setState({coupon})
        // }

        if(viewedAddOns) {
            this.addKeydown();
        }
        
        this.setState({viewedAddOns, addOns});

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    componentDidUpdate() {
        // const {rounderError, productGroupsError, productGroups} = this.props;
        const {rounderError, rounder, productGroupsError, productGroups} = this.props;
        const { options, productsMap } = this.state;

        if(productGroups && !this.state.productGroups) {
            productGroups.forEach(pg => {
                productsMap[pg.groupName] = {};
                productsMap[pg.groupName].groupName = pg.groupName;
                productsMap[pg.groupName].brandName = pg.brandName;
                productsMap[pg.groupName].displayName = pg.displayName;
                productsMap[pg.groupName].pricePerArticle = pg.pricePerArticle;
                productsMap[pg.groupName].articleType = pg.articleType;
                productsMap[pg.groupName].badges = pg.badges;
                productsMap[pg.groupName].imageUrl = pg.imageUrl;
                productsMap[pg.groupName].description = pg.description;
                productsMap[pg.groupName].productType = pg.productType;
                productsMap[pg.groupName].possibleArticleCounts = pg.possibleArticleCounts;
            })
            sessionStorage.setItem('productsMap', JSON.stringify(productsMap));
            this.setState({productGroups, productsMap});
        }

        if(rounderError && !this.state.rounderError) {
            this.setState({rounderError});
        }

        // const rounder = {
        //     "productSets": {
        //         "ToiletPaper": {
        //             "preferred": { groupName: "toilet-paper-angel-soft-mega-rolls", proposedArticles: 8 },
        //             "additional": [
        //                 {groupName: "toilet-paper-cottonelle-ultra-clean-care-mega", proposedArticles: 12},
        //                 {groupName: "toilet-paper-caboo-super-soft-bamboo-double-rolls", proposedArticles: 12}
        //             ] 
        //         },
        //         "PaperTowels": { 
        //             "preferred": { groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double", proposedArticles: 3 },
        //             "additional": [{groupName: "paper-towels-bounty-select-a-size-double", proposedArticles: 6}]  
        //         },
        //         "LaundryDetergent": { 
        //             "preferred": { groupName: "laundry-detergent-puracy-natural-liquid-free-and-clear", proposedArticles: 1 },
        //             "additional": []  
        //         },
        //     }
        // }

        if (!options && rounder && productsMap && !this.state.rounder) {
            this.setState({rounder});
            this.setOptions(rounder, productsMap);
        }
        if(!rounder && this.state.rounder) {
            this.setState({rounder, options: undefined})
        }
    }

    setSubtotal(products, productsMap) {
        try {
            let subtotal = 0;
            Object.values(products).forEach(type => {
                if(Object.values(type.selected).length) {
                    const {groupName} = type.selected;
                    const product = productsMap[groupName];
                    const {articles, pricePerArticle} = product;
                    subtotal += (articles * pricePerArticle / 100);
                }
            })
            this.setState({subtotal});
            return subtotal;
        } catch(err) {
            console.error(err);
        }
    }

    setOptions(rounder, productsMap) {
        try {
            const {addOns, everydayProductsSet, kitchenProductsSet, products} = this.state;
            Object.entries(rounder.productSets).forEach(entry => {
                productsMap[entry[1].preferred.groupName].articles = entry[1].preferred.proposedArticles;
                productsMap[entry[1].preferred.groupName].proposedArticles = entry[1].preferred.proposedArticles;
                
                let includeProduct = false;

                if(everydayProductsSet.indexOf(entry[0]) > -1 || addOns.indexOf(entry[0]) > -1 || kitchenProductsSet.indexOf(entry[0]) > -1) {
                    includeProduct = true;
                }
                const {groupName} = entry[1].preferred;
                const pMGN = productsMap[groupName];
                const maxQuantity = pMGN.possibleArticleCounts[pMGN.possibleArticleCounts.length - 1]
                if(entry[1].preferred.proposedArticles > maxQuantity) {
                    pMGN.possibleArticleCounts.push(entry[1].preferred.proposedArticles);
                }
                if(pMGN.possibleArticleCounts.indexOf(entry[1].preferred.proposedArticles) < 0) {
                    pMGN.possibleArticleCounts.push(entry[1].preferred.proposedArticles);
                    pMGN.possibleArticleCounts.sort((a,b) => {return a-b})
                }
                const additional = entry[1].additional.map(product => {
                    const p = productsMap[product.groupName]
                    p.proposedArticles = product.proposedArticles;
                    p.articles = product.proposedArticles;
                    return p;
                })

                additional.push(pMGN)

                if(!products[productsMap[entry[1].preferred.groupName].productType]) {
                    const additional = entry[1].additional.map(product => {
                        const p = productsMap[product.groupName]
                        if(product.proposedArticles > p.possibleArticleCounts[p.possibleArticleCounts.length - 1]) {
                            p.possibleArticleCounts.push(product.proposedArticles);
                        }
                        if(p.possibleArticleCounts.indexOf(product.proposedArticles) < 0) {
                            p.possibleArticleCounts.push(product.proposedArticles);
                            p.possibleArticleCounts.sort((a,b) => {return a-b})
                        }
                        p.proposedArticles = product.proposedArticles;
                        p.articles = product.proposedArticles;
                        return p;
                    })
                    products[productsMap[entry[1].preferred.groupName].productType] = {
                        selected: includeProduct? productsMap[entry[1].preferred.groupName] : {},
                        edited: includeProduct? productsMap[entry[1].preferred.groupName] : {},
                        preferred: productsMap[entry[1].preferred.groupName],
                        additional
                    };
                } else {
                    products[productsMap[entry[1].preferred.groupName].productType] = {
                        selected: products[productsMap[entry[1].preferred.groupName].productType].selected ? products[productsMap[entry[1].preferred.groupName].productType].selected : includeProduct ? productsMap[entry[1].preferred.groupName] : {},
                        edited: products[productsMap[entry[1].preferred.groupName].productType].edited ? products[productsMap[entry[1].preferred.groupName].productType].edited : includeProduct? productsMap[entry[1].preferred.groupName] : {},
                        preferred: productsMap[entry[1].preferred.groupName],
                        additional
                    };
                }
                products[productsMap[entry[1].preferred.groupName].productType].preferred = productsMap[entry[1].preferred.groupName];
                products[productsMap[entry[1].preferred.groupName].productType].additional = additional;

            });    
            sessionStorage.setItem('productsMap', JSON.stringify(productsMap));
            this.setState({productsMap, products});
            this.setSubtotal(products, productsMap);
        } catch(err) {
            console.error(err);
        }
    }

    onConfirmOrderClick() {
        this.clearLimits();
        const { products, isSaving, newChanges, productChanges, productsMap, rounder } = this.state;
        const interview = JSON.parse(sessionStorage.getItem('interview'));

        if (!isSaving && products) {
            const order = {
                products: {}
            };
            const quantityChanges = []
            Object.values(products).forEach(group => {
                if(group.edited.articles) {
                    order.products[group.edited.groupName] = {articleCount: group.edited.articles};
                    if(group.edited.articles !== group.edited.proposedArticles) {
                        quantityChanges.push({product: group.edited, incremented: group.edited.articles > group.edited.proposedArticles})
                    }
                }
                group.selected = group.edited;
            });

            sessionStorage.setItem('interview', JSON.stringify(interview));
            sessionStorage.setItem('rounder', JSON.stringify(rounder));
            sessionStorage.setItem('products', JSON.stringify(products)); 
            sessionStorage.setItem('productsMap', JSON.stringify(productsMap)); 
            
            Object.entries(order.products).forEach(entry => {
                const groupName = entry[0];
                if(!entry[1].quantityChangeReason) entry[1].quantityChangeReason = "None";
                if(productChanges[groupName]) {
                    entry[1].productChangeReason = productChanges[groupName];
                } else {
                    entry[1].productChangeReason = "None";
                }
            })
            sessionStorage.setItem('order', JSON.stringify(order));

            if(quantityChanges.length && newChanges) {
                this.setState({quantityChanges});
            } else {
                this.props.history.push("/checkout");            
            }
        }
    }

    retryRounder() {
        const {interview} = this.state;
        this.props.getRounder(interview);
        this.setState({loading: true, rounderError: false})
    }

    increment(selectedProduct) {
        const {productsMap, products} = this.state;
        try {
            const {possibleArticleCounts} = productsMap[selectedProduct.groupName];
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);
            if(!selectedProduct.articles) {
                if(selectedProduct.articles !== possibleArticleCounts[0]) selectedProduct.articles = possibleArticleCounts[0];
                else selectedProduct.articles = possibleArticleCounts[1]
                productsMap[selectedProduct.groupName] = selectedProduct;
                products[selectedProduct.productType].edited.articles = selectedProduct.articles;

                this.setState({productsMap, newChanges: true, quantityLimitReached: false});
                this.setSubtotal(products, productsMap)
            } else if(index < 0 || possibleArticleCounts.length <= index + 1 || possibleArticleCounts[index + 1] > (selectedProduct.proposedArticles * 1.25)) {
                const quantityLimitReached = `Maximum quantity reached for ${selectedProduct.brandName} ${selectedProduct.displayName}`
                this.setState({quantityLimitReached});
            } else {
                selectedProduct.articles = possibleArticleCounts[index + 1];
                productsMap[selectedProduct.groupName] = selectedProduct;
                products[selectedProduct.productType].edited.articles = selectedProduct.articles;

                this.setState({productsMap, newChanges: true, quantityLimitReached: false});
                this.setSubtotal(products, productsMap)
            }
        } catch(err) {
            console.error(err);
        }
    }

    decrement(selectedProduct) {
        const {productsMap, products} = this.state;
        try {
            const {possibleArticleCounts} = productsMap[selectedProduct.groupName];
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);
            let quantityLimitReached;
            if(index > 0) {
                selectedProduct.articles = possibleArticleCounts[index - 1];
                products[selectedProduct.productType].edited.articles = selectedProduct.articles;
            } else {
                selectedProduct.articles = 0;
                products[selectedProduct.productType].edited.articles = selectedProduct.articles;
            }
            productsMap[selectedProduct.groupName] = selectedProduct;
            this.setState({productsMap, newChanges: true, quantityLimitReached, products});
            this.setSubtotal(products, productsMap)
        } catch(err) {
            console.error(err);
        }
    }

    updateSelection(product) {
        const {products, productsMap} = this.state;

        products[product.productType].edited = product;
        products[product.productType].selected = product;
        const index = products[product.productType].additional.map( item => { return item.groupName }).indexOf(product.groupName);
        products[product.productType].additional.splice(index, 1);

        const changedProduct = {};
        
        changedProduct.groupName = product.groupName;

        this.setSubtotal(products, productsMap);
        this.setState({products, changedProduct, quantityLimitReached: false});
    }

    clearLimits() {
        this.setState({quantityLimitReached: false});
    }


    closeDetail() {
        window.addEventListener('keydown', this.handleKeydown, false);
        this.setState({showProduct: undefined});
    }

    setAddOns(addOns) {
        const {interview} = this.state;
        const outdoorTrashFrequency = JSON.parse(sessionStorage.getItem('outdoorTrashFrequency'));
        if(outdoorTrashFrequency !== this.state.outdoorTrashFrequency && interview) {
            const index = interview.trashPreferences.map( item => { return item.type }).indexOf("Outdoor");
            if(index > -1) {
                interview.trashPreferences.splice(index, 1);
            }
            if(outdoorTrashFrequency) {
                interview.trashPreferences.push({
                    type: "Outdoor",
                    frequency: outdoorTrashFrequency,
                    bags: 1
                });
            }
        }
        this.props.clearRounder();
        this.setState({loading: true, rounderError: false, outdoorTrashFrequency, interview, addOns, viewedAddOns: true})
        this.props.getRounder(interview);
        sessionStorage.setItem('viewedAddOns', JSON.stringify(true));
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    renderError() {
        const {rounderError} = this.state;
        if(rounderError) {
            return (
                <Saving error={rounderError} body="Try again?" tryAgain={this.retryRounder}/>
            )
        }
    }

    renderQuantityLimitReached() {
        const {quantityLimitReached} = this.state;
        if(quantityLimitReached) {
            return (
                <Alert variant="warning" className="alert-fixed-bottom">{quantityLimitReached}</Alert>
            )
        }
    }

    handleQuantityChangeReason(groupName, reason) {
        const {quantityChanges} = this.state;
        for (let i = 0; i < quantityChanges.length; i++) {
            if(quantityChanges[i].product.groupName === groupName) {
                quantityChanges[i].reason = reason;
                break;
            }
        }
        this.setState({quantityChanges})
    }

    handleProductChangeReason(reason) {
        const {changedProduct} = this.state;
        changedProduct.reason = reason;
        this.setState({changedProduct});
    }

    validateQuantityModal() {
        this.clearLimits();
        const {quantityChanges} = this.state;

        let needReasons = false;

        for(let i = 0; i < quantityChanges.length; i++) {
            if(!quantityChanges[i].reason) {
                needReasons = true;
                break;
            }
        }

        if(needReasons) {
            this.setState({needReasons});
        } else {
            try {
                const order = JSON.parse(sessionStorage.getItem('order'))
                quantityChanges.forEach(change => {
                    const {product, reason} = change;
                    order.products[product.groupName].quantityChangeReason = reason;
                })

                Object.values(order.products).forEach(product => {
                    if(!product.quantityChangeReason) product.quantityChangeReason = "None";
                    if(!product.productChangeReason) product.productChangeReason = "None";
                })
                sessionStorage.setItem('order', JSON.stringify(order));
                this.props.history.push('/checkout');
            } catch (err) {
                console.error(err);
            }
        }
    }

    submitProductChange() {
        this.clearLimits();
        const {changedProduct, productChanges} = this.state;
        if(!productChanges[changedProduct.groupName]) productChanges[changedProduct.groupName] = {};
        productChanges[changedProduct.groupName] = changedProduct.reason;
        this.setState({changedProduct: undefined, productChanges});
    }

    renderQuantityModal() {
        const {quantityChanges, needReasons} = this.state;
        if(quantityChanges && quantityChanges.length) {
            const rows = [];
            quantityChanges.forEach(change => {
                const {incremented, product} = change;
                rows.push(
                    <div className="modal-prediction-question">
                        <div className="prediction-modal-header">
                            We are in beta and your feedback helps us tune your recommendation. Please let us know why you need {incremented ? "more" : "less"} {formatType(product.productType).toLowerCase()}?
                        </div>
                        <div className="prediction-modal-form">
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(product.groupName, incremented ? "EstimateTooLow" : "EstimateTooHigh")}>
                                <div className={`prediction-modal-radio ${change.reason === "EstimateTooLow" || change.reason === "EstimateTooHigh" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I use {incremented ? "more" : "less"}</label>
                            </div>
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(product.groupName, incremented ? "PoorlyStocked" : "WellStocked")}>
                                <div className={`prediction-modal-radio ${change.reason === "PoorlyStocked" || change.reason === "WellStocked" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I'm {incremented ? "totally out" : "well stocked"}</label>
                            </div>
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(product.groupName, incremented ? "HavingGuests" : "Traveling")}>
                                <div className={`prediction-modal-radio ${change.reason === "HavingGuests" || change.reason === "Traveling" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I'm {incremented ? "having guests" : "traveling"}</label>
                            </div>
                        </div>
                    </div>
                )
            })
            return (
                <div className="survey-modal-container">
                    <div className="signin-modal-body">
                        <div className="signin-modal-tab-container">
                            <div className="prediction-modal-body">
                                {rows}
                                <Alert variant="warning" className={needReasons ? "signin-modal-alert" : "hide"}>Please select a response for each.</Alert>
                            </div>
                        </div>
                        <button className="interview-continue-button" onClick={this.validateQuantityModal}>Submit</button>
                    </div>
                </div>
            )
        }
    }

    renderProductChangeModal() {
        const {changedProduct} = this.state;
        if(changedProduct) {
            return (
                <div className="modal-container light">
                    <div className="signin-modal-body">
                            <div className="prediction-modal-body">
                                <div className="modal-prediction-question">
                                    <div className="prediction-modal-header">
                                        We are in beta and your feedback helps us tune your recommendation. Please let us know why you made this product change.
                                    </div>
                                    <div className="prediction-modal-form">
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("AlreadyUse")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "AlreadyUse" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">I already use this brand and want to stick with it</label>
                                        </div>
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("WantToTry")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "WantToTry" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">I have heard of this brand and want to try it</label>
                                        </div>
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("TooExpensive")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "TooExpensive" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">The recommended brand was too expensive</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <button disabled={!changedProduct.reason} className="interview-continue-button" onClick={this.submitProductChange}>Submit</button>
                    </div>
                </div>
            )
        }
    }

    renderRowResults() {
        const { products, subtotal, rounder, productsMap, everydayProductsSet, addOns, kitchenProductsSet, edits, coupon, addingProducts } = this.state;

        if (rounder && productsMap && !addingProducts) { 
            const productsSet = [...everydayProductsSet, ...addOns, ...kitchenProductsSet];

            sessionStorage.setItem('productsSet', JSON.stringify(productsSet));
            return (
                <div>
                    <div className="more-info-copy">
                        <div className="interview-copy">
                            <b>Here's our recommended first delivery.</b><br/>
                            <span className="order-prediction-subheader">If you would like to make adjustments, you can easily change your product selection or quantity below. In order to ensure every customer has what they need every month, there is a maximum quantity allowed for certain products.</span>
                        </div>
                    </div>
                    <Order
                        showDescription={this.showDescription}
                        updateSelection={this.updateSelection}
                        increment={this.increment}
                        decrement={this.decrement}
                        addProducts={this.setToAddingProducts}
                        productTypes={products}
                        coupon={coupon}
                        productSet={productsSet}
                        productsMap={productsMap}
                        edits={edits}
                        clearLimits={this.clearLimits}
                        subtotal={subtotal}
                        precheckout={true}
                        confirmChanges={this.confirmChanges}
                        removeProduct={this.removeProduct}
                    />
                </div>
            );
        }
    }

    setToAddingProducts() {
        this.removeKeydown();
        this.setState({addingProducts: true})
    }

    removeProduct(product) {
        const {products, productsMap} = this.state;
        try {
            products[product.productType].edited = {};
            products[product.productType].selected = {};
            this.setState({products})
            this.setSubtotal(products, productsMap)
        } catch(err) {
            console.error(err);
        }
    }

    showDescription(product) {
        this.setState({showProduct: product})
    }

    renderBackModal() {
        const {navigatingBack} = this.state;
        if(navigatingBack) {
            return (
                <div className="modal-container transparent">
                        <div className="interview-question-modal">
                            <img onClick={() => {this.setState({navigatingBack: false})}} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                            <div className="interview-modal-body$ no-padding">
                            <div className="signin-modal-tab-container">
                                <div className="cancel-modal-body">
                                    <p className="modal-title">
                                        Are you sure you want to go back?
                                    </p>
                                    <hr />
                                    <p className="modal-p">
                                        You will lose any changes made to your order.
                                    </p>
                                </div>
                            </div>
                            <div className="cancel-modal-button-row">
                                <button className="dashboard-btn dashboard-submit white" onClick={() => {this.setState({navigatingBack: false})}}>No, stay here</button>
                                <button className="dashboard-btn dashboard-cancel-btn" onClick={this.viewAddOns}>Yes, go back</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    viewAddOns() {
        this.removeKeydown();
        sessionStorage.removeItem('products');
        this.setState({viewedAddOns: false, navigatingBack: false});
    }

    renderProductView() {
        const {showProduct} = this.state;
        if(showProduct) {
            window.removeEventListener('keydown', this.handleKeydown, false);
            return <ProductDetail product={showProduct} close={this.closeDetail} />
        } 
    }

    renderContinue() {
        const {addingProducts} = this.state;
        if(!addingProducts) {
            return (
                <div className="interview-continue-wrapper">
                    <button className="dashboard-btn dashboard-save cta" onClick={this.onConfirmOrderClick}>Checkout</button>
                    <p className="interview-enter-text">press <b>enter</b></p>
                </div>
            )
        }
    }

    renderAddProducts() {
        const {addingProducts, products, productsMap, interview, newOptions, subtotal} = this.state;
        if(addingProducts) {
            return (
                <AddProducts
                    key={0}
                    productTypes={products}
                    productsMap={productsMap}
                    updateSelection={this.updateSelection}
                    showDescription={this.showDescription}
                    updateInterview={this.updateInterview}
                    newOptions={newOptions}
                    clearNewOptions={() => this.setState({newOptions: false})}
                    subtotal={subtotal}
                    interview={interview}
                    addProducts={this.addProducts}
                    cancelChanges={this.cancelAddingProducts}
                />
            )
        }
    }

    updateInterview(interview) {
        this.setState({interview});
        sessionStorage.setItem('interview', JSON.stringify(interview));
    }

    cancelAddingProducts() {
        this.addKeydown();
        this.setState({addingProducts: false});
    }

    addProducts(products) {
        const {productsMap} = this.state;
        this.setState({products});
        this.setSubtotal(products, productsMap);
        this.cancelAddingProducts();
    }

    renderTopRow() {
        const {addingProducts} = this.state;
        return (
            <div className="interview-top-row">
                <img src={images.back_arrow} onClick={() => this.setState({navigatingBack: true})} className={addingProducts ? "hide" : "interview-icon interview-back-arrow"} />
                <div></div>
            </div>
        )
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown spaceTop={true} />
    }

	render() {
        const {products, viewedAddOns, rounder, productsMap, zipcode} = this.state;
        if(products && viewedAddOns && productsMap) {
            return (
                <div>
                    <ProgressBar progress={100} />
                    {this.renderCountdown()};
                    <Header history={this.props.history} spaceTopSm={zipcode} />
                    {this.renderProductView()}
                    {this.renderQuantityModal()}
                    {this.renderProductChangeModal()}
                    {this.renderBackModal()}
                    <div className="interview-outer">
                        <div className="interview-nav-container">
                            {this.renderTopRow()}
                        </div>
                        <div className="interview-inner fadeInRight animated-fast">
                            {this.renderAddProducts()}
                            {this.renderRowResults()}
                            {this.renderContinue()}
                            {this.renderQuantityLimitReached()}
                        </div>
                        <Feedback question={'prediction'} isPostInterview={true}/>
                    </div>
                </div>
            )
        } else if(viewedAddOns) {
            return (
                <div>
                    <ProgressBar />
                    {this.renderCountdown()}
                    <Header history={this.props.history} spaceTopSm={zipcode} />
                    {this.renderError()}
                    <div className="interview-outer">
                        <div className="interview-inner fadeInRight animated-fast rounded prediction-loading">
                            <div className="text-center">
                                <img src={images.predicting} className="pulse animated-super-slow infinite delay1" />
                            </div>
                            <div className="interview-copy">
                                <b>Voila! You’re all done.</b><br/>
                                We’re calculating your first order.
                            </div>
                        </div>
                        <Feedback question={'prediction'} isPostInterview={true}/>
                    </div>
                </div>
            )
        } else {
            return (
                <AddOns setAddOns={this.setAddOns} history={this.props.history} />
            )
        }
	}
}

const mapStateToProps = (state) => {
    const { productGroups, productGroupsError } = state.product;
	const { error, rounder, rounderError, gettingRounder } = state.interview;
	return { error, rounder, rounderError, gettingRounder, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getRounder,
    clearRounder,
    getProductGroups
})(OrderPrediction);