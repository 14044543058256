import React, {Component} from 'react';
import DocDialog from './DocModal';
import images from '../img/images';
import '../Style/footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            doc: undefined
        }

        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    renderDialog() { 
        const {doc, showDialog} = this.state;
        if(showDialog && !!doc) {
            return <DocDialog doc={doc} hide={this.hideDialog}/>
        }
    }

    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }

    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }

    render() {
        const {showDialog} = this.state;
        const {landing} = this.props;
        return (
            <div className={`footer-container${showDialog ? ' z-1000' : ''}${landing? " landing-footer" : "" }`}>
                {this.renderDialog()}
                <div className="lnd-footer">
                    <div className="footer-wrapper">
                        <div className="footer-left">
                            {/* <img src={images.logo_dark} id="footer-logo" alt="Supply Drop"/> */}
                            <div className="footer-text">© {new Date().getFullYear()} Supply Drop Online, Inc.</div>
                        </div> 
                        <div className="footer-right">
                            <span onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</span>
                            <span onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</span>
                            {/* <span onClick={() => this.props.history.push('/about')}>Help</span> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;