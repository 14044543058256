import React, {Component} from 'react';
import Header from './Header';
import images from '../img/images';

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <Header history={this.props.history} />
                <div className="interview-outer">
                    <div className="interview-inner rounded">
                    <div className="btn-container">
                        <img src={images.not_found} alt="404" className="img-404-tp" />
                    </div>
                        <h1 className="landing-main-header">Oops! That page can't be found.</h1>
                        <p className="interview-copy">The page you are looking for may have been removed,
                            had its name changed or is temporarily unavailable.</p>
                            <div className="btn-container">
                                <button className="btn-blue btn-secondary" id="btn-404" onClick={() => this.props.history.push('/')}>Go to homepage</button>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;