import React, { Fragment } from "react";
import * as Sentry from '@sentry/browser';
import { CartSummary } from "./cart-summary";
import { Option } from "@nozzlegear/railway";
import AddressForm from './address-form';
import {States} from './states';
import {Col, Row} from 'react-bootstrap';
import {
    validateZip,
    validateCityAndNames,
    validateStreetAddress,
    validateCheckoutForm
} from '../../actions';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';
import images from '../../img/images';
import keys from '../../config';
const prod = keys.prod.hostNames.includes(window.location.hostname);

// Turn this module into a barrel by exporting all of the packages types
export * from "./cart-summary";
export * from "./types";

class _BillingForm extends React.Component {
    constructor(props) {
        super(props);

        let defaultAddress = {
            city: "",
            line1: "",
            line2: "",
            name: "",
            state: States[0].iso,
            zipcode: "",
            deliveryInstructions: ""
        };

        this.state = {
            email: "",
            password: "",
            phone: "",
            billingAddress: defaultAddress,
            isAddressNameValid: true,
            isLine1Valid: true,
            isLine2Valid: true,
            isCityValid: true,
            isZipValid: true,
            isStateValid: true,
            isFullNameValid: true,
            errorMessage: '',
            loading: false,
            rates: [],
            sameBillingAddress: true,
            addressVerified: false
        };

        this.updateStateFromEvent = this.updateStateFromEvent.bind(this);
        this.continue = this.continue.bind(this);
        this.validateAddress = this.validateAddress.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        if(prod) window.fbq('track', 'InitiateCheckout');

        const zipcodes = JSON.parse(localStorage.getItem('zipcodes'));

        const {dropoffZipcodes} = this.props;
        this.setState({dropoffZipcodes, zipcodes})
        this.handleResize();

        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const {shipping} = customer;
        const shippingAddress = {
            name: shipping.name,
            line1: shipping.address,
            line2: "",
            city: shipping.city,
            zipcode: shipping.zipcode,
            state: shipping.state
        }

        this.setState({shippingAddress, customer})

        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }
    componentDidUpdate() {
        const {dropoffZipcodes} = this.props;
        if(this.props.error && !this.state.errorMessage) {
            this.setState({errorMessage: this.props.error})
        }
        if(this.props.order && this.state.source) {
            sessionStorage.setItem('stripeOrder', JSON.stringify(this.props.order));
            this.props.history.push('/checkout/confirm');
        }
        if(dropoffZipcodes && !this.state.dropoffZipcodes) {
            this.setState({dropoffZipcodes});
        }
    }

    updateStateFromEvent(name, value) {
        try {
            this.setState({ [name]: value, addressVerified: false });
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    /**
     * Validates an address, returning Option.Some with an error message when validation fails.
     */
    validateField(name, value) {
        const {dropoffZipcodes, billingAddress, zipcodes} = this.state;
        const goldenTicket = JSON.parse(sessionStorage.getItem('goldenTicket'));
        let rc = false;
        if(goldenTicket && typeof goldenTicket === 'string' && goldenTicket.toLowerCase() === "sd4me") {
            rc = true;
        }

        const zips = dropoffZipcodes ? dropoffZipcodes : zipcodes;

        try {
            switch(name) {
                case 'name':
                    this.setState({ isAddressNameValid: validateCityAndNames(value) });
                    break;
                case 'line1':
                    this.setState({ isLine1Valid: validateStreetAddress(value) });
                    break;
                case 'city':
                    this.setState({ isCityValid: validateCityAndNames(value) });
                    break;
                case 'state':
                    billingAddress.state = value;
                    this.setState({billingAddress});
                    break;
                case 'zipcode':
                    this.setState({ isZipValid: validateZip(value), errorMessage: "" })
                    break;
                default:
                    break;
            }
            this.setState({invalidFields: false})
        }
        catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    validateAddress() {
        const {billingAddress} = this.state;
        const addressValues = [];
        Object.entries(billingAddress).map(entry => {
            if(entry[0] !== "line2" && entry[0] !== "deliveryInstructions")
                addressValues.push(entry[1])
        })
        const fields = [
            this.state.isAddressNameValid,
            this.state.isLine1Valid,
            this.state.isLine2Valid,
            this.state.isCityValid,
            this.state.isStateValid,
            this.state.isZipValid,
            ...addressValues
        ];
        
        return validateCheckoutForm(fields);
    }

    renderDesktopCartSummary() {
        const {isMobile} = this.state;
        if(!isMobile) {
            return (
                <div className="desktop-cart-summary">
                    <CartSummary
                        coupon={this.props.coupon}
                        totals={this.props.totals}
                        lineItems={this.props.items}
                    />
                </div>
            );   
        }
    }

    renderErrorMessage() {
        const { errorMessage } = this.state;
        if(errorMessage) {
            return(
                <div className="alert alert-danger">{errorMessage}</div>
            )
        }
    }

    async continue() {
        const {
            sameBillingAddress,
            loading,
            fullName,
            shippingAddress,
            billingAddress
        } = this.state;
        const invalid = !sameBillingAddress ? await this.validateAddress() : false;
        if(!invalid) {
            if(!fullName) {
                this.setState({errorMessage: 'Please provide card holder name', isFullNameValid: false})
            } else {

                const customer = JSON.parse(sessionStorage.getItem('customer'));
                if(!loading) {
                    this.setState({loading: true});
        
                    const address = sameBillingAddress ? shippingAddress : billingAddress;
                    const stripeRes = await this.props.stripe.createSource({
                        type: 'card',
                        owner: {
                            name: fullName,
                            email: customer.email,
                            phone: customer.phone,
                            address: {
                                line1: address.line1,
                                city: address.city,
                                state: address.state,
                                country: 'US'
                            },
                        }
                    })

                    const {source} = stripeRes
            
                    if(source){        
                        const {card, owner, id} = source;
                        sessionStorage.setItem('source', JSON.stringify({owner, card, id}));   
                        this.setState({source: true});
                        const order = JSON.parse(sessionStorage.getItem('order'));    
                        const {coupon} = this.props;
                        order.customer = customer;
                        order.customer.billing = billingAddress;
                        order.customer.billing.address = billingAddress.line1;
                        if(billingAddress.line2) order.customer.billing.address += billingAddress.line2;
                        delete order.customer.billing.line1;
                        delete order.customer.billing.line2;
                        order.source = source;
                        order.coupon = coupon ? coupon.id : undefined;
                        sessionStorage.setItem('customer', JSON.stringify(order.customer));
                        sessionStorage.setItem('order', JSON.stringify(order));
                        this.props.history.push('/checkout/confirm');
                    } else {
                        if(stripeRes && stripeRes.error && stripeRes.error.message)
                            this.setState({errorMessage: stripeRes.error.message, loading: false});
                        else
                            this.setState({errorMessage: "Please enter all required fields", loading: false});
                    }
                }
            }
        } else {
            this.setState({errorMessage: 'Please fill in all required fields with valid responses'})
        }
    }
    //#endregion

    renderForm() {
        const {
            fullName,
            error,
            billingAddress,
            isAddressNameValid,
            isFullNameValid,
            isLine1Valid,
            isCityValid,
            isZipValid,
            isStateValid,
            errorMessage,
            sameBillingAddress
        } = this.state;

        return (
            <Fragment>
                <form>
                    <div className="input-wrapper flex-row flex-wrap">
                        <div className="modal-input-container full">
                            <label className="modal-label">Full Name On Card</label>
                            <input
                                className={`modal-input ${isFullNameValid ? '' : ' invalid'}`}
                                type="text"
                                id="full-name"
                                value={fullName}
                                placeholder="Card Holder*"
                                onChange={(e) => this.updateStateFromEvent('fullName', e.target.value)}
                                onBlur={(e) => this.validateField('fullName', e.target.value)}
                            />
                        </div>
                        <div className="modal-input-container full">
                            <label className="modal-label">Credit Card</label>
                            <CardElement className="stripe-input" />
                        </div>
                        <AddressForm
                            show={!sameBillingAddress}
                            type="billing"
                            address={billingAddress}
                            isAddressNameValid= {isAddressNameValid}
                            isLine1Valid= {isLine1Valid}
                            isCityValid= {isCityValid}
                            isZipValid= {isZipValid}
                            isStateValid= {isStateValid}
                            onChange={a => this.setState({ billingAddress: a, errorMessage: '' })}
                            onBlur={(name, value) => this.validateField(name, value)}
                        />
                    </div>
                    <div className="checkbox-container" onClick={() => {this.setState({sameBillingAddress: !sameBillingAddress})}}>
                        <div className="checkbox">
                            <img src={images.checkmark} alt="checkbox" className={!sameBillingAddress ? "hide" : ""} />
                        </div>
                        <label className="terms-text">Use Shipping Address as Billing Address</label>
                    </div>
                </form>
                <div className="ms-row">
                    <div className="xs-col-24-24 text center">
                        {this.renderErrorMessage()}
                    </div>
                </div>
            </Fragment>
        );
    }

    render() {        
        return (
            <Fragment>
                <div className="checkout-main">
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">3</div>
                        <span className="checkout-stage-name">Billing</span>
                    </div>
                    {this.renderForm()}
                    <button className="dashboard-btn dashboard-save cta" onClick={this.continue}>Continue</button>
                </div>
                {this.renderDesktopCartSummary()}
            </Fragment>
        );
    }
}

export const BillingForm = injectStripe(_BillingForm);