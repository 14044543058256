import axios from 'axios';
import {
    GET_INTERVIEW_ROUNDER_SUCCESS,
    GET_INTERVIEW_ROUNDER_FAIL,
    GETTING_ROUNDER,
    POST_INTERVIEW_SUCCESS,
    POST_INTERVIEW_FAIL,
    POST_FEEDBACK_SUCCESS,
    POST_FEEDBACK_FAIL,
    PUT_INTERVIEW_SUCCESS,
    PUTTING_INTERVIEW,
    PUT_INTERVIEW_FAIL,
    CLEAR_INTERVIEWS,
    GET_PROJECTIONS_FAIL,
    GET_PROJECTIONS_SUCCESS,
    CLEAR_PROJECTIONS,
    CLEAR_ROUNDER
} from './types';
import { Auth } from 'aws-amplify';
import {handleApiError,getAuthHeaders} from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';
import keys from '../config';
const prod = keys.prod.hostNames.includes(window.location.hostname);
const url = prod ? keys.prod.apiUrl : keys.dev.apiUrl;

export const getRounder = (interview) => {
    return async (dispatch) => {
        dispatch({ type: GETTING_ROUNDER });
        const req = await getSignature('POST', interview, '/interviews/rounder', {new:1});
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: GET_INTERVIEW_ROUNDER_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, GET_INTERVIEW_ROUNDER_FAIL))
            })
            .catch(err => {
                dispatch(handleApiError(err, GET_INTERVIEW_ROUNDER_FAIL))
            })
        }
}

export const putInterview = (interview) => {
    return async (dispatch) => {
        dispatch({ type: PUTTING_INTERVIEW });
        getAuthHeaders().then(headers => {
            axios.put(`${url}/interviews`, interview, headers)
                .then(res => {
                if (res.status === 200)
                    dispatch({ type: PUT_INTERVIEW_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, PUT_INTERVIEW_FAIL))
            })
            .catch(err => {
                dispatch(handleApiError(err, PUT_INTERVIEW_FAIL))
            })
        })
    }
}

export const clearRounder = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ROUNDER })
    }
}

export const clearInterviews = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_INTERVIEWS })
    }
}

export const getProjections = (interview, products) => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_PROJECTIONS, });
        const req = await getSignature('POST', {interview, products}, '/interviews/projection');
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({type: GET_PROJECTIONS_SUCCESS, payload: res.data ? res.data.projections : res.data });
                else
                    dispatch(handleApiError(res.error, GET_PROJECTIONS_FAIL));
            })
            .catch(err => {
                dispatch(handleApiError(err, GET_PROJECTIONS_FAIL));
            })
        }
}

export const postFeedback = (question, liked, feedback) => {
    return async (dispatch) => {
        const req = await getSignature('POST', {question, liked, feedback}, '/feedback');
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: POST_FEEDBACK_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, POST_FEEDBACK_FAIL))
            })
            .catch(err => {
                dispatch(handleApiError(err, POST_FEEDBACK_FAIL))
            })
        }
}

export const postInterview = (interview, unauthedId) => {
    return (dispatch) => {
        Auth.currentCredentials().then(creds => {
            getAuthHeaders({'X-CognitoUnauthedIdentityId': unauthedId, 'X-CognitoAuthedIdentityId': creds._identityId}).then(headers => {
                axios.post(`${url}/interviews`, interview, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: POST_INTERVIEW_SUCCESS });
                        else
                            dispatch(handleApiError(res.error, POST_INTERVIEW_FAIL))
                        })
                        .catch(err => {
                            dispatch(handleApiError(err, POST_INTERVIEW_FAIL))
                        })
                }).catch(seshErr => {
                    dispatch(handleApiError(seshErr, POST_INTERVIEW_FAIL))
            })
        })
    }
}