import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Row, Col } from 'react-bootstrap';
import images from '../../img/images';
import { formatType, getUnits, getRounder } from '../../actions'
import Counter from '../Interview/Counter';
import {connect} from 'react-redux';
import MoreInfo from '../Interview/MoreInfo';
import KitchenFlow from '../Interview/Kitchen/KitchenFlow';

class ClusterRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outdoorTrashFrequency: 1,
            laundryFrequency: 1
        };
        this.handleAdditionalClick = this.handleAdditionalClick.bind(this);
        this.setSelectedProduct = this.setSelectedProduct.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.closeKitchenFlow = this.closeKitchenFlow.bind(this);
    }

    componentDidMount() {
        const {
            cluster,
            clusterNumerator,
            selectedProducts,
            productTypes,
            productsMap,
            interview
        } = this.props;

        const capitalCluster = cluster === "everyday" ? "Everyday" : "Kitchen & Cleaning";

        this.setState({
            cluster,
            clusterNumerator,
            selectedProducts,
            productTypes,
            productsMap,
            capitalCluster,
            interview
        })
    }

    componentDidUpdate() {
        const { selectedProducts, clusterNumerator, newOptions, productsMap, productTypes } = this.props;
        try {
            if (Object.keys(selectedProducts).length !== Object.keys(this.state.selectedProducts).length) {
                this.setState({ selectedProducts, selectedProduct: undefined })
            }
        } catch (err) {
            Sentry.captureException(err);
            console.error(err)
        }
        if (this.state.clusterNumerator !== clusterNumerator) {
            this.setState({ clusterNumerator })
        }
        if(newOptions) {
            this.props.clearnewOptions();
            this.setState({productsMap, productTypes})
        }
    }

    handleAdditionalClick(groupName) {
        const { productsMap } = this.state;
        const element = document.getElementById(`${groupName}-svg`);
        if (element.matches(':hover')) {
            this.props.showDescription(productsMap[groupName])
        } else {
            this.setState({ selectedProduct: groupName })
            this.props.updateSelection(productsMap[groupName])
        }
    }

    updateInterview(kitchenQuestions) {
        const {gettingRounder} = this.props;
        const {outdoorTrashFrequency, interview, laundryFrequency, needsOutdoor} = this.state;
        if(!gettingRounder) {
            try {
                if(kitchenQuestions) {
                    const {dishwashing, housekeeping, cookingFrequency} = kitchenQuestions;
                    if(dishwashing) interview.dishwashing = dishwashing;
                    if(housekeeping) interview.housekeeping = housekeeping;
                    if(cookingFrequency) interview.cookingFrequency = cookingFrequency;
                } else if(needsOutdoor) {
                    const index = interview.trashPreferences.map(it => {return it.type}).indexOf('Outdoor');
                    if(index > -1) {
                        interview.trashPreferences.splice(index, 1);
                    }
                    interview.trashPreferences.push({
                        type: "Outdoor",
                        frequency: outdoorTrashFrequency,
                        bags: 1
                    });
                } else {
                    if(!interview.laundry) {
                        interview.laundry = {preferences: []}
                    }
                    interview.laundry.frequency = laundryFrequency;
                }
                if(window.location.hash !== "#/dashboard") this.props.getRounder(interview);
                this.props.updateInterview(interview);
                sessionStorage.setItem('interview', JSON.stringify(interview))
                this.setState({needsKitchen: false, needsOutdoor: false, needsLaundry: false, interview})
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    renderClusterCol() {
        const { cluster, capitalCluster, selectedProducts } = this.state;
        return (
            <Col xs={12} md={4} className={`cluster-col${!selectedProducts || !Object.keys(selectedProducts).length ? ' full' : ''}`}>
                <div className="add-products-cluster-img"><img src={images[`${cluster}_cluster`]} alt={cluster} /></div>
                <div className="cluster-name-container">
                    <div>{capitalCluster} Essentials</div>
                    {this.renderFulfillmentBar()}
                </div>
            </Col>
        )
    }

    renderFulfillmentBar() {
        const { clusterNumerator, selectedProducts } = this.state
        if (clusterNumerator !== undefined && selectedProducts) {
            const styles = {
                width: `${clusterNumerator / (clusterNumerator + Object.values(selectedProducts).length) * 100}%`
            }

            return (
                <div className={`cluster-progress-bar${!Object.values(selectedProducts).length ? ' full' : ''}`}>
                    <div className={`progress cluster-progress${!Object.values(selectedProducts).length ? ' rounded' : ''}`} style={styles}></div>
                    <div className="cluster-percentage">{(clusterNumerator / (clusterNumerator + Object.values(selectedProducts).length) * 100).toFixed(0)}%</div>
                </div>
            )
        }
    }

    setSelectedProduct(groupName) {
        const { selectedProduct, productsMap } = this.state;
        const { interview } = this.state;

        try {
            switch (productsMap[groupName].productType.toLowerCase()) {
                case 'dishwasherdetergent':
                case 'surfacecleaner':
                case 'dishsoap':
                    if (interview.housekeeping === undefined)
                        this.setState({ modal: "needsKitchen" })
                    break;
                case 'trashbagsoutdoor':
                    const index = interview.trashPreferences.map(it => { return it.type }).indexOf('Outdoor')
                    if (index === -1) {
                        this.setState({ modal: "needsOutdoor" })
                    }
                    break;
                case "laundrydetergent":
                    if(!interview.laundry)
                        this.setState({modal: "needsLaundry"})
            }
            if (selectedProduct === groupName)
                this.setState({ selectedProduct: undefined })
            else
                this.setState({ selectedProduct: groupName })
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderModalPrompt() {
        const {modal, showInfo} = this.state;
        if(modal && !showInfo) {
            let products;
            let questions = "one more quick question";
            switch (modal) {
                case "needsKitchen":
                    products = " for kitchen products";
                    questions = "a couple quick questions"
                    break;
                case "needsOutdoor":
                    products = " for Outdoor Trash Bags";
                    break;
                case "needsLaundry":
                    products = " for Laundry Detergent";
                    break;
            }
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                        <img onClick={() => { this.setState({ modal: false, selectedProduct: false }) }} className="text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body">
                            <div>
                                <h2 className="no-margin text-center">{window.location.hash === "#/prediction" ? "More Convenience!" : "Hello, again."}</h2>
                                <p className="interview-copy margin-small">
                                    We just have {questions} to determine our best recommendation{products}.
                                </p>
                            </div>
                            <div className="interview-modal-button-row">
                                <button className="dashboard-btn dashboard-submit white" onClick={() => this.setState({modal: false, selectedProduct: false})}>Cancel</button>
                                <button className="dashboard-btn dashboard-save" onClick={() => this.setState({modal: false, [modal]: true})}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderEditingRow() {
        const { selectedProduct, productsMap, productTypes, needsOutdoor, needsKitchen } = this.state;
        if (selectedProduct && !needsOutdoor && !needsKitchen) {
            try {
                const product = productsMap[selectedProduct];
                const { additional } = productTypes[product.productType];
                const additionalRows = [];

                Object.values(additional).map(a => {
                    const additionalProduct = productsMap[a.groupName];
                    const { imageUrl, brandName, groupName, pricePerArticle, displayName, articles, articleType } = additionalProduct;
                    if (groupName !== selectedProduct) {
                        additionalRows.push(
                            <div className="order-additional-product-container" onClick={() => this.handleAdditionalClick(groupName)}>
                                <div className="order-additional-product text-center">
                                    <svg id={`${groupName}-svg`} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8" />
                                        <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8" />
                                        <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8" />
                                    </svg>
                                    <img className="order-edit-img additional" src={imageUrl} alt={`${brandName} ${displayName}`} />
                                    <div className="text-center">{brandName}</div>
                                    <div className="text-center">{articles} {getUnits(articleType, articles, true)} ${(pricePerArticle / 100 * articles).toFixed(2)}</div>
                                    <div className="text-center">@ ${(pricePerArticle / 100 * articles).toFixed(2)} each</div>
                                </div>
                            </div>
                        )
                    }
                })

                const { articles, articleType, imageUrl, brandName, displayName, pricePerArticle, groupName, productType } = product;

                const renderAdditional = () => {
                    if (additionalRows.length) {
                        return (
                            <div className="products-option-container">
                                <div className="other-options-text">Other Options:</div>
                                {additionalRows}
                            </div>
                        )
                    }
                }

                return (
                    <Col xs={12} className="cluster-row edit">
                        <div className="order-product edit text-center">
                            <img className="order-edit-img text-center" src={imageUrl} alt={`${brandName} ${displayName}`} />
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.props.showDescription(productsMap[groupName])}>
                                <circle className="info-circle" cx="7.5" cy="7.5" r="7" stroke="#183041" stroke-opacity="0.8" />
                                <circle className="info-dot" cx="7.49902" cy="4.5" r="0.75" fill="#183041" fill-opacity="0.8" />
                                <rect width="1.5" height="5.25" rx="0.75" transform="matrix(-1 0 0 1 8.25098 6.00049)" fill="#183041" fill-opacity="0.8" />
                            </svg>
                            <div className="order-product-details text-center">
                                <div><b>{brandName}</b> {displayName}</div>
                                <div>{formatType(productType)}</div>
                                <Counter type="prediction" min={0} count={articles} increment={() => this.props.increment(productsMap[groupName])} units={getUnits(articleType, articles)} decrement={() => this.props.decrement(productsMap[groupName])} />
                                <div><span className="summary-product-price">${(articles * pricePerArticle / 100).toFixed(2)}</span> @ ${(pricePerArticle / 100).toFixed(2)} each</div>
                            </div>
                            <div className="add-products-btn-container stacked">
                                <button className="dashboard-btn dashboard-submit" onClick={() => this.props.addProduct(productsMap[groupName])}>Add Product</button>
                                <button className="dashboard-btn dashboard-cancel-link" onClick={() => this.setState({ selectedProduct: undefined })}>Cancel</button>
                            </div>
                        </div>
                        {renderAdditional()}
                    </Col>
                )


            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }

        }
    }

    renderProducts() {
        const { productTypes, selectedProducts, selectedProduct } = this.state;
        if(productTypes) {
            try {
                const products = [];
                for (let i = 0; i < Object.entries(productTypes).length; i++) {
                    const productType = Object.entries(productTypes)[i];
                    if (selectedProducts[productType[0]]) {
                        if (!Object.keys(productType[1].edited).length) {
                            const { groupName } = productType[1].preferred;
                            products.push(
                                <div className={`order-additional-product-container ${selectedProduct === groupName ? " open" : ""}`} onClick={() => this.setSelectedProduct(productType[1].preferred.groupName)}>
                                    <div className="order-additional-product text-center">
                                        <div>{formatType(productType[0])}</div>
                                        <img src={productType[1].preferred.imageUrl} alt={formatType(productType[0])} />
                                    </div>
                                </div>
                            )
                        } else {
                            const { groupName } = productType[1].edited;
                            products.push(
                                <div className={`order-additional-product-container ${selectedProduct === groupName ? " open" : ""}`} onClick={() => this.setSelectedProduct(productType[1].edited.groupName)}>
                                    <div className="order-additional-product text-center">
                                        <div>{formatType(productType[0])}</div>
                                        <img src={productType[1].edited.imageUrl} alt={formatType(productType[0])} />
                                    </div>
                                </div>
                            )
                        }
                    }
                }
                return (
                    <Col xs={12} md={8} className="additional-products">
                        {products}
                    </Col>
                )
    
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
                return null
            }
        }
    }
    
    closeKitchenFlow() {
        this.setState({selectedProduct: false, needsKitchen: false, unsavedChanges: false})
    }

    renderAdditionalQuestions() {
        const { needsKitchen, needsOutdoor, needsLaundry, laundryFrequency, outdoorTrashFrequency, showInfo, interview, unsavedChanges } = this.state;
        if(!showInfo) {
            if (needsKitchen) {
                return (
                    <div className="modal-container transparent">
                        <div className="interview-question-modal">
                            {unsavedChanges ? null : <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />}
                            <img onClick={unsavedChanges ? this.closeKitchenFlow : () => this.setState({unsavedChanges: true})} className={`${unsavedChanges ? "raised " : ""}text-right order-x-icon`} src={images.btn_close_x} alt="close" />
                            <div className={`interview-modal-body${unsavedChanges ? ' no-padding' : ''}`}>
                                <KitchenFlow 
                                    updateInterview={this.updateInterview}
                                    interview={interview}
                                    close={this.closeKitchenFlow} 
                                    unsavedChanges={unsavedChanges}
                                    setUnsavedChanges={() => this.setState({unsavedChanges: true})}
                                    clearUnsavedChanges={() => this.setState({unsavedChanges: false})}
                                />
                            </div>
                        </div>
                    </div>
                )
            } else if (needsOutdoor) {
                return (
                    <div className="modal-container transparent">
                        <div className="interview-question-modal">
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                            <img onClick={() => { this.setState({ needsOutdoor: false, selectedProduct: false }) }} className="text-right order-x-icon" src={images.btn_close_x} alt="close" />
                            <div className="interview-modal-body">
                                <div>
                                    <div className="product-icon text-center" id="outdoor-trash-img"><img src={images.trashBin} /></div>
                                    <p className="interview-copy">
                                        How many Outdoor Trash Bags do you use each month?
                                    </p>
                                    <Counter type="interview" min={0} count={outdoorTrashFrequency} increment={() => this.setState({outdoorTrashFrequency: outdoorTrashFrequency + 1})} decrement={outdoorTrashFrequency > 1 ? () => this.setState({outdoorTrashFrequency: outdoorTrashFrequency - 1}) : null} />
                                </div>
                                <div className="interview-modal-button-row">
                                    <button className="dashboard-btn dashboard-submit white" onClick={() => this.setState({needsOutdoor: false, selectedProduct: false})}>Don't Save</button>
                                    <button className="dashboard-btn dashboard-save" onClick={() => this.updateInterview()}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else if (needsLaundry) {
                return (
                    <div className="modal-container transparent">
                        <div className="interview-question-modal">
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                            <img onClick={() => { this.setState({ needsLaundry: false, selectedProduct: false }) }} className="text-right order-x-icon" src={images.btn_close_x} alt="close" />
                            <div className="interview-modal-body">
                                <div>
                                    <div className="product-icon text-center" id="outdoor-trash-img"><img src={images.laundryFrequency} /></div>
                                    <p className="interview-copy">
                                        How many loads of laundry do you do every week?
                                    </p>
                                    <Counter type="interview" min={0} count={laundryFrequency} increment={() => this.setState({laundryFrequency: laundryFrequency + 1})} decrement={laundryFrequency > 1 ? () => this.setState({laundryFrequency: laundryFrequency - 1}) : null} />
                                </div>
                                <div className="interview-modal-button-row">
                                    <button className="dashboard-btn dashboard-submit white" onClick={() => this.setState({needsLaundry: false, selectedProduct: false})}>Don't Save</button>
                                    <button className="dashboard-btn dashboard-save" onClick={() => this.updateInterview()}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    renderOutdoorInfo() {
        const {showInfo, needsOutdoor, needsLaundry, needsKitchen, modal} = this.state;
        if(showInfo) {
            let copy;
            if(needsOutdoor || modal === "needsOutdoor")
                copy = "The amount of outdoor trash bags you have helps us calculate how many you’ll need each month.";
            else if (needsKitchen || modal === "needsKitchen")
                copy = "By answering just a couple questions, we'll be able to calculate your kitchen & cleaning needs.";
            else if (needsLaundry || modal === "needsLaundry")
                copy = "The amount of laundry you do helps us calculate how much detergent you’ll need each month.";
            else
                copy = "By answering just a couple questions, we'll be able to calculate your supply needs.";
            return (
                <MoreInfo
                    close={() => this.setState({ showInfo: false })} copy={copy}
                />
            )
        }
    }

    render() {
        const { cluster, capitalCluster, selectedProducts } = this.state;
        return (
            <Row className="order-row">
                <Row className={`cluster-row${!selectedProducts || !Object.keys(selectedProducts).length ? ' full' : ''}`}>
                    {this.renderClusterCol()}
                    {this.renderProducts()}
                    {this.renderOutdoorInfo()}
                </Row>
                {this.renderModalPrompt()}
                {this.renderAdditionalQuestions()}
                {this.renderEditingRow()}
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    const { rounder, rounderError, gettingRounder } = state.interview;
    return {rounder, rounderError, gettingRounder};
}

export default connect(mapStateToProps, {
    getRounder,
})(ClusterRow);