import axios from 'axios';
import {
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    UPDATING_ORDER,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_MANIFEST_SUCCESS,
    GET_MANIFEST_FAIL,
    GETTING_MANIFEST,
    PUT_MANIFEST_FAIL,
    PUT_MANIFEST_SUCCESS,
    UPDATING_MANIFEST,
    CREATING_ORDER,
    CLEAR_ORDERS_STATE,
    GETTING_LANDING,
    GET_LANDING_SUCCESS,
    GET_LANDING_FAIL,
    CLEAR_ORDERS_ERRORS
} from './types';
import {handleApiError,getAuthHeaders} from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';
import keys from '../config';

const prod = keys.prod.hostNames.includes(window.location.hostname);
const url = prod ? keys.prod.apiUrl : keys.dev.apiUrl;

export const clearOrdersState = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ORDERS_STATE })
    }
}

export const getOrders = () => {
    return (dispatch) => {
        getAuthHeaders().then(headers => {
            axios.get(`${url}/orders/`, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_ORDERS_SUCCESS, payload: res.data.orders })
                    else
                        dispatch(handleApiError(res.error, GET_ORDERS_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_ORDERS_FAIL))
                })
        })
    }
}

export const putManifest = (products) => {
    const body = {products};
    return (dispatch) => {
        dispatch({ type: UPDATING_MANIFEST })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/manifest?new=1`, body, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({type: PUT_MANIFEST_SUCCESS, payload: res.data})
                    else {
                        dispatch(handleApiError(res.error, PUT_MANIFEST_FAIL))
                    }
                }).catch(err => {
                    dispatch(handleApiError(err, PUT_MANIFEST_FAIL)) 
                })
        })
    }
}

export const createOrder = (order) => {
    const body = {order};
    return (dispatch) => {
        dispatch({ type: CREATING_ORDER })
        getAuthHeaders().then(headers => {
            axios.post(`${url}/orders`, body, {headers: headers.headers, params: {new: 1}})
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: CREATE_ORDER_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, CREATE_ORDER_FAIL))
                })
                .catch(err => {
                    dispatch(handleApiError(err, CREATE_ORDER_FAIL))
                })
        })
    }
}

export const updateOrder = (source, orderId) => {
    const body = {source};
    return (dispatch) => {
        dispatch({ type: UPDATING_ORDER })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/orders/${orderId}`, body, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: res.data });
                    else
                       dispatch(handleApiError(res.error, UPDATE_ORDER_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, UPDATE_ORDER_FAIL));
                })
        })
    }
}

export const getManifest = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_MANIFEST })
        getAuthHeaders().then(headers => {
            axios.get(`${url}/manifest?new=1`, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_MANIFEST_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, GET_MANIFEST_FAIL))
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_MANIFEST_FAIL))
                })
        })
    }
}

export const getLanding = () => {
    return async (dispatch) => {
        const req = await getSignature('GET', undefined, '/landing');
        dispatch({ type: GETTING_LANDING })
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: GET_LANDING_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, GET_LANDING_FAIL));
            }).catch(err => {
                dispatch(handleApiError(err, GET_LANDING_FAIL));
            })
    }
}

export const clearOrderErrors = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ORDERS_ERRORS })
    }
}