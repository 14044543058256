export default {
    dev: {
        apiUrl: 'https://alpha.api.supplydrop.com',
        apiHostName: 'alpha.api.supplydrop.com',
        stripeKey: 'pk_test_tEOZwevkOWpShSr9o4XrwxHp009QMPqJhV',
        gaTrackingId: 'UA-148893093-1',
        pinpointAppId: '034ea5508589419a9f9d0ab363ffca06'
    },
    prod: {
        hostNames: [
            'friends.supplydrop.com',
            'supplydrop.com',
            'www.supplydrop.com'
        ],
        apiUrl: 'https://api.supplydrop.com',
        apiHostName: 'api.supplydrop.com',
        stripeKey: 'pk_live_d0O2s7qhLBmBzLPyUQ4Fd8pL000y476GR7',
        gaTrackingId: 'UA-148893093-2',
        pinpointAppId: 'bc9e7216c9a14f9b93df6953cca2760d'
    }
}
