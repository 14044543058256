import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import Header from './Header';
import images from '../img/images';
import {validateZip, validateEmail, getLanding, marginForCountdown} from '../actions';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';
import moment from 'moment';
import Countdown from './Countdown';

class AvailabilityGate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isZipValid: true,
            isEmailValid: true,
            zipcode: "",
            email: ""
        };

        this.handleZipChange = this.handleZipChange.bind(this);
        this.validateZipcode = this.validateZipcode.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.continue = this.continue.bind(this);
        this.handleRCChange = this.handleRCChange.bind(this);
        this.setDeliveryDate = this.setDeliveryDate.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
        clearInterval(this.setDeliveryDate)
    }

    handleKeydown(e) {
        if(e.keyCode && e.keyCode === 13) {
            this.continue();
        }
    }

    componentDidMount() {
        const {signupsOpen, zipcodes, dropoffZipcodes} = this.props;
        const isOpen = signupsOpen === undefined ? true : signupsOpen;
        this.setState({signupsOpen: isOpen, zipcodes, dropoffZipcodes});
        if(!dropoffZipcodes) {
            getLanding();
        }
    }

    componentDidUpdate() {
        const {landing, landingError} = this.props;
        const {dropoffZipcodes}  = this.state;
        if(landing && landing.dropoffZipcodes && !dropoffZipcodes) (
            this.setState({dropoffZipcodes: landing.dropoffZipcodes, signupsOpen: landing.signupsOpen})
        )
    }

    updateDeliveryDate() {
        const {cutoff} = this.state;
        const hours = moment().isDST() ? "4" : "5";
        if(moment.utc().startOf('day').add('1', 'day').add(hours, "hours") > cutoff) {

        }
    }

    setDeliveryDate() {
        const hours = moment().isDST() ? "4" : "5";
        const dayOfWeek = moment.utc().subtract(hours, 'hours').format('dddd');
        const days = dayOfWeek === "Thursday" || dayOfWeek === "Friday"? 4 : dayOfWeek === "Saturday" ? 3 : 2;
        const cutoff = moment.utc().subtract(hours, 'hours').startOf('day').add('1', 'days').add(hours, "hours");
        const deliveryDate = moment.utc().subtract(hours, 'hours').startOf('day').add(days, 'days');
        this.setState({deliveryDate, cutoff})
    }

    handleRCChange(value) {
        try {
            const goldenTicket = value.toLowerCase();
            this.setState({goldenTicket, invalidReferral: false})
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    handleZipChange(value) {
        if(!isNaN((parseInt(value)) && value.length < 6) || value === "") {
            this.setState({zipcode: value, isZipValid: true})
        }
    }
    
    handleEmailChange(value) {
        this.setState({email: value, isEmailValid: true})
    }

    validateZipcode() {
        const {zipcode} = this.state;
        this.setState({isZipValid: validateZip(zipcode)});
    }
    
    validateEmail() {
        const {email} = this.state;
        this.setState({isEmailValid: validateEmail(email)});
    }

    renderDeliveryDate() {
        const {deliveryDate} = this.state;
        if(deliveryDate) {
            return (
                <p>
                    <b>Estimated Delivery: {moment(deliveryDate).format('MMMM Do')}</b><br/><br/>
                    Complete your signup before 11PM CT tonight to get your Supply Drop on {moment(deliveryDate).format('MMMM Do')}
                </p>
            )
        }
    }

    renderTopRow() {
        return (
            <div className="interview-nav-container">
				<div className="interview-top-row">
					<img src={images.back_arrow} onClick={this.props.close} className="interview-icon interview-back-arrow" />
					<div></div>
				</div>
            </div>
        )
    }

    renderCountdown() {
        const {zipcode, approved} = this.state;
        if(approved && zipcode) return <Countdown />
    }

    continue() {
        const {zipcode, approved, denied, isZipValid, referral, goldenTicket, zipcodes, signupsOpen, dropoffZipcodes} = this.state;
        const zips = dropoffZipcodes? dropoffZipcodes : zipcodes;
        if(zipcode && isZipValid && !approved && !denied) {
            try {
                const included = zips.includes(zipcode);
                if(included && signupsOpen) {
                    this.setState({approved: true})
                    marginForCountdown();
                    sessionStorage.setItem('zipcode', JSON.stringify(zipcode));
                    sessionStorage.setItem('zipcodeApproved', JSON.stringify(zipcode));
                    this.setDeliveryDate();
                    this.interval = setInterval(this.setDeliveryDate.bind(this), 60000);                    
                } else {
                    this.setState({denied: true})
                }
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
                this.setState({denied: true})
            }
        } else if(approved) {
            this.props.history.push('/start')
        } else if(referral && goldenTicket === "sd4me") {
            sessionStorage.setItem('goldenTicket', JSON.stringify(goldenTicket));
            this.setState({approved: true})
        } else if(referral && goldenTicket !== "sd4me") {
            this.setState({invalidReferral: true})
        }
    }

    renderSubheader() {
        const {denied, approved, zipcode, goldenTicket, isZipValid, confirmed, referral, invalidReferral} = this.state;
        if(approved && goldenTicket === "sd4me") {
            return (
                <div>
                    <h2 className="gate-header">Welcome friend!</h2>
                    <p className="interview-copy small">We’ll confirm your delivery date at the completion of your order.
                    <br/><br/>You can expect to recieve your delivery within 5-7 business days.</p>
                </div>
            )
        } else if(approved) {
            return (
                <div>
                    <h2 className="gate-header">Good news</h2>
                    <p className="interview-copy small">
                        Supply Drop is available in your area!<br/><br/>
                        {this.renderDeliveryDate()}
                    </p>
                </div>
            )
        } else if(denied && !confirmed) {
            return (
                <div>
                    <h2 className="gate-header">We're sorry</h2>
                    <p className="interview-copy small">Supply Drop isn't currently available in your area.<br/>Enter your email below and we'll let you know when we are.</p>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf0hky9BDZs3pjEJ-uECvUOJbfpFXAhM2t6qfFMHUwVIZx1Mg/viewform?embedded=true" width="640" height="600px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            )
        } else if(denied && confirmed) {
            return (
                <h2 className="gate-header">Thanks, we'll be in touch!</h2>
            )
        } else if(referral) {
            return (
                <div>
                    <h2 className="gate-header">Enter referral code</h2>
                    <p className="interview-copy small"></p>
                    <input
                        type="text"
                        id="gate-zip-input"
                        className={`signin-modal-input${invalidReferral ? " invalid" : ""}`}
                        placeholder="Enter your referral code"
                        onChange={(e) => this.handleRCChange(e.target.value)}
                        value={goldenTicket}
                    />
                    <div className="input-sublink-container">
                        <a onClick={() => this.setState({referral: false})} className="input-sublink">Try zipcode instead?</a>
                    </div>
                    <p className="interview-copy small"><Alert variant="danger" className={invalidReferral ? "" : "hide"}>Sorry, the code you entered is not valid</Alert></p>
                    {this.renderValidationMessage()}
                </div>
            )
        } else {
            return (
                <div>
                    <h2 className="gate-header">Check availability</h2>
                    <p className="interview-copy small">See if Supply Drop is available in your area!</p>
                    <input
                        type="number"
                        id="gate-zip-input"
                        className={`signin-modal-input${isZipValid ? "" : " invalid"}`}
                        placeholder="Enter your zip code"
                        onChange={(e) => this.handleZipChange(e.target.value)}
                        onBlur={this.validateZipcode}
                        value={zipcode}
                        max="99999"
                    /> 
                    <div className="input-sublink-container">
                        <a onClick={() => this.setState({referral: true, zipcode: "", isZipValid: true})} className="input-sublink">Have a referral code?</a>
                    </div>
                    {this.renderValidationMessage()}
                </div>
            )
        }
    }

    renderValidationMessage() {
        const {isZipValid, isEmailValid} = this.state;
        if(!isZipValid || !isEmailValid) {
            return (
                <Alert variant="danger" className="alert-fixed-bottom">
                    Please enter a valid {isZipValid ? "email address" : "zipcode"}.
                </Alert>
            )
        }
    }

    renderImg() {
        const {denied} = this.state;
        return <div className="interview-header-img"><img src={images[denied ? "availability_denied" : "availability"]} alt="Availability" /></div>

    }

    renderContinue() {
        const {confirmed, denied} = this.state;
        if(!denied) {
            return (
                <div className="interview-continue-wrapper">
                    <button className="interview-continue-button" onClick={this.continue}>{confirmed ? "Back to Homepage" : "Next"}</button>
                    <p className="interview-enter-text">press <b>enter</b></p>
                </div>
            )
        }
    }

    render() {
        const {zipcode, approved} = this.state;
        return (
            <div>
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTop={zipcode && approved} />
                <div className="interview-outer">
                    {this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        {this.renderImg()}
                        {this.renderSubheader()}
                        {this.renderContinue()}
                    </div>
                </div>
            </div>			
        )
    }
}

const mapStateToProps = (state) => {
    const {landing, landingError} = state.order;
    return {landing, landingError};
}

export default connect(mapStateToProps, {
    getLanding
})(AvailabilityGate);