import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import images from '../../img/images';
import {formatType} from '../../actions'
import ReactTooltip from 'react-tooltip';

class ProductDetail extends Component {
    constructor(props) {
        super(props);

        this.handleKeydown = this.handleKeydown.bind(this);
    }
    
    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        if(e.keyCode && e.keyCode === 13) {
            this.props.close();
        }
    }

    render() {
        const {product, close} = this.props;
        const {
            imageUrl,
            name,
            brand,
            brandName,
            displayName,
            price,
            pricePerArticle,
            description, 
            badges,
            groupName
        } = product;
    
        const badgeImages = [];
    
        if(badges && badges.length) {
            badges.map(badge => {
                if(images[badge]) {
                    let formattedBadge = "";
                        switch(badge) {
                            case "SupplyDropPick":
                                formattedBadge = "Premium Product" 
                                break;
                            case "EcoFriendly":
                                formattedBadge = "Eco-Friendly"
                                break;
                            case "NationallyRecognizedBrandLead":
                                formattedBadge = "National Brand";
                                break;
                            case "SocialGood":
                                formattedBadge = "Socially Conscious";
                                break;
                            default:
                                formattedBadge = badge.replace(/([A-Z])/g, ' $1').trim()
                                break;
                        }
                        badgeImages.push(
                            <div>
                                <img data-tip data-for={`${groupName}-${badge}`} className="prediction-badge" src={images[badge]} alt={badge} />
                                <ReactTooltip id={`${groupName}-${badge}`}>
                                    {formattedBadge}
                                </ReactTooltip>
                            </div>
                        )
                }
            })
        }
        
        return (
            <div className="modal-container light">
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row">
                            <div></div>
                            <img src={images.x_icon} onClick={close} className="interview-icon interview-info-icon" />
                        </div>
                    </div>
                    <div className="modal-inner">
                        <Row className="flex-row flex-center">
                            <Row>
                                <Col xs={12}><div className="product-detail-header">Product Detail</div></Col>
                                <Col xs={4} className="product-detail-thumbnail">
                                    <img src={imageUrl} alt={brand ? brand : brandName}/>
                                </Col>
                                <Col xs={8} className="flex-col-center flex-center product-detail-name">
                                    <Row className="prediction-detail-name no-margin">
                                        <div><b>{brand ? brand : brandName}</b> {name ? name : displayName}<br/>
                                        <span>${price ? price.toFixed(2) : (pricePerArticle/100).toFixed(2)}/each</span></div>
                                    </Row>
                                </Col>
                                <Col xs={12}><div className="interview-copy product-detail-copy">{description}</div></Col>
                                <Col xs={12}><div className="product-detail-badges-header">THIS PRODUCT IS</div></Col>
                                <Col xs={12} className="text-left"><div className="badge-row detail">{badgeImages}</div></Col>
                            </Row>
                        </Row>
                        <div className="interview-continue-wrapper">
                            <button className="interview-continue-button" onClick={close}>Go Back</button>
                            <p className="interview-enter-text">press <b>enter</b></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductDetail;