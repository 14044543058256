import {
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    GETTING_USER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    UPDATING_CUSTOMER,
    CLEAR_CUSTOMER_STATE,
    CLEAR_CUSTOMER,
    UPDATING_SOURCE,
    UPDATE_SOURCE_SUCCESS,
    UPDATE_SOURCE_FAIL,
    NEW_MAGIC_LINK_FAIL,
    NEW_MAGIC_LINK_SUCCESS,
    GETTING_MAGIC_LINK,
    USER_DOESNT_EXIST,
    POST_PROSPECT_SUCCESS,
    POST_PROSPECT_FAIL,
    POSTING_PROSPECT
} from '../actions/types';

const INITIAL_STATE = {
    getUserError: '',
    user: undefined,
    updatingCustomer: undefined,
    putCustomerSuccess: undefined,
    putCustomerError: undefined,
    updatingSource: undefined,
    putSourceSuccess: undefined,
    putSourceError: undefined,
    magicLink: undefined,
    magicLinkErr: undefined,
    gettingMagicLink: false,
    noUser: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return { ...state, user: action.payload, getUserError: '' };
        case GET_USER_FAIL:
            return { ...state, getUserError: action.payload };
        case GETTING_USER:
            return { ...state, getUserError: undefined, user: undefined };
        case UPDATING_CUSTOMER: 
            return { ...state, updatingCustomer: true, putCustomerSuccess: undefined, putCustomerError: undefined };
        case UPDATE_CUSTOMER_SUCCESS: 
            return { ...state, updatingCustomer: undefined, putCustomerSuccess: action.payload, putCustomerError: undefined };
        case UPDATE_CUSTOMER_FAIL: 
            return { ...state, updatingCustomer: undefined, putCustomerSuccess: undefined, putCustomerError: action.payload };
        case UPDATING_SOURCE: 
            return { ...state, updatingSource: true, putSourceSuccess: undefined, putSourceError: undefined };
        case UPDATE_SOURCE_SUCCESS: 
            return { ...state, updatingSource: undefined, putSourceSuccess: action.payload, putSourceError: undefined };
        case UPDATE_SOURCE_FAIL: 
            return { ...state, updatingSource: undefined, putSourceSuccess: undefined, putSourceError: action.payload };
        case NEW_MAGIC_LINK_FAIL: 
            return { ...state, magicLinkErr: action.payload, magicLink: undefined, gettingMagicLink: false};
        case NEW_MAGIC_LINK_SUCCESS: 
            return { ...state, magicLinkErr: undefined, magicLink: action.payload, gettingMagicLink: false};
        case GETTING_MAGIC_LINK:
            return { ...state, magicLinkErr: undefined, magicLink: undefined, gettingMagicLink: true, noUser: false};
        case USER_DOESNT_EXIST: 
            return { ...state, noUser: true, gettingMagicLink: false}
        case POST_PROSPECT_SUCCESS:
            return { ...state, postingProspect: false, postProspectSuccess: action.payload, postProspectError: undefined }
        case POST_PROSPECT_FAIL:
            return { ...state, postingProspect: false, postProspectSuccess: undefined, postProspectError: action.error }
        case POSTING_PROSPECT:
            return { ...state, postingProspect: true, postProspectSuccess: undefined, postProspectError: undefined }
        case CLEAR_CUSTOMER_STATE:
            return { 
                ...state,
                updatingCustomer: undefined,
                putCustomerFail: undefined,
                putCustomerSuccess: undefined,
                updatingSource: undefined,
                updateSourceSuccess: undefined,
                updateSourceError: undefined,
                magicLink: undefined,
                magicLinkErr: undefined,
                gettingMagicLink: false,
                noUser: false
            }
        case CLEAR_CUSTOMER:
            return { 
                ...state,
                updatingCustomer: undefined,
                putCustomerFail: undefined,
                putCustomerSuccess: undefined,
                updatingSource: undefined,
                updateSourceSuccess: undefined,
                updateSourceError: undefined,
                user: undefined,
                getUserError: undefined,
                magicLink: undefined,
                magicLinkErr: undefined,
                gettingMagicLink: false,
                noUser: false
            }
        default:
            return state;
    }
};