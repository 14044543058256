import React from 'react';
import images from '../../img/images';

const Loading = ({message}) => 
{
    return (
        <svg class="loading-icon" xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 200 200">
            <clipPath id="loading-icon-clip-path">
                <circle r="95"></circle>
            </clipPath>
            <circle class="loading-icon__background" r="95" fill="#f3f3f7"></circle>
            <circle class="loading-icon__animated-stroke" clip-path="url(#loading-icon-clip-path)" r="100" fill="none" stroke-width="20px" stroke="#aaaaaa"></circle>
            <circle class="loading-icon__static-stroke-section" clip-path="url(#loading-icon-clip-path)" r="100" fill="none" stroke-width="20px" stroke="#aaaaaa"></circle>
            <image href="http://iglustudios.com/supplydrop/icon-supplydrop-orange.svg" x="-50" y="-50" width="100" height="100" opacity="1" class="grow"></image>
        </svg>
    )
}

export default Loading;