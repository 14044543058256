import * as React from "react";
import {formatType, getUnits, round} from '../../actions';
import {Row, Col} from 'react-bootstrap';

export function CartSummary({ totals, ...props }) {
    // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
    const coupon = null;
    //const toFixed = (value: string | number) => (typeof value === "string" ? value : value.toFixed(2));
    const items = props.lineItems.map((item, index) => 
    (
        <Row className="cart-item" key={`line-item-${index}`}>
            <Col xs={8}>
                <div className="summary-product-description"><strong>{item.brandName}</strong> {formatType(item.productType)}</div>
                <div className="summary-product-breakdown">({`${item.articles} ${getUnits(item.articleType, item.articles).toLowerCase()} at $${(item.pricePerArticle/100).toFixed(2)} each`})</div>

            </Col>
            <Col xs={4} className="text-right summary-product-price">${round(item.pricePerArticle * item.articles/100).toFixed(2)}</Col>
        </Row>
    ));

    const details = (
        <div className="cart-summary">
            <div className="summary-details-header">Order Summary</div>
            <div className="cart-items-container">
                {items}
            </div>
            <hr />
            <Row className="summary-subtotal-row">
                <Col xs={7} className="text-right bold">Subtotal:</Col>
                <Col className="text-right" xs={5}>{`$${round(totals.subTotal).toFixed(2)}`}</Col>
            </Row>
            {
                coupon ?
                <div>
                    <Row className="summary-subtotal-row green">
                        <Col xs={7} className="text-right bold">Discounts ({coupon.percentDiscount}% off)*:</Col>
                        <Col className="text-right" xs={5}>-${round(coupon.percentDiscount * totals.subTotal/100).toFixed(2)}</Col>
                        <Col xs={7} className="text-right text-small">* Discount limited to one per household.</Col>
                    </Row> 
                </div>
                :
                ""
            }
            <Row className="summary-subtotal-row">
                <Col xs={7} className="text-right bold">Shipping:</Col>
                <Col xs={5} className="text-right">FREE</Col>
            </Row>
            <Row className="summary-subtotal-row">
                <Col xs={7} className={totals.taxTotal || totals.taxTotal === 0 ? "text-right bold" : " hide"}>Estimated Taxes:</Col>
                <Col xs={5} className={totals.taxTotal || totals.taxTotal === 0 ? "text-right" : " hide"}>{totals.taxTotal || totals.taxTotal === 0 ? `$${round(totals.taxTotal).toFixed(2)}`: ""}</Col>
            </Row>
            <hr />
            <Row className="summary-total-row">
                <Col className="checkout-total-text" xs={7}>{totals.taxTotal || totals.taxTotal === 0 ? "Total:" : "Pre-Tax Total"}</Col>
                <Col className="checkout-total-price text-right" xs={5}>
                    ${round(totals.ultimateTotal).toFixed(2)}
                </Col>
            </Row>
            <hr />
        </div>
    )

    return details;
}
