import React, { Component } from 'react';
import images from '../img/images';
import { Auth } from 'aws-amplify';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import '../Style/signin.css';
import Header from './Header';
import { getMagicLink, clearCustomerState } from '../actions';
import MoreInfo from './Interview/MoreInfo';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channel: 'sms',
            moreInfoCopy: (
                <div>
                    Passwords are so 2000. You don't have to mess with them anymore.<br />
                    {/* TODO: Insert explanation of how the magic links works */}
                </div>
            )
        };
    }

    componentDidMount() {
        let params = this.props.location.search;

        
        if (params) {
            params = JSON.parse(('{"' + decodeURI(params.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'));
            const { u, s } = params;
            let channel = "sms";
            if(s && s === "e") channel = "email"

            if (u) {
                this.props.getMagicLink(undefined, '/#/dashboard', channel, u)
                this.setState({ channel })
            } else {
                this.props.history.push('/login');
            }
        } else {
            this.props.history.push('/login');
        }
    }

    componentDidUpdate() {
        const { magicLinkErr, magicLink } = this.props;
        if (magicLinkErr && !this.state.magicLinkErr && !magicLink) {
            this.props.history.push('/login');
        }
        if (magicLink && !this.state.magicLink) {
            this.setState({ magicLink, magicLinkErr: false })
        }
    }

    renderInfo() {
        const { showInfo, moreInfoCopy } = this.state;
        if (showInfo) {
            return (
                <MoreInfo close={() => this.setState({ showInfo: false })} copy={moreInfoCopy} />
            )
        }
    }

    renderSentSuccessfully() {
        const { channel } = this.state;
        if (channel) {
            return (
                <div className="signin-subtext">
                    Your previous link expired.<br/>For security, we've sent a new link to your {channel === "sms" ? "phone" : "email"} that will allow you to login.
                    <br/><br/><div><span className="span-link" onClick={() => this.props.history.push('/login')}>Click here</span> if you need to request a new link, or if you don't receive {channel === "sms" ? "a message." : "an email"}</div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header history={this.props.history} />
                {this.renderInfo()}
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row signin">
                            <div></div>
                            <img src={images.info} onClick={() => this.setState({ showInfo: true })} className="interview-icon interview-info-icon" />
                        </div>
                    </div>
                    <div className="interview-inner text-center">
                        <div className="interview-header-img"><img src={images.login} alt="Security" /></div>
                        <div className="signin-main-header">New magic link</div>
                        {this.renderSentSuccessfully()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { magicLink, magicLinkErr, noUser, gettingMagicLink } = state.user;
    return { magicLink, magicLinkErr, noUser, gettingMagicLink };
}

export default connect(mapStateToProps, {
    getMagicLink,
    clearCustomerState
})(SignIn);