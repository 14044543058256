import React from 'react';
import Feedback from '../Feedback';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';
import images from '../../../img/images';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Priority extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tierPreferences: [],
            tiers: [
                "EcoFriendly",
                "NationallyRecognizedBrandLead",
                "SupplyDropPick",
                "Value",
                "SensitiveSkin",
                "SocialGood",
                "AllNatural"
            ],
            moreInfoCopy: (
                <div>
                    We’ll select the best products and brands to suit your needs based on your unique preferences.
                </div>
            )
        }

        this.toggleTier = this.toggleTier.bind(this);
        this.setPreferences = this.setPreferences.bind(this);
        this.continue = this.continue.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.continue();
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

		const bathrooms = JSON.parse(sessionStorage.getItem('bathrooms'));
        const family = JSON.parse(sessionStorage.getItem('family'));
        const trashFrequency = JSON.parse(sessionStorage.getItem('trashFrequency'));
        const tierPreferences = JSON.parse(sessionStorage.getItem('tierPreferences'));
		if(!family) this.props.history.push('/start/1');
		else if(!bathrooms) this.props.history.push('/start/3');
        else if(!trashFrequency) this.props.history.push('/start/4');
        else if(tierPreferences) {
            for (let i = 0; i < tierPreferences.length; i++) {
                this.setState({[tierPreferences[i]]: i + 1})
            }
            this.setState({tierPreferences});
        }

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
	}

    toggleTier(tier) {
        const {tierPreferences, tiers} = this.state;
        if(this.state[tier]) {
            // remove tier
            const newPreferences = tierPreferences.filter(t => {return t !== tier});
            tiers.map(t => {
                const value = this.state[t];
                if(value && value > this.state[tier]) {
                    this.setState({[t]: value - 1})
                }
            })
            this.setState({tierPreferences: newPreferences, [tier]: false});
        } else {
            // add tier
            const id = tierPreferences.length > 0 ? tierPreferences[tierPreferences.length - 1].id + 1 : 0;
            tierPreferences.push(tier);
            this.setState({tierPreferences, [tier]: tierPreferences.length})
        }
    }

    continue() {
        const {tierPreferences} = this.state;
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        if(tierPreferences.length) {
            sessionStorage.setItem('tierPreferences', JSON.stringify(tierPreferences));
            this.props.history.push(hasKitchen ? '/kitchen/1' : '/prediction')
        }
    }

    renderContinue() {
        const {
            tierPreferences, 
        } = this.state;
        return (
			<div className="interview-continue-wrapper">
				<button disabled={!tierPreferences.length} className="interview-continue-button" onClick={this.continue}>Continue</button>
				<p className={`interview-enter-text ${!tierPreferences.length ? 'disabled' : ''}`}>press <b>enter</b></p>
			</div>
		)
        return (
            <button id="tiers-continue-btn" className="interview-continue-button" onClick={this.setPreferences}>Continue</button>
        )
    }

    renderTopRow() {
		return (
            <div className="interview-nav-container">
                <div className="interview-top-row">
                    <img src={images.back_arrow} onClick={() => this.props.history.push('/start/4')} className="interview-icon interview-back-arrow" />
                    <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                </div>
			</div>
		)
	}

    setPreferences() {
        const {tierPreferences, tiers} = this.state;

        const preferences = [];
        tierPreferences.map(p => {
            preferences.push(p.tier);
        })

        this.props.next({tierPreferences: preferences});
    }

    renderTiers() {
        const {
            EcoFriendly,
            NationallyRecognizedBrandLead,
            SupplyDropPick,
            Value,
            SensitiveSkin,
            SocialGood,
            AllNatural
        } = this.state;

        return (
            <div className="tiers-container">
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.NationallyRecognizedBrandLead} className="tier-emoji" /></div>
                    <div id="brand-btn" className={`interview-pill brand ${ NationallyRecognizedBrandLead ? 'selected' : ''}`} onClick={() => this.toggleTier('NationallyRecognizedBrandLead')}>Brands I Know</div>
                    <div className="tier-rank">{NationallyRecognizedBrandLead ? NationallyRecognizedBrandLead : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SupplyDropPick} /></div>
                    <div id="premium-btn" className={`interview-pill brand ${ SupplyDropPick ? 'selected' : ''}`} onClick={() => this.toggleTier('SupplyDropPick')}>Premium Products</div>
                    <div className="tier-rank">{SupplyDropPick ? SupplyDropPick : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.EcoFriendly} /></div>
                    <div id="eco-btn" className={`interview-pill brand ${ EcoFriendly ? 'selected' : ''}`} onClick={() => this.toggleTier('EcoFriendly')}>Eco-Friendly</div>
                    <div className="tier-rank">{EcoFriendly ? EcoFriendly : ''}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SensitiveSkin} /></div>
                    <div id="sensitive-btn" className={`interview-pill brand ${ SensitiveSkin ? 'selected' : ''}`} onClick={() => this.toggleTier('SensitiveSkin')}>Sensitive Skin</div>
                    <div className="tier-rank">{ SensitiveSkin ? SensitiveSkin : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.Value} /></div>
                    <div id="value-btn" className={`interview-pill brand ${ Value ? 'selected' : ''}`} onClick={() => this.toggleTier('Value')}>Value</div>
                    <div className="tier-rank">{ Value ? Value : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.SocialGood} /></div>
                    <div id="social-btn" className={`interview-pill brand ${ SocialGood ? 'selected' : ''}`} onClick={() => this.toggleTier('SocialGood')}>Socially Conscious</div>
                    <div className="tier-rank">{SocialGood ? SocialGood : ""}</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div className="tier-emoji"><img src={images.AllNatural} /></div>
                    <div id="natural-btn" className={`interview-pill brand ${ AllNatural ? 'selected' : ''}`} onClick={() => this.toggleTier('AllNatural')}>All Natural</div>
                    <div className="tier-rank">{AllNatural ? AllNatural : ''}</div>
                </div>
            </div>
        )
    }
    
    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown spaceTop={true} />
    }

    render() {
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        const {zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={hasKitchen ? 70 : 80} />
                {this.renderCountdown()}
				<Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
				<div className="interview-outer">
					{this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast">
                        <p className="interview-copy header">
                            When buying home supplies which of these is most important to you? 
                        <br/>Select as many as you want in order of preference.
                        </p>
                        {this.renderTiers()}
                        {this.renderContinue()}
                    </div>
                    <Feedback question={'tierPreferences'} />
                </div>              
            </div>              
        )
    }

}

export default Priority;