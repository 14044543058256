import axios from 'axios';
import {Auth} from 'aws-amplify';
import {
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    UPDATE_SOURCE_SUCCESS,
    UPDATE_SOURCE_FAIL,
    UPDATING_CUSTOMER,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    UPDATING_STATUS,
    UPDATING_SOURCE,
    CLEAR_CUSTOMER_STATE,
    CLEAR_CUSTOMER,
    NEW_MAGIC_LINK_FAIL,
    NEW_MAGIC_LINK_SUCCESS,
    GETTING_MAGIC_LINK,
    USER_DOESNT_EXIST,
    POST_PROSPECT_FAIL,
    POST_PROSPECT_SUCCESS,
    POSTING_PROSPECT,
    GETTING_USER
} from './types';
import keys from '../config';
import getSignature from '../helpers/aws4-utl';
import {getAuthHeaders, handleApiError} from '../helpers/apiHelper';
import awsKeys from '../aws-config';
const prod = keys.prod.hostNames.includes(window.location.hostname);
const url = prod ? keys.prod.apiUrl : keys.dev.apiUrl;

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_USER })
        getAuthHeaders().then(headers => {
            axios.get(`${url}/customer?new=1`, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: GET_USER_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, GET_USER_FAIL))
                })
                .catch(err => {
                    dispatch(handleApiError(err, GET_USER_FAIL))
                })
        }).catch(headerErr => {
            dispatch(handleApiError(headerErr, GET_USER_FAIL))
        })
    }
}

export const updateCustomer = (customer) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_CUSTOMER })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/customer/`, customer, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, UPDATE_CUSTOMER_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, UPDATE_CUSTOMER_FAIL));
                })
        })
    }
}

export const updateSource = (sourceId) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_SOURCE })
        getAuthHeaders().then( headers => {
            axios.put(`${url}/customer/source/`, {sourceId}, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_SOURCE_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, UPDATE_SOURCE_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, UPDATE_SOURCE_FAIL));
                })
        })
    }
}

export const updateCustomerStatus = (status) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_STATUS })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/customer/status/${status}/`, {}, { headers: headers })
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_STATUS_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, UPDATE_STATUS_FAIL));
                })
                .catch(err => {
                    dispatch(handleApiError(err, UPDATE_STATUS_FAIL));
                })
        })
    }
}

export const getMagicLink = (email, path, channel, username, phone) => {
    return async (dispatch) => {
        dispatch({ type: GETTING_MAGIC_LINK })
        let req;
        if(email) req = await getSignature('POST', {cognitoPoolId: awsKeys.Auth.userPoolId, email: email.toLowerCase(), path, channel}, '/magiclink');
        else if (username) req = await getSignature('POST', {cognitoPoolId: awsKeys.Auth.userPoolId, username, path, channel}, '/magiclink');
        else if (phone) req = await getSignature('POST', {cognitoPoolId: awsKeys.Auth.userPoolId, phone, path, channel}, '/magiclink');
        else dispatch(handleApiError("No idenitification received", NEW_MAGIC_LINK_FAIL));
        if(req) {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: NEW_MAGIC_LINK_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, NEW_MAGIC_LINK_FAIL))
                }).catch(err => {
                    if(err.response && err.response.status === 404)
                        dispatch(handleApiError(err, USER_DOESNT_EXIST))
                    else
                        dispatch(handleApiError(err, NEW_MAGIC_LINK_FAIL))
                })
        }
    }
}

export const postProspect = (body) => {
    return async (dispatch) => {
        dispatch({ type: POSTING_PROSPECT });
        const req = await getSignature('POST', body, '/prospect');
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: POST_PROSPECT_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, POST_PROSPECT_FAIL))
            })
            .catch(err => {  
                dispatch(handleApiError(err, POST_PROSPECT_FAIL))
            })
    }
}

export const clearCustomerState = (all) => {
    return (dispatch) => {
        if(all)
            dispatch({ type: CLEAR_CUSTOMER })
        else
            dispatch({type: CLEAR_CUSTOMER_STATE})
    }
}