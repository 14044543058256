import React, {Component, Fragment} from 'react';
import Menu from './Menu';
import Footer from '../Footer';
import {Auth} from 'aws-amplify';
import PastOrder from './PastOrder';
import ProductDetail from '../Interview/ProductDetail';
import {connect} from 'react-redux';
import Loading from './Loading';
import Welcome from './Welcome';
import {Row, Col} from 'react-bootstrap';
import * as Sentry from '@sentry/browser';
import {
    getUser,
    getOrders,
    clearOrdersState,
    getProductGroups,
} from '../../actions';

class SupplyDrops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderGroups: {},
            section: 0
        };
        
        this.showDescription = this.showDescription.bind(this);
    }

    componentDidMount() {
        // if user is not logged in, render sign in modal
        Auth.currentAuthenticatedUser().then(data => {
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
            this.setState({signedIn: true})
            this.props.getProductGroups();
        }).catch(err => {
            this.props.history.push('/login')
        })
    }

    componentDidUpdate() {
        const {user, orders} = this.props;
        const {loading, signedIn} = this.state;
        if((!user || !orders) && !loading && signedIn) {
            if(!user) {
                this.props.getUser();
            }
            if(!this.props.orders) {
                this.props.getOrders();
            }
            this.setState({loading: true});
        }

        if(orders && !this.state.orders) {
            const stateOrders = {}
            orders.forEach(o => {
                o.id = o.order.orderId
                const itemsMap = {};
                o.items.forEach(it => {
                    itemsMap[it.groupName] = it.amount/it.quantity;
                })
                o.order.products.forEach(product => {
                    if(itemsMap[product.groupName])
                        product.pricePerArticle = itemsMap[product.groupName]
                })
            })
            orders.forEach(order => {
                const {createdAt, orderId, expectedShipDate, expectedDeliveryDate, type, status, products} = order.order;
                stateOrders[orderId] = {}
                stateOrders[orderId].items = order.items;
                stateOrders[orderId].createdAt = createdAt;
                stateOrders[orderId].expectedShipDate = expectedShipDate;
                stateOrders[orderId].expectedDeliveryDate = expectedDeliveryDate;
                stateOrders[orderId].type = type;
                stateOrders[orderId].status = status;
                stateOrders[orderId].products = products;
            });
            this.setState({orders: stateOrders});
        }

        if(user && !this.state.user) {
            this.setState({user});
        }

        if(user && this.props.orders && loading) {
            this.setState({loading: false});
        }
    }

    renderLoading() {
        const {loading} = this.state;
        if(loading) {
            return (
                <Loading />
            )
        }
    }

    renderWelcome() {
        const {user} = this.state;
        if(user) {
            return (
                <Welcome customer={user.customer} />
            )
        }
    }

    renderProductDetail() {
        const {showProduct} = this.state;
        if(showProduct) {
            return <ProductDetail product={showProduct} close={() => {this.setState({showProduct: undefined})}} />
        }
    }

    renderOrders() {
        const {
            orders
        } = this.state;
        if(orders && Object.keys(orders).length) {
            const rows = [];
            Object.values(orders).forEach(order => {
                const {
                    items,
                    createdAt,
                    expectedShipDate,
                    expectedDeliveryDate,
                    type,
                    status,
                    products
                } = order;
                rows.push({
                    [expectedDeliveryDate]:
                    <PastOrder 
                        key={0}
                        items={items}
                        products={products}
                        createdAt={createdAt}
                        expectedShipDate={expectedShipDate}
                        expectedDeliveryDate={expectedDeliveryDate}
                        type={type}
                        status={status}
                        showDescription={this.showDescription}
                    />
                })
            })

            rows.sort((a,b) => {
                if(Object.keys(a)[0] > Object.keys(b)[0]) { return -1; }
                if(Object.keys(a)[0] < Object.keys(b)[0]) { return 1; }
                return 0;
            });

            const sortedRows = rows.map(row => {
                return Object.values(row)[0];
            })

            return (
                <div className="col-12">
                    <div className="space-bottom lg">
                        <div className="order container">
                            <Row className="order-row top">
                                <Col xs={6} className="text-left order-label bold">Delivery Date</Col>
                                <Col xs={6} className="text-right order-label bold">Total</Col>
                            </Row>
                            {sortedRows}
                        </div>
                    </div>
                </div>
            )
        }
    }

    showDescription(product) {
        this.setState({showProduct: product});
    } 

    render() {
        const {signedIn} = this.state;

        if(signedIn) {
            return (
                <div className="no-cloud">
                    {this.renderProductDetail()}
                    <Menu history={this.props.history} signedIn={() => {this.setState({signedIn: true})}} />
                    <div id="dashboard-body-container">
                        {this.renderWelcome()}
                        {this.renderLoading()}
                        <div className="dashboard-orders flex-wrap">
                            {this.renderOrders()}
                        </div>
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <Menu history={this.props.history} signedIn={() => {this.setState({signedIn: true})}} />
                    {this.renderLoading()}
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError } = state.user;
    const { productGroups, productGroupsError } = state.product;
    const { orders, getOrdersError, } = state.order;
    return { user, orders, getOrdersError, getUserError, productGroups, productGroupsError };
}

export default connect(mapStateToProps, {
    getUser,
    getOrders,
    clearOrdersState,
    getProductGroups
})(SupplyDrops);