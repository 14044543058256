import React, { Component } from 'react';
import images from '../img/images';
import '../Style/header.css'
class Header extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {signIn, fixed, history, showSignIn, signedIn, next, aptCampaign, spaceTop, spaceTopLg, spaceTopSm} = this.props;
        return (
            <div className={
                    `interview-nav-container
                    ${fixed ? " fixed animated-fast fadeInDown" : ""}
                    ${spaceTop ? " padding-top-delivery" : ""}
                    ${spaceTopSm ? " padding-top-delivery-sm" : ""}
                    ${spaceTopLg ? " padding-top-delivery-lg" : ""}`
                }
                >
                <div className="interview-top-row header">
                    <img className="nav-logo" src={images.logo_beta} onClick={() => history.push('/')} />
                    <button className={signIn && fixed ? "header-btn header-btn-mobile" : "hide"}></button>
                    <div className="text-center"><button className={fixed ? "btn btn-green" : "hide"} onClick={next}>Get Started</button></div>
                    <div className="header-btn-container">
                        <button className={signIn ? "header-btn" : "hide"} id="header-blog-btn" onClick={() => window.location.href = "https://blog.supplydrop.com"}>Visit our blog!</button>
                        <button className={signIn ? "header-btn" : "hide"} onClick={signedIn ? () => history.push('/dashboard') : showSignIn}>{aptCampaign ? "" : signedIn ? "My Dashboard" : "Login"}</button>
                    </div>
                    <button className={signIn && fixed ? "header-btn header-btn-mobile" : "hide"} onClick={signedIn ? () => history.push('/dashboard') : showSignIn}>{aptCampaign ? "" : signedIn ? "My Dashboard" : "Login"}</button>
                </div>
            </div>
        );
    }
}

export default Header;
