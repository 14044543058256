export const validateEmail = (email) => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
}

export const validateZip = (zip) => {
    const reg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return reg.test(zip);
}

export const validateStreetAddress = (address) => {
    if (address.split(" ").length > 2) {
        return true;
    } else {
        return false;
    }
}

export const validateCityAndNames = (value) => {
    const reg = RegExp('[A-Za-z]');
    return reg.test(value);
}

export const checkObjForNulls = (quickCheckObject) => {
    let invalidFields = Object.values(quickCheckObject).filter(field => field == null || field == undefined || field == "");
    return invalidFields.length > 0 ? false : true;
}

export const validatePassword = (pword) => {
    //between 8-20 characters with one captial letter, one lowercase and a number
    const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return reg.test(pword);
}
export const validateCvv = (value) => {
    const reg = /^[0-9]{3,4}$/;
    return reg.test(value);
}

export const validateExpDate = (date) => {
    return date > new Date() ? true : false;
}

export const validateCheckoutForm = (values) => {
    let invalidFields = false;
    for(let i = 0; i < values.length; i++) {
        invalidFields = !values[i];
        if(invalidFields) {
            break;
        }
    }
    return invalidFields;
}