import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import images from '../img/images';
import '../Style/landing.css';
import { Col, Row, Container } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import { getLanding, getProductGroups, postProspect, marginForCountdown } from '../actions';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Carousel } from 'react-bootstrap';
import MultiCarousel from 'react-multi-carousel';
import ProductDetail from './Interview/ProductDetail';
import 'react-multi-carousel/lib/styles.css';
import FontAwesome from 'react-fontawesome';
import AvailabilityGate from './AvailabilityGate';
import DocDialog from './DocModal';
import Countdown from './Countdown';

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itsAbout: [
                "time.",
                "convenience.",
                "you.",
                "preference.",
                "never running out.",
                "less hassle.",
                "quality.",
                "choice.",
                "the right amount."
            ],
            itsAboutDiv: (<span className="animated fadeInRight">time.</span>),
            itsAboutSelected: "time.",
            responsive: {
                superLargeDesktop: {
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5,
                },
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 7,
                },
                tablet: {
                    breakpoint: { max: 1024, min: 757 },
                    items: 3,
                },
                nextdown: {
                    breakpoint: { max: 757, min: 0 },
                    items: 1,
                },
            },
            zipcodes: [
                "78702",
                "78762",
                "78741",
                "78721",
                "78701",
                "78722",
                "78704",
                "78712",
                "78773",
                "78723",
                "78742",
                "78705",
                "78778",
                "78799",
                "78711",
                "78774",
                "78769",
                "78768",
                "78767",
                "78714",
                "78703",
                "78751",
                "78713",
                "78746",
                "78764",
                "78744",
                "78772",
                "78765",
                "73301",
                "78760",
                "73344",
                "78756",
                "78745",
                "78731",
                "78763",
                "78783",
                "78752",
                "78779",
                "78757",
                "78761",
                "78716",
                "78710",
                "78749",
                "78748",
                "78766",
                "78718",
                "78715",
                "78709",
                "78708",
                "78735",
                "78759",
                "78738",
                "78758",
                "78726",
                "78727",
                "78733",
                "78747",
                "78739"
            ]
        };

        this.handleKeydown = this.handleKeydown.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.showSignIn = this.showSignIn.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        // handle fb campaign params
        const {zipcodes} = this.state;
        if(zipcodes) localStorage.setItem('zipcodes', JSON.stringify(zipcodes));
        let search = window.location.search;
        let referrer;
        const seshReferrer = JSON.parse(sessionStorage.getItem('referrer'));
        if (!seshReferrer) referrer = document.referrer;
        else referrer = seshReferrer
        sessionStorage.setItem('referrer', JSON.stringify(referrer));
        if (search) {
            try {
                search = JSON.parse(('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'));
                const { utm_campaign, utm_content, fbclid, rc } = search;
                if (utm_campaign) {
                    this.props.postProspect({
                        campaignId: utm_campaign,
                        referrer,
                        attributes: {
                            utm_content,
                            fbclid
                        }
                    })
                }
                if (typeof rc === 'string' && rc.toLowerCase() === "sd4me") {
                    sessionStorage.setItem('goldenTicket', JSON.stringify(rc.toLowerCase()))
                }
                sessionStorage.setItem('campaignCode', JSON.stringify(utm_campaign));
                this.setState({ utm_campaign })
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
        let aptName = "";
        // handle multiple paths for lenox
        if (
            window.location.pathname === "/lenoxboardwalk"
            || window.location.pathname === "/lenox-boardwalk"
            || window.location.pathname === "/lenox-boardwalk/"
            || window.location.pathname === "/lenoxboardwalk/"
        ) {
            window.location.pathname = "";
            this.props.history.push('/lenox-boardwalk')
        }
        if (window.location.hash === "#/lenox-boardwalk") {
            aptName = "Lenox Boardwalk"
            this.setState({ aptName, aptCampaign: "lenox-boardwalk" });
            sessionStorage.setItem('campaignCode', JSON.stringify("lenox-boardwalk"));
        }
        if (window.location.pathname === "/dashboard") {
            window.location = `${window.location.origin}/#/dashboard`
        }
        let params = this.props.location.search;
        if (params) {
            try {
                params = JSON.parse(('{"' + decodeURI(params.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'));
                const { a, rc } = params;
                if (a) {
                    // TODO: remove if statement before next apartment campaign
                    // replace with switch for known campaigns, or as param for GET /landing
                    if (a === "lenox-boardwalk") {
                        // for future use on other complexes:
                        // 
                        // const values = a.split('-')
                        // values.forEach(val => {
                        //     aptName += val.charAt(0).toUpperCase() + val.substring(1) + " ";
                        // })
                        // aptName = aptName.trim();
                        // this.setState({aptName, aptCampaign: a});
                        aptName = "Lenox Boardwalk"
                        this.setState({ aptName, aptCampaign: "lenox-boardwalk" });
                        sessionStorage.setItem('campaignCode', JSON.stringify("lenox-boardwalk"));
                    }
                }
                if (typeof rc === 'string' && rc.toLowerCase() === "sd4me") {
                    sessionStorage.setItem('goldenTicket', JSON.stringify(rc.toLowerCase()))
                }
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
        this.props.getLanding();
        this.props.getProductGroups();
        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ signedIn: true });
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
        }).catch(err => {
            this.setIdentityBreadcrumb();
        })

        if (!aptName) {
            this.interval = setInterval(this.changeMessage.bind(this), 2000);
        }

        window.addEventListener('scroll', this.handleScroll);

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    componentDidUpdate() {
        const {
            productGroups,
            productGroupsError,
            gettingLanding,
            landing,
            postProspectError,
            postProspectSuccess,
            aptCampaign,
            landingError,
            zipcodes
        } = this.props;

        if(zipcodes && !localStorage.getItem('zipcodes')) localStorage.setItem('zipcodes', JSON.stringify(zipcodes))

        const { prospectPosted } = this.state;

        if (landing && !this.state.landed) {
            this.setState({ landed: true });
            const { carousel, dropoffZipcodes, signupsOpen } = landing;
            if (carousel) {
                this.setState({ carousel, dropoffZipcodes, signupsOpen })
                sessionStorage.setItem('dropoffZipcodes', JSON.stringify(dropoffZipcodes));
                sessionStorage.setItem('signupsOpen', JSON.stringify(signupsOpen));
            }
        }
        if (productGroups && !this.state.productGroups) {
            const productsMap = {};
            productGroups.map(pg => {
                productsMap[pg.groupName] = {};
                productsMap[pg.groupName].groupName = pg.groupName;
                productsMap[pg.groupName].brandName = pg.brandName;
                productsMap[pg.groupName].displayName = pg.displayName;
                productsMap[pg.groupName].pricePerArticle = pg.pricePerArticle;
                productsMap[pg.groupName].articleType = pg.articleType;
                productsMap[pg.groupName].badges = pg.badges;
                productsMap[pg.groupName].imageUrl = pg.imageUrl;
                productsMap[pg.groupName].description = pg.description;
                productsMap[pg.groupName].productType = pg.productType;
                productsMap[pg.groupName].possibleArticleCounts = pg.possibleArticleCounts;
            })
            sessionStorage.setItem('productsMap', JSON.stringify(productsMap));
            this.setState({ productGroups, productsMap });
        }
        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError });
        }

        if ((postProspectSuccess || postProspectError) && !prospectPosted) {
            this.setState({ prospectPosted: true })
        }
        if (prospectPosted && aptCampaign && !gettingLanding) {
            this.props.getLanding();
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Sentry.addBreadcrumb({
                    category: "_identityId",
                    message: `User _identityId: ${creds._identityId}`,
                    level: Sentry.Severity.Info
                })
            }
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('scroll', this.handleScroll);
    }

    changeMessage() {
        let { itsAbout, itsAboutSelected, checkAvailability } = this.state;
        if (!checkAvailability) {
            const index = itsAbout.indexOf(itsAboutSelected);
            itsAboutSelected = index + 1 === itsAbout.length ? itsAbout[0] : itsAbout[index + 1]
            const itsAboutDiv = <span className="animated" id="whats-it-about">{itsAboutSelected}</span>
            this.setState({ itsAboutSelected, itsAboutDiv });
            const element = document.getElementById("whats-it-about");
            element.classList.add("fadeInRight")
            setTimeout(() => {
                element.classList.remove("fadeInRight")
            }, 1000)
        }
    }

    getDocTop() {
        if (typeof window.pageYOffset !== 'undefined') {
            //most browsers except IE before #9
            return window.pageYOffset;
        }
        else {
            var B = document.body; //IE 'quirks'
            var D = document.documentElement; //IE with doctype
            D = (D.clientHeight) ? D : B;
            return D.scrollTop;
        }
    }

    handleScroll(e) {
        const { fixedNav } = this.state;
        const docTop = this.getDocTop();
        if (docTop > 564 && !fixedNav) {
            this.setState({ fixedNav: true })
        }

        if (docTop < 564 && fixedNav) {
            this.setState({ fixedNav: false })
        }
    }

    handleKeydown(e) {
        if (e.keyCode === 13) {
            this.onGetStartedClick();
        }
    }

    onGetStartedClick() {
        const { dropoffZipcodes, zipcodes, signupsOpen, aptCampaign, firstName, lastName, aptNum, utm_campaign } = this.state;
        const { gettingLanding } = this.props;
        const goldenTicket = JSON.parse(sessionStorage.getItem('goldenTicket'));
        const zipcode = JSON.parse(sessionStorage.getItem('zipcode'));
        const zipcodeApproved = JSON.parse(sessionStorage.getItem('zipcodeApproved'));
        const referrer = JSON.parse(sessionStorage.getItem('referrer'));
        if (aptCampaign) {
            if (firstName && lastName && aptNum) {
                this.props.postProspect({
                    campaignId: aptCampaign,
                    referrer,
                    attributes: {
                        aptNum,
                        firstName,
                        lastName
                    }
                });
                sessionStorage.setItem('aptCampaignShipping', JSON.stringify({
                    firstName,
                    lastName,
                    line1: `2515 Elmont Dr`,
                    line2: `Apt ${aptNum}`,
                    city: "Austin",
                    state: "TX",
                    zipcode: "78741"
                }));
                sessionStorage.setItem('zipcode', JSON.stringify("78741"));
                sessionStorage.setItem('zipcodeAppoved', JSON.stringify(true));
                clearInterval(this.interval);
                this.props.history.push('/start');
            } else if (!firstName) {
                document.getElementById("firstNameInput").focus()
            } else if (!lastName) {
                document.getElementById("lastNameInput").focus()
            } else if (!aptNum) {
                document.getElementById("aptNumInput").focus()
            }
        } else if (zipcode && zipcodeApproved) {
            this.props.postProspect({referrer, attributes: {}});
            this.props.history.push('/start');
        } else {
            clearInterval(this.interval);
            this.props.postProspect({ referrer, attributes: {} });
            this.setState({ checkAvailability: true });
        }
    }

    showSignIn() {
        this.props.history.push('/login')
    }

    renderProductsCarousel() {
        const { responsive, carousel, productsMap } = this.state;
        if (productsMap && carousel) {
            const products = [];
            carousel.map(product => {
                const group = productsMap[product];
                if (group) {
                    products.push(
                        <div className="products-carousel-item">
                            <img
                                onClick={() => this.setState({ showProduct: group })}
                                src={group.imageUrl}
                                alt={`${group.brandName} ${group.displayName}`}
                            />
                        </div>
                    )
                }
            })
            return (
                <Col xs={12} id="landing-products-carousel">
                    <MultiCarousel
                        responsive={responsive}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={4000}
                        infinite={true}
                    >
                        {products}
                    </MultiCarousel>
                </Col>
            )
        }
    }

    renderFixedNav() {
        const {fixedNav, showSignIn, showModal, signedIn, aptCampaign, zipcode} = this.state;
        if(fixedNav) {
            return (
                <Header
                    fixed={true}
                    history={this.props.history}
                    showSignIn={aptCampaign ? false : this.showSignIn}
                    signedIn={signedIn}
                    signIn={!(showSignIn || showModal)}
                    aptCampaign={aptCampaign}
                    spaceTop={zipcode}
                    next={this.onGetStartedClick}
                />
            )
        }
    }

    renderProductDetail() {
        const { showProduct } = this.state;
        if (showProduct) {
            return (
                <ProductDetail product={showProduct} close={() => this.setState({ showProduct: undefined })} />
            )
        }
    }

    renderHeroText() {
        const { aptCampaign, aptName, itsAboutDiv, firstName, lastName, aptNum, prospectPosted } = this.state;
        const { gettingLanding, postingProspect } = this.state;
        if (aptCampaign && aptName) {
            return (
                <div>
                    <h1 className="landing-main-header">Welcome {aptName} Residents</h1>
                    <div className="landing-detail-wrapper apt">
                        <p className="landing-section-detail apt">
                            Supply Drop is proud to deliver all the household needs of {aptName} Residents!<br />
                        </p>
                    </div>
                    <div className="landing-apt-input-container">
                        <input
                            type="text"
                            className="landing-input"
                            placeholder="First Name"
                            id="firstNameInput"
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                            onKeyDown={(e) => this.handleKeydown(e)}
                        />
                        <input
                            type="text"
                            className="landing-input"
                            id="lastNameInput"
                            placeholder="Last Name"
                            onKeyDown={(e) => this.handleKeydown(e)}
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                        />
                        <input
                            type="text"
                            className="landing-input"
                            id="aptNumInput"
                            placeholder="Apartment #"
                            onChange={(e) => this.setState({ aptNum: e.target.value })}
                            onKeyDown={(e) => this.handleKeydown(e)}
                        />
                    </div>
                    <div className="text-center">
                        <button onClick={this.onGetStartedClick} className="landing-get-started">
                            Get Started
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h1 className="landing-main-header mobile-small">It's about<br />
                        {itsAboutDiv}
                    </h1>
                    <div className="landing-detail-wrapper">
                        <p className="landing-section-detail">
                            Home supplies curated and delivered to your door every month.<br/>
                            In stock and delivered within 5 business days or less!
                        </p>
                    </div>
                    <div className="text-center"><button onClick={this.onGetStartedClick} className="landing-get-started">Get Started</button></div>
                </div>
            )
        }
    }

    renderCovidResponse() {
        const { showCovidResponse } = this.state;
        if (showCovidResponse) {
            return (
                <DocDialog doc="covid" hide={() => this.setState({ showCovidResponse: false })} />
            )
        }
    }

    renderCountdown() {
        const {zipcode} = this.state;

        if(zipcode) {
            return <Countdown />
        }
    }

    render() {
        const {showSignIn, showModal, aptCampaign, checkAvailability, dropoffZipcodes, zipcodes, signupsOpen, showCovidResponse, productsMap, carousel, zipcode } = this.state;
        if(checkAvailability) {
            return <AvailabilityGate
                history={this.props.history}
                signupsOpen={signupsOpen}
                dropoffZipcodes={dropoffZipcodes}
                zipcodes={zipcodes}
                close={() => this.setState({ checkAvailability: false })}
            />
        } else {
            return (
                <div className="landing">
                    {this.renderCountdown()}
                    {this.renderFixedNav()}
                    {this.renderProductDetail()}
                    {this.renderCovidResponse()}
                    <Container id="landing-container">
                        <div className={`landing-video-container${aptCampaign ? "" : " mobile-small"} ${zipcode ? "position-top-delivery" : ""}`}>
                        {window.chrome ? 
                            <div id="hero-img" className={`${aptCampaign ? "" : " mobile-small"} ${zipcode ? "position-top-delivery" : ""}`}></div>
                            :
                            <video
                                muted
                                autoPlay
                                loop
                                playsinline
                                poster={images.hero}
                                id="landing-video" className={`${aptCampaign ? "" : " mobile-small"} ${zipcode ? "position-top-delivery" : ""}`}
                            >
                                <source src={images.landing_video} type="video/mp4" />
                            </video>
                            }
                        </div>
                        <div className={`video-overlay${aptCampaign ? "" : " mobile-small"} ${zipcode ? "position-top-delivery" : ""}`}></div>
                        <Row className={`landing-top-section${aptCampaign ? "" : " mobile-small"}`}>
                            <Row className={showCovidResponse ? "hide" : "landing-header landing-top-content"}>
                                <Header signIn={!(showSignIn || showModal)} spaceTop={zipcode} history={this.props.history} aptCampaign={aptCampaign} about={() => this.props.history.push('/about')} showSignIn={this.showSignIn} signedIn={this.state.signedIn}/>
                            </Row>
                            <Row className="landing-top-section-main landing-top-content">
                                <Col xs={12} className="no-padding">
                                    {this.renderHeroText()}
                                </Col>
                            </Row>
                        </Row>
                        <Row className="landing-section-container fff">
                            <Row className="landing-section">
                                <Col xs={12} className="text-center"><h2 className="small-margin">We Are Supply Drop</h2></Col>
                                <Col xs={12} className="text-left landing-content no-margin-bottom">
                                    <div className="landing-who-we-are">
                                        Supply Drop was founded in 2019 in Austin, Texas. Our mission is to deliver a magical experience – which means being exceptional at delivering all our customers household needs as seamlessly as possible. To do this requires some interaction between us during the first few months to learn and tailor as much as possible for each individual household. We strive to communicate with our customers to make this process as easy and natural as possible - we’re here to make your life easier!
                                    </div>
                                    <div className="btn-container">
                                        <button className="btn-blue" onClick={() => this.setState({ showCovidResponse: true })}>Our response to COVID-19</button>
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                        <Row className="landing-section-container">
                            <Row className="landing-section">
                                <Col xs={12} className="text-center"><h2>How it works</h2></Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <img src={images.landing_setup} alt="How it works" className="landing-hiw-img" />
                                    <h4>Easy</h4>
                                    <div className="landing-hiw-description">Answer a few questions to get setup</div>
                                </Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <img src={images.landing_personalized} alt="How it works" className="landing-hiw-img" />
                                    <h4>Personalized</h4>
                                    <div className="landing-hiw-description">Your favorite brands in the quantities you need</div>
                                </Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <img src={images.landing_shipping} alt="How it works" className="landing-hiw-img" />
                                    <h4>Automatic</h4>
                                    <div className="landing-hiw-description">Home supplies replenished every month</div>
                                </Col>
                            </Row>
                        </Row>
                        <Row className="landing-section-container" id="landing-preference-section">
                            <Row className="landing-section">
                                <Col xs={12} className="text-center"><h2>What's included</h2></Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <img src={images.everyday_cluster} alt="Everyday pack" className="landing-cluster-img" />
                                    <h4 className="landing-cluster-header">Everyday Essentials</h4>
                                    <div>
                                        The supplies you use everyday.
                                        Your first delivery includes a starter pack of toilet paper, paper towels and kitchen trash bags.
                                        You can also enjoy hassle free monthly delivery of facial tissue, hand wipes and other types of trash bags.
                                    </div>
                                </Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <img src={images.kitchen_cluster} alt="Kitchen pack" className="landing-cluster-img" />
                                    <h4 className="landing-cluster-header">Kitchen & Cleaning Essentials</h4>
                                    <div>
                                        Those easy to forget essential supplies for the kitchen and cleaning.
                                        We currently offer hand soap, dish soap and dishwasher detergent in a variety of options to fit your preferences.
                                    </div>
                                </Col>
                                <Col xs={12} lg={4} className="text-center landing-content">
                                    <div id="landing-laundry-img">
                                        <img src={images.laundry_cluster} alt="Laundry pack - coming soon!" className="landing-cluster-img" />
                                    </div>
                                    <h4 className="landing-cluster-header">Laundry Essentials</h4>
                                    <div>
                                        Never hassle with laundry supplies again!
                                        We ensure you always have laundry detergent, softener and dryer sheets so you can start and finish any load of laundry.
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center"><h2>Personalized to your preferences</h2></Col>
                                <Col xs={12} className="landing-preferences-row">
                                    <div className="landing-preference">
                                        <img src={images.NationallyRecognizedBrandLead} alt="National brands" className="landing-emoji" />
                                        <div>National Brands</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.SupplyDropPick} alt="Premium Products" className="landing-emoji" />
                                        <div>Premium Products</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.EcoFriendly} alt="Eco-friendly" className="landing-emoji" />
                                        <div>Eco-friendly</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.SensitiveSkin} alt="Sensitive Skin" className="landing-emoji" />
                                        <div>Sensitive Skin</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.SocialGood} alt="Brands that Give Back" className="landing-emoji" />
                                        <div>Brands that Give Back</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.Value} alt="Value" className="landing-emoji" />
                                        <div>Value</div>
                                    </div>
                                    <div className="landing-preference">
                                        <img src={images.AllNatural} alt="All Natural" className="landing-emoji" />
                                        <div>All Natural</div>
                                    </div>
                                </Col>
                                {this.renderProductsCarousel()}
                            </Row>
                        </Row>
                        <Row className="landing-section-container" id="landing-testimonial-container">
                            <Row className="landing-section">
                                <Col xs={12} className="text-center"><h5>What People Are Saying</h5></Col>
                                <Carousel interval={6000} touch={true} id="landing-testimonial-carousel" direction={'right'} onSelect={this.handleSelect} indicators={true} controls={false}>
                                    <Carousel.Item className="text-center">
                                        <h2>“I love that I’ll never need to go to the store and weave through people to get my TP.  Also thanks for introducing me to Soapbox Soap, it’s awesome!”</h2>
                                        <h5>Devin P.</h5>
                                    </Carousel.Item>
                                    <Carousel.Item className="text-center">
                                        <h2>“Now I can just stop at one grocery store! Whole Foods for yummies. Supply Drop for necessities.”</h2>
                                        <h5>Amy R.</h5>
                                    </Carousel.Item>
                                    <Carousel.Item className="text-center">
                                        <h2>“I love the convenience of Supply Drop... my dogs love the treats in every month’s delivery!”</h2>
                                        <h5>Cody C.</h5>
                                    </Carousel.Item>
                                    <Carousel.Item className="text-center">
                                        <h2>“Same price as HEB but no hassle. Yay for being able to grab the small cart now!”</h2>
                                        <h5>Sally J.</h5>
                                    </Carousel.Item>
                                </Carousel>
                            </Row>
                        </Row>
                        <Row className="landing-section-container fff">
                            <Row className="landing-section">
                                <Col xs={12} className="landing-faq-container">
                                    <h2 id="landing-faq-header" className="text-center">FAQ</h2>
                                    <div className="landing-faq">
                                        <h4>Is Supply Drop available in my area?</h4>
                                        <div>We are currently offering this magical experience only to residents of Austin, TX.</div>
                                    </div>
                                    <div className="landing-faq">
                                        <h4>Is shipping always free?</h4>
                                        <div>Yes, but only if you sign up during our Beta!</div>
                                    </div>
                                    <div className="landing-faq">
                                        <h4>Can I change products or adjust quantities?</h4>
                                        <div>Of course! Text us or visit your dashboard and we’ll show you more options for you to choose from. You can also make adjustments.</div>
                                    </div>
                                    <div className="landing-faq">
                                        <h4>How does the cost compare to my grocery store?</h4>
                                        <div>Our costs are comparable to shopping online or at your local store but without the hassle!</div>
                                    </div>
                                    <div className="landing-faq">
                                        <h4>Can I cancel anytime?</h4>
                                        <div>Of course, but you‘ll be so delighted that you won't want to!</div>
                                    </div>
                                    <div className="landing-faq">
                                        <h4>Do you use my information for anything else?</h4>
                                        <div>Privacy is important to us. We hate spam and will never sell your personal information.</div>
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                    <Footer
                        history={this.props.history}
                        landing={true}
                    />
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    const { postProspectSuccess, postProspectError, postingProspect } = state.user;
    const { landing, gettingLanding, landingError } = state.order;
    const { productGroups, productGroupsError } = state.product;
    return { landing, gettingLanding, landingError, productGroups, productGroupsError, postProspectSuccess, postProspectError, postingProspect };
}

export default connect(mapStateToProps, {
    getLanding,
    getProductGroups,
    postProspect
})(Landing);