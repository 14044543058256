import React, {Component} from 'react';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';

class DishwashingProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dishwashing: {

            },
            moreInfoCopy: (
                <div>
                    We don’t want to send you something that won’t work with the appliances in your home, so let us know if you need packs or gel!
                </div>
            )
        }

        this.toggleProduct = this.toggleProduct.bind(this);
        this.continue = this.continue.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo, Gel, Packs} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else if(Gel || Packs) {
                this.continue();
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({ isModal: this.props.isModal });

        const cookingFrequency = JSON.parse(sessionStorage.getItem('cookingFrequency'));
        if(cookingFrequency === undefined) this.props.history.push('/kitchen/1');
        const dishwashing = JSON.parse(sessionStorage.getItem('dishwashing'));
        if(!dishwashing || !dishwashing.frequency) {
            this.props.history.push('/kitchen/2');
        }
        if(dishwashing) {
            this.setState({dishwashing});
            if(dishwashing.products && dishwashing.products.length) {
                dishwashing.products.map(p => {
                    this.setState({[p]: true})
                })
            }
        }
    }

    toggleProduct(product) {
        const otherProduct = product === "Gel" ? "Packs" : "Gel"
        this.setState({[product]: !this.state[product], [otherProduct]: false})
    }

    continue() {
        const {
            dishwashing,
            Gel,
            isModal
        } = this.state;

        const products = [Gel ? "Gel" : "Packs"];

        dishwashing.products = products;
        sessionStorage.setItem('dishwashing', JSON.stringify(dishwashing));

        if(isModal) this.props.next({dishwashing})
        else this.props.history.push('/kitchen/4');
    }

    renderContinue() {
        const {Packs, Gel, isModal } = this.state;
        if (isModal) {
            return (
                <div className="interview-modal-button-row">
                    <button className="dashboard-btn dashboard-submit white" onClick={this.props.back}>Back</button>
                    <button className="dashboard-btn dashboard-save" onClick={this.continue}>Continue</button>
                </div>
            )
        } else {
            return (
                <div className="interview-continue-wrapper">
                    <button disabled={(!Gel && !Packs)} className="interview-continue-button" onClick={this.continue}>Continue</button>
                    <p className="interview-enter-text">press <b>enter</b></p>
                </div>
            )
        }
    }

    renderTopRow() {
        const {isModal} = this.state;
        if(!isModal) {
            return (
                <div className="interview-nav-container">
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.push('/kitchen/2')} className="interview-icon interview-back-arrow" />
                        <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                    </div>
                </div>
            )
        }
	}

    renderProducts() {
        const {
            Gel,
            Packs,
        } = this.state;

        return (
            <div className="tiers-container">
                <div className="tier-pill-wrapper">
                    <div id="brand-btn" className={`interview-pill brand ${ Gel ? 'selected' : ''}`} onClick={() => this.toggleProduct("Gel")}>Detergent Gel</div>
                </div>
                <div className="tier-pill-wrapper">
                    <div id="premium-btn" className={`interview-pill brand ${ Packs ? 'selected' : ''}`} onClick={() => this.toggleProduct("Packs")}>Detergent Packs</div>
                </div>
            </div>
        )
    }
    
    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderHeader() {
        const { isModal } = this.state;
        if (!isModal) {
            return <Header history={this.props.history} />
        }
    }

    renderProgressBar() {
        const { isModal } = this.state;
        if (!isModal) {
            return <ProgressBar progress={85} />
        }
    }

    renderFeedback() {
        const { isModal } = this.state;
        if (!isModal) {
            return <Feedback question="dishwashingProducts" />
        }
    }

    render() {
        const {isModal} = this.state;
        return (
            <div>
                {this.renderProgressBar()}
                {this.renderHeader()}
                {this.renderInfo()}
				<div className={isModal ? "interview-modal-outer" : "interview-outer"}>
					{this.renderTopRow()}
                    <div className={`${isModal? "interview-modal-inner" : "interview-inner"} fadeInRight animated-fast text-center`}>
                        <div className="interview-header-img"><img src={images.dishwashingProducts} alt="cooking" /></div>
                        <p className="interview-copy">
                            Which of the following do you use in your dishwasher?
                        </p>
                        {this.renderProducts()}
                        {this.renderContinue()}
                    </div>
                    {this.renderFeedback()}
                </div>              
            </div>              
        )
    }

}

export default DishwashingProducts;