import config from '../config';
import axios from 'axios';
import {
    GET_PRODUCT_GROUPS_SUCCESS,
    GET_PRODUCT_GROUPS_FAIL,
    GETTING_PRODUCT_GROUPS
} from './types';
import keys from '../config';
import {handleApiError} from '../helpers/apiHelper';
import getSignature from '../helpers/aws4-utl';

const prod = keys.prod.hostNames.includes(window.location.hostname);
const url = prod ? keys.prod.apiUrl : keys.dev.apiUrl;

export const getProductGroups = () => {
    return async (dispatch) => {
        dispatch({ type: GETTING_PRODUCT_GROUPS });
        const req = await getSignature('GET', {}, '/product_groups');
        axios.request(req.request)
            .then(res => {
                if (res.status === 200)
                    dispatch({ type: GET_PRODUCT_GROUPS_SUCCESS, payload: res.data });
                else
                    dispatch(handleApiError(res.error, GET_PRODUCT_GROUPS_FAIL))
            })
            .catch(err => {
                dispatch(handleApiError(err, GET_PRODUCT_GROUPS_FAIL))
            })
        }
}