import React, {Component} from 'react';
import ProgressBar from '../ProgressBar';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import MoreInfo from '../MoreInfo';
import {Auth} from 'aws-amplify';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moreInfoCopy: (
                <div>You will receive an initial delivery of Everyday Essentials (toilet paper, paper towels and kitchen trash bags) followed by a monthly replenishment of those supplies based on your specific households needs and product preferences.</div>
            )
        };
        this.handleKeydown = this.handleKeydown.bind(this);
        this.back = this.back.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
		Auth.signOut();
    }

    componentDidMount() {
        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    back() {
        const aptCampaign = JSON.parse(sessionStorage.getItem('aptCampaign'));
        if(aptCampaign) {
            this.props.history.push(`/?a=${aptCampaign}`);
        } else {
            this.props.history.push('/')
        }
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.props.history.push('/kitchen');
            }
        }
    }
    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderCountdown() {
        const {zipcode} = this.state;
        if(zipcode) return <Countdown spaceTop={true} />
    }

    render() {
        const {zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={10} />
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row">
                            <img src={images.back_arrow} onClick={this.back} className="interview-icon interview-back-arrow" />
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                        </div>
                    </div>
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img"><img src={images.everyday} alt="House" /></div>
                        <div className="interview-copy-container">
                            <h2 className="cluster-header">Everyday Essentials</h2>
                            <p className="interview-copy">
                                Answer a few questions about your household so we can get you set-up with your monthly supply of toilet paper, paper towels and kitchen trash bags.
                                Never worry about running out of those Everyday Essentials again!
                            </p>
                            <h4>What's Included</h4>
                            <div className="checked-list">
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Toilet Paper</div>
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Paper Towels</div>
                                <div className="checked-list-item"><i class="fa fa-check-circle" aria-hidden="true"></i>Trash Bags</div>
                            </div>
                        </div>
                        <div className="interview-continue-wrapper">
                            <button className="interview-continue-button" onClick={() => this.props.history.push('/kitchen')}>Continue</button>
                            <p className="interview-enter-text">press <b>enter</b></p>
                        </div>
                    </div>
                    <Feedback question="everydayStart" />
                </div>
            </div>
        )
    }
}

export default Start;