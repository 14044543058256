import React from 'react';

const Counter = ({
    type,
    increment,
    decrement,
    count,
    min,
    max,
    units
}) => {
    return (
        <div className={`${type}-counter-body`}>
            <div className={`${type}-counter-button`} onClick={min === undefined || min === null || count > min ? decrement : null}>—</div>
            <div className={`${type}-counter-value`}>{count}{units ? ` ${units}` : ''}</div>
            <div className={`${type}-counter-button`} onClick={ max === undefined || max === null || count < max ? increment : null}>+</div>
        </div>
    )
}

export default Counter;