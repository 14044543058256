import React from 'react';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';
import Counter from '../Counter';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class TrashPreferences extends React.Component {
		constructor(props, context) {
		super(props, context);

		this.state = {
			show: false,
			error: '',
			trashFrequency: 1,
			moreInfoCopy: (
				<div>
					Your first month, we’ll send you a starter pack of 40 trash bags.
					Then, based on how many trash bags you use a week, we’ll calculate when to send you more.
				</div>
			)
		};
		
		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.continue = this.continue.bind(this);
		this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.continue();
            }
        }
    }
	componentDidMount() {
        window.scrollTo(0, 0);

		const bathrooms = JSON.parse(sessionStorage.getItem('bathrooms'));
		const family = JSON.parse(sessionStorage.getItem('family'));
		const trashFrequency = JSON.parse(sessionStorage.getItem('trashFrequency'));
		if(!family) this.props.history.push('/start/1');
		else if(!bathrooms) this.props.history.push('/start/3');
		else if(trashFrequency) {
			this.setState({trashFrequency});
		}

		const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
		this.setState({zipcode});
		if(zipcode) marginForCountdown();
	}

	continue() {
		const {trashFrequency} = this.state;
		sessionStorage.setItem('trashFrequency', JSON.stringify(trashFrequency));
		this.props.history.push('/start/5');
	}

	decrement() {
		let {trashFrequency} = this.state;
		if(trashFrequency > 1) trashFrequency -= 1;
		this.setState({ trashFrequency })
	}

	increment() {
		let {trashFrequency} = this.state;
		trashFrequency += 1;
		this.setState({ trashFrequency })
	}

	renderTopRow() {
		return (
            <div className="interview-nav-container">
				<div className="interview-top-row">
					<img src={images.back_arrow} onClick={() => this.props.history.push('/start/3')} className="interview-icon interview-back-arrow" />
					<img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
				</div>
			</div>
		)
	}

	renderContinue() {
		const {trashFrequency} = this.state;
		return (
			<div className="interview-continue-wrapper">
				<button className="interview-continue-button" onClick={this.continue}>Continue</button>
				<p className="interview-enter-text">press <b>enter</b></p>
			</div>
		)
	}

	renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
	}
	
	renderCountdown() {
		if(this.state.zipcode) return <Countdown spaceTop={true} />
	}

	render() {
		const {trashFrequency, zipcode} = this.state;
		const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        return (
            <div>
                <ProgressBar progress={hasKitchen ? 60 : 70} />
				{this.renderCountdown()}
				<Header history={this.props.history} spaceTopLg={zipcode} />
				{this.renderInfo()}
				<div className="interview-outer">
					{this.renderTopRow()}
					<div className="interview-inner fadeInRight animated-fast text-center">
						<div className="interview-header-img"><img src={images.trashbag} alt="Trashbag" /></div>
						<p className="interview-copy">How many tall kitchen trash bags (13gal) do you use a week?</p>
						<Counter type="interview" min={1} count={trashFrequency} increment={this.increment} decrement={this.decrement} />
						{this.renderContinue()}
					</div>
					<Feedback question="kitchenTrash" />
				</div>
			</div>			
		);
	}
}
	
export default TrashPreferences;	