import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Landing from './components/Landing';
// Everyday
import EverydayStart from './components/Interview/Everyday/EverydayStart';
import Household from './components/Interview/Everyday/Household';
import StaysHome from './components/Interview/Everyday/StaysHome';
import Bathrooms from './components/Interview/Everyday/Bathrooms';
import TrashPreferences from './components/Interview/Everyday/TrashPreferences';
import TierPreferences from './components/Interview/Everyday/TierPreferences';

// Kitchen
import KitchenStart from './components/Interview/Kitchen/KitchenStart';
import Cooking from './components/Interview/Kitchen/Cooking';
import DishwashingFrequency from './components/Interview/Kitchen/DishwashingFrequency';
import DishwashingProducts from './components/Interview/Kitchen/DishwashingProducts';
import Cleaning from './components/Interview/Kitchen/Cleaning';

import OrderPrediction from './components/Interview/OrderPrediction';
import Checkout from './components/checkout/CustomerInfo';
import Billing from './components/checkout/Billing';
import Shipping from './components/checkout/Shipping';
import Confirmation from './components/checkout/Confirmation';
import OrderComplete from './components/OrderComplete';
import Dashboard from './components/user/Dashboard';
import Account from './components/user/Account';
import OrderHistory from './components/user/OrderHistory';
import NotFound from './components/404';
import Login from './components/Login';

import Magiclink from './components/Magiclink';

import keys from './config';
import ReactGA from 'react-ga';
import withTracker from './ga/withTracker';

import Amplify from 'aws-amplify';
import awsconfig from './aws-config';

import { StripeProvider, Elements } from 'react-stripe-elements';

import './App.css';
import './Style/animate.css';
import './Style/animations.css';
import './Style/style.css';
import './Style/modifiers.css';
import './Style/flex.css';

import Analytics from '@aws-amplify/analytics';
const prod = keys.prod.hostNames.includes(window.location.hostname);

Analytics.configure({
  Auth: {
    identityPoolId: awsconfig.identityPoolId,
    region: awsconfig.region
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
  },
  AWSPinpoint: {
    appId: keys[prod ? 'prod' : 'dev'].pinpointAppId,
    region: 'us-east-1',
    mandatorySignIn: false,
  }
});

Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'pageView',
  // OPTIONAL, the attributes of the event, you can either pass an object or a function 
  // which allows you to define dynamic attributes
  attributes: {
      attr: 'attr'
  },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  type: 'SPA',
  // OPTIONAL, the service provider, by default is the AWS Pinpoint
  provider: 'AWSPinpoint',
  // OPTIONAL, to get the current page url
  getUrl: () => {
      // the default function
      return window.location.origin + window.location.pathname;
  }
});

const fontAwesome = (
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet" />
);

class App extends Component {
  render() {
    return (
      <HashRouter>
          <div id="cloud-intro">
            {fontAwesome}
            <StripeProvider apiKey={prod ? keys.prod.stripeKey : keys.dev.stripeKey}>
              <Switch>
                  <Route path="/" component={withTracker(Landing)} exact />
                  <Route path="/lenox-boardwalk" component={withTracker(Landing)} exact />
                  {/* #Interview Questions */}
                  <Route path="/start" component={withTracker(EverydayStart)} exact />
                  <Route path="/start/1" component={withTracker(Household)} exact />
                  <Route path="/start/2" component={withTracker(StaysHome)} exact />
                  <Route path="/start/3" component={withTracker(Bathrooms)} exact />
                  <Route path="/start/4" component={withTracker(TrashPreferences)} exact />
                  <Route path="/start/5" component={withTracker(TierPreferences)} exact />
                  <Route path="/prediction" component={withTracker(OrderPrediction)} exact />
                  {/* #End */}
                  {/* Kitchen */}
                  <Route path="/kitchen" component={withTracker(KitchenStart)} exact />
                  <Route path="/kitchen/1" component={withTracker(Cooking)} exact />
                  <Route path="/kitchen/2" component={withTracker(DishwashingFrequency)} exact />
                  {/* <Route path="/kitchen/3" component={withTracker(DishwashingProducts)} exact /> */}
                  <Route path="/kitchen/3" component={withTracker(Cleaning)} exact />
                  {/* Kitchen End */}
                  {/* <Route path="/summary" component={withTracker(Summary)} exact/> */}
                  {/* Checkout */}
                  <Route path="/ordercomplete" component={withTracker(OrderComplete)} exact />
                  <Route path="/checkout" component={withTracker(Checkout)} exact />
                  <Route path="/checkout/shipping" component={withTracker(Shipping)} exact />
                  <Route path="/checkout/billing" component={withTracker(Billing)} exact />
                  <Route path="/checkout/confirm" component={withTracker(Confirmation)} exact />
                  {/* Dashboard */}
                  <Route path="/dashboard" component={withTracker(Dashboard)} exact/>
                  <Route path="/dashboard/orders" component={withTracker(OrderHistory)} exact/>
                  <Route path="/dashboard/account" component={withTracker(Account)} exact/>
                  {/* <Route path="/about" component={withTracker(FAQ)} exact /> */}
                  <Route path="/login" component={withTracker(Login)} exact />
                  <Route path="/magiclink" component={withTracker(Magiclink)} exact />
                  <Route component={NotFound} />
              </Switch>
            </StripeProvider>
          </div>
        </HashRouter>
    );
  }
}

Amplify.configure(awsconfig);
ReactGA.initialize(prod ? keys.prod.gaTrackingId : keys.dev.gaTrackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

export default App;
