import React, {Component} from 'react';
import moment from 'moment';
import {Alert} from 'react-bootstrap';

class Countdown extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.updateCountdown = this.updateCountdown.bind(this);
    }

    componentDidMount() {
        const hours = moment().isDST() ? "4" : "5";
        const dayOfWeek = moment.utc().subtract(hours, 'hours').format('dddd');
        const days = dayOfWeek === "Thursday" || dayOfWeek === "Friday"? 4 : dayOfWeek === "Saturday" ? 3 : 2;
        const cutoff = moment.utc().subtract(hours, 'hours').startOf('day').add('1', 'days').add(hours, "hours");
        const deliveryDate = moment.utc().subtract(hours, 'hours').startOf('day').add(days, 'days');
        this.setState({cutoff, now: moment.utc(), deliveryDate, days});

        this.interval = setInterval(this.updateCountdown.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.updateCountdown);
    }

    updateCountdown() {
        let {cutoff, now, deliveryDate} = this.state;
        if(cutoff, now, deliveryDate) {
            const hours = moment().isDST() ? "4" : "5";
            if(moment.utc().startOf('day').add('1', 'day').add(hours, "hours") > cutoff) {
                const dayOfWeek = moment.utc().subtract(hours, 'hours').format('dddd');
                const days = dayOfWeek === "Thursday" || dayOfWeek === "Friday"? 4 : dayOfWeek === "Saturday" ? 3 : 2;
                const cutoff = moment.utc().subtract(hours, 'hours').startOf('day').add('1', 'days').add(hours, "hours");
                const deliveryDate = moment.utc().subtract(hours, 'hours').startOf('day').add(days, 'days');
                this.setState({cutoff, deliveryDate})
            }
            this.setState({now: moment.utc()})
        }
    }
    render() {
        const {cutoff, now, deliveryDate} = this.state;
        const {spaceTop} = this.props;
        if(cutoff && now && deliveryDate) {
            return (
                <Alert variant="warning" id="countdown-alert" className={spaceTop ? 'countdown-space-top' : ''}>{moment.utc(cutoff.diff(now)).format("H:mm:ss")} remaining for delivery by {moment(deliveryDate).format('MMMM Do')}</Alert>
            )
        } else return <div></div>
    }
}

export default Countdown;