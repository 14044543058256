import { connect } from 'react-redux';
import {
    checkObjForNulls,
    getLanding,
    marginForCountdown,
    clusterProductsForCart
} from '../../actions';
import React, { Component } from 'react';
import {Auth} from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { BillingForm } from './Billing-Form';
import { Elements } from 'react-stripe-elements';
import Header from '../Header';
import Footer from '../Footer';
import Countdown from '../Countdown';
import {CartSummary} from './cart-summary';
import images from '../../img/images';
import Feedback from '../Interview/Feedback';

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: {
                currency: "",
                subTotal: "",
                taxTotal: "",
                ultimateTotal: ""
            },
            shippingAddress: undefined,
            options: undefined,
            order: undefined,
            interview: undefined,
            loading: false,
        }

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const { totals } = this.state;
        const products = JSON.parse(sessionStorage.getItem('products'));
        const customer = JSON.parse(sessionStorage.getItem('customer'));
        const {shipping} = customer;
        const shippingAddress = {
            name: shipping.name,
            line1: shipping.address,
            line2: "",
            city: shipping.city,
            zipcode: shipping.zipcode,
            state: shipping.state
        }

        this.setState({shippingAddress, customer})

        Auth.currentAuthenticatedUser().then(data => {
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
        });

        if(products) {
            clusterProductsForCart(products).then(res => {
                const {sorted, total} = res;
                // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
                // if(coupon) {
                //     this.setState({coupon});
                // }
                // totals.ultimateTotal = coupon ? totals.subTotal - (totals.subTotal * coupon.percentDiscount/100) : totals.subTotal;
                
                // if(shippingAddress.city === "Austin") {
                //     totals.subTotal = total;
                //     totals.taxTotal = total * .0825
                //     totals.ultimateTotal = totals.taxTotal + total;
                // } else {
                    totals.ultimateTotal = totals.subTotal = total;
                // }
                this.setState({options: sorted})
            })
        } else {
            this.props.history.push('/checkout');
        }

        if (!this.state.order) {
            const order = JSON.parse(sessionStorage.getItem('order'));
            this.setState({ order, totals })
        }

        const dropoffZipcodes = JSON.parse(sessionStorage.getItem('dropoffZipcodes'));
        if (dropoffZipcodes) this.setState({ dropoffZipcodes })
        else this.props.getLanding();


        const initZipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({initZipcode})

        if(initZipcode) marginForCountdown();
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }
    componentDidUpdate() {
        const { landing } = this.props;
        const { error, validating } = this.props;

        if (error && !this.state.error && !validating) {
            this.setState({ error });
        }

        if (landing && !this.state.dropoffZipcodes) {
            this.setState({ dropoffZipcodes: landing.dropoffZipcodes });
        }
    }

    renderCountdown() {
        if(this.state.initZipcode) return  <Countdown />
    }

    renderMobileCartSummary() {
        const {isMobile, coupon, totals, options} = this.state;
        if(isMobile && totals && options) {
            return (
                <div className="checkout-body space-bottom">
                    <CartSummary
                        coupon={coupon}
                        totals={totals}
                        lineItems={options}
                    />
                </div>
            );   
        }
    }

    render() {
        const { dropoffZipcodes, initZipcode } = this.state;
        if (this.state.options && !this.state.isBilling) {
            const interview = JSON.parse(sessionStorage.getItem('interview'));
            return (
                <div>
                    {this.renderCountdown()}
                    <Header history={this.props.history} spaceTop={initZipcode} />
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.goBack()} className="interview-icon interview-back-arrow" />
                        <div></div>
                    </div>
                    <div className="checkout-container">
                        <div className="checkout-body">
                            <Elements>
                                <BillingForm
                                    getLanding={this.props.getLanding}
                                    totals={this.state.totals}
                                    coupon={this.state.coupon}
                                    history={this.props.history}
                                    items={this.state.options}
                                    interview={interview}
                                    stripe={this.props.stripe}
                                    addresses={this.props.addresses}
                                    error={this.state.error}
                                    order={this.props.order}
                                    dropoffZipcodes={dropoffZipcodes}
                                />
                            </Elements>
                        </div>
                        {this.renderMobileCartSummary()}
                    </div>
                    <Feedback question="/checkout/billing" />
                </div>
            )
        } else {
            return (
                <div>
                    <Footer
                        history={this.props.history}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { landing, gettingLanding, error } = state.order;
    return { landing, gettingLanding, error };
}

export default connect(mapStateToProps, {
    checkObjForNulls,
    getLanding
})(Billing);
