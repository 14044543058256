import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../Style/ordercomplete.css';
import SummaryRow from './SummaryRow';
import moment from 'moment';
import Feedback from './Interview/Feedback';
import {marginForCountdown} from '../actions';
import * as Sentry from '@sentry/browser';
import keys from '../config';
const prod = keys.prod.hostNames.includes(window.location.hostname);

class OrderComplete extends Component {

    constructor (props) {
        super(props);

        this.state = {
            alertVisible: true,
            editPhoneDialogVisible: false
        };

        this.renderComingOrder = this.renderComingOrder.bind(this);
        this.sendGATransaction = this.sendGATransaction.bind(this);
    }

    componentDidMount() {
        const order = JSON.parse(sessionStorage.getItem('stripeOrder'));
        if(order && order.stripeItems) {
            let taxTotal;
            let subtotal = 0;
            order.stripeItems.map(item => {
                if(item.description === 'Sales tax') {
                    taxTotal = parseInt(item.amount)/100;
                } else {
                    subtotal += (item.amount/100)
                }
            })
            this.sendGATransaction(order);
            this.setState({expectedDeliveryDate: order.expectedDeliveryDate, order})
            if(prod) window.fbq('track', 'Purchase', {value: subtotal, currency: 'USD'})
            if(taxTotal) this.setTaxRate(taxTotal);
            this.setState({ taxTotal });
        }

        const products = JSON.parse(sessionStorage.getItem('products'));
        if(!products) {
            this.props.history.push('/');
        } else {
            const selectedProducts = [];
            Object.values(products).map(product => {
                const {selected} = product;
                if(selected.articles) {
                    selectedProducts.push(selected);
                }
            })
    
            this.setState({products: selectedProducts});
        }

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown(true); 

        // const coupon = JSON.parse(localStorage.getItem('sd_coupon'));
        // if(coupon) this.setState({coupon});
    }

    sendGATransaction(order) {
        try {
            const customer = JSON.parse(sessionStorage.getItem('customer'));
            const {stripeItems, orderId} = order;
            const actions = [];
            const {ga} = window;
            let total = 0;
            let tax = 0;
            const productsMap = JSON.parse(sessionStorage.getItem('productsMap'))
            stripeItems.forEach(product => {
                const {parent, amount, groupName, description, quantity, currency, type} = product;
                if(type === 'sku') {
                    const {productType, pricePerArticle} = productsMap[groupName];
                    total += amount/100;
                    actions.push({
                        id: groupName,
                        name: description,
                        price: pricePerArticle/100,
                        quantity,
                        category: productType,
                        currency: currency.toUpperCase(),
                        sku: parent
                    })
                } else if (description === "Sales tax") {
                    tax = amount/100;
                    actions.push({
                        id: description,
                        name: description,
                        price: amount/100,
                        quantity: 1,
                        category: 'tax',
                        currency: currency.toUpperCase()
                    })
                }
            });
            ga('ecommerce:addTransaction', {
                id: orderId,
                affiliation: customer.email,
                revenue: `${total}`,
                tax: `${tax}`,
                currency: 'USD'
            })
            actions.forEach(action => {
                ga('ecommerce:addItem', action);
            })
            ga('ecommerce:send');
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    setTaxRate(taxTotal) {
        const products = JSON.parse(sessionStorage.getItem('options'));

        if(products) {
            let subTotal = 0;
            Object.values(products).map(product => {
                const {selected} = product;
                if(selected.articles) {
                    subTotal += selected.pricePerArticle * selected.articles;
                }
            })
            
            const taxRate = taxTotal/ subTotal;
            this.setState({taxRate});
        }
    }

    renderComingOrder() {
        const {taxTotal, expectedDeliveryDate, products} = this.state;
        if(products) {
            return (
                <SummaryRow
                    first={true}
                    expectedDeliveryDate={expectedDeliveryDate}
                    products={products}
                    taxTotal={taxTotal}
                    coupon={this.state.coupon}
                    // closed={true}
                />
            )
        }
    }

    render() {
        return (
            <div>
                <Header history={this.props.history} />
                <div className="interview-outer">
                    <div className="interview-inner">
                        {this.state.expectedDeliveryDate ? <h2 className="order-complete-header">You're all set!<br/>Your first delivery is arriving {moment.utc(this.state.expectedDeliveryDate).format('MMM Do')}</h2> : 
                            <h2 className="order-complete-header">You're all set!</h2>
                        }
                        <p className="interview-copy">Look out for a text message confirmation. If you have any issues text us back at<br/> <b>(205) 583-6916</b> or email <a href="mailto:care@supplydrop.com" target="_top">care@supplydrop.com</a> and we’ll get any issues resolved.</p>	
                        { this.renderComingOrder() }
                        <div>
                            <div className="flex-center-container space-bottom">
                                <button className="complete-continue-button rounded" onClick={() => this.props.history.push('/dashboard/orders')}>View in Dashboard</button>
                            </div>
                            <div className="interview-copy">Use your Dashboard to adjust your account settings and make any changes to future orders.</div>
                        </div>
                    </div>
                    <Feedback question='order-complete' />
                </div>
                <Footer 
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default OrderComplete;