import React from 'react';
import images from '../img/images';

const ErrorMessage = ({body}) => {
    return (
        <div className="error-message-container">
            <span className="error-message"><i class="fa fa-times-circle"></i> {body}</span>
        </div>
    )
}

export default ErrorMessage;