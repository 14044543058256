import React from 'react';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';
import Counter from '../Counter';
import Countdown from '../../Countdown';
import {marginForCountdown} from '../../../actions';

class Bathrooms extends React.Component {

  	constructor(props, context) {
		super(props, context);

		this.state = {
			show: false,
			error: '',
			bathrooms: 1,
            moreInfoCopy: (
				<div>
					The amount of bathrooms you have helps us calculate what bathroom supplies you’ll need.
				</div>
			)
		};
		
		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.continue = this.continue.bind(this);
		this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.continue();
            }
        }
    }

  	componentDidMount() {
        window.scrollTo(0, 0);

		const family = JSON.parse(sessionStorage.getItem('family'));
		const bathrooms = JSON.parse(sessionStorage.getItem('bathrooms'));
		if(!family){
			this.props.history.push('/start/1');
		} else if(bathrooms) {
			this.setState({bathrooms});

		}
		const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
		this.setState({zipcode})

		if(zipcode) marginForCountdown();
	}

	continue() {
		const {bathrooms} = this.state;
        sessionStorage.setItem('bathrooms', JSON.stringify(bathrooms));
        this.props.history.push('/start/4');
	}

	decrement() {
		let {bathrooms} = this.state;
		if(bathrooms > 1) bathrooms -= 0.5;
		this.setState({ bathrooms })
	}

	increment() {
		let {bathrooms} = this.state;
		bathrooms > 0 ? bathrooms += 0.5 : bathrooms += 1;
		this.setState({ bathrooms })
	}

	renderTopRow() {
        return (
            <div className="interview-nav-container">
				<div className="interview-top-row">
					<img src={images.back_arrow} onClick={() => this.props.history.push('/start/2')} className="interview-icon interview-back-arrow" />
					<img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
				</div>
            </div>
        )
    }

	renderContinue() {
		const {bathrooms} = this.state;
		return (
			<div className="interview-continue-wrapper">
                <button className="interview-continue-button" onClick={this.continue}>Continue</button>
                <p className="interview-enter-text">press <b>enter</b></p>
            </div>
		)
	}

	renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
	}

	renderCountdown() {
		if(this.state.zipcode) return <Countdown spaceTop={true} />
	}

	render() {
		const {bathrooms, zipcode} = this.state;
		const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        return (
            <div>
                <ProgressBar progress={hasKitchen ? 50 : 60} />
				{this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
				{this.renderInfo()}
                <div className="interview-outer">
                    {this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img"><img src={images.bathrooms} alt="Bathrooms" /></div>
                        <p className="interview-copy">How many bathrooms do you have?</p>
						<Counter type="interview" increment={this.increment} decrement={this.decrement} min={1} count={bathrooms} />
                        {this.renderContinue()}
                    </div>
                    <Feedback question="bathrooms" />
                </div>
            </div>			
		);
	}
}

export default Bathrooms;
