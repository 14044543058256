import {
    GET_INTERVIEW_ROUNDER_SUCCESS,
    GET_INTERVIEW_ROUNDER_FAIL,
    GETTING_ROUNDER,
    POST_INTERVIEW_SUCCESS,
    POST_INTERVIEW_FAIL,
    POST_FEEDBACK_FAIL,
    POST_FEEDBACK_SUCCESS,
    GET_PROJECTIONS_FAIL,
    GET_PROJECTIONS_SUCCESS,
    CLEAR_PROJECTIONS,
    CLEAR_INTERVIEWS,
    PUT_INTERVIEW_FAIL,
    PUT_INTERVIEW_SUCCESS,
    PUTTING_INTERVIEW,
    GETTING_USER
} from '../actions/types';

const INITIAL_STATE = {
    interviewPostError: "",
    rounder: undefined,
    rounderError: undefined,
    gettingRounder: undefined,
    interviewPosted: false,
    isAddingPerson: false,
    selectedMember: undefined,
    pets: {
        dog: 0,
        cat: 0,
        other: 0
    },
    persons: [],
    self: undefined,
    feedback: '',
    projections: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POST_INTERVIEW_SUCCESS:
            return { ...state, interviewPosted: true, interviewPostError: ''}
        case POST_INTERVIEW_FAIL:
            return { ...state, interviewPosted: false, interviewPostError: action.error}
        case GETTING_ROUNDER:
            return { ...state, rounderError: '', rounder: undefined, gettingRounder: true }
        case GET_INTERVIEW_ROUNDER_SUCCESS:
            return { ...state, rounderError: undefined, rounder: action.payload, gettingRounder: false };
        case GET_INTERVIEW_ROUNDER_FAIL:
            return { ...state, rounder: undefined, rounderError: action.error, gettingRounder: false };
        case POST_FEEDBACK_FAIL:
            return { ...state, error: action.payload, feedback: ''};
        case POST_FEEDBACK_SUCCESS:
            return { ...state, error: '', feedback: 'success'};
        case GET_PROJECTIONS_FAIL:
            return { ...state, error: action.payload, projections: undefined};
        case GET_PROJECTIONS_SUCCESS:
            return { ...state, error: '', projections: action.payload};
        case CLEAR_PROJECTIONS:
            return { ...state, error: '', projections: undefined};
        case CLEAR_INTERVIEWS:
            return { ...state, postInterviewError: '', interviewPosted: false, putInterviewSuccess: undefined, putInterviewError: undefined};
        case PUT_INTERVIEW_FAIL:
            return { ...state, putInterviewError: action.error, putInterviewSuccess: undefined, puttingInterview: false}
        case PUT_INTERVIEW_SUCCESS:
            return { ...state, putInterviewError: undefined, putInterviewSuccess: action.payload, puttingInterview: false}
        case PUTTING_INTERVIEW:  
        return { ...state, putInterviewError: undefined, putInterviewSuccess: undefined, puttingInterview: true} 
        default:
            return state;
    }
};