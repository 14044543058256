import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import images from '../img/images';
import {Auth} from 'aws-amplify';
import { Alert } from 'react-bootstrap';
import {connect} from 'react-redux';
import '../Style/signin.css';
import Header from './Header';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {validateEmail} from '../actions';
import {getMagicLink, clearCustomerState} from '../actions';
import MoreInfo from './Interview/MoreInfo';
import InputMask from 'react-input-mask';

class SignIn extends Component {
    constructor(props){
        super(props);
        this.state = {
            channel: 'sms',
            moreInfoCopy: (
				<div>
					Passwords are so 2000. You don't have to mess with them anymore.<br/>
                    {/* TODO: Insert explanation of how the magic links works */}
				</div>
			)
        };

        this.requestLink = this.requestLink.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.validateField = this.validateField.bind(this);
        this.formatPhone = this.formatPhone.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo, email, channel} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else if(email && channel) {
                this.requestLink();
            }
        }
    }

    validateField (name, value) {
        try {
            switch (name) {
                case 'email':
                    this.setState({ isEmailInvalid: !validateEmail(value) });
                    break;
                case 'phone':
                    const phone = this.formatPhone(value); 
                    const isPhoneInvalid = !isValidPhoneNumber(phone);
                    this.setState({ isPhoneInvalid, phone });
                    break;
                default:
                    console.error(name, value, 'invalid')
                    break;
            }
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    formatPhone(value) {
        let num = '';
        num = "+1" + value.slice(1,4) + value.slice(6, 9) + value.slice(10,14);
        return num;
    }

    reverseFormatPhone(value) {
        let num = '';
        num = `(${value.slice(2,5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    componentDidUpdate() {
        const {magicLinkErr} = this.props;

        if(magicLinkErr) {
            Sentry.captureException(magicLinkErr);
            console.error(magicLinkErr);
        }
    }

    requestLink() {
        const {email, channel, phone} = this.state;
        if(channel === "email") {
            this.props.getMagicLink(email, '/#/dashboard', channel)
        } else {
            this.props.getMagicLink(null, '/#/dashboard', channel, null, phone)
        }
    }

    renderChannel() {
        const {channel} = this.state;

        return (
            <div>
                <div className="signin-subtext">
                    How would you like to receive your magic link?
                </div>
                <div className="signin-radio-form">
                    <div className="signin-radio-container" onClick={() => this.setState({channel: "sms"})}>
                        <div className={`prediction-modal-radio ${channel === "sms" ? "selected" : ""}`}></div>
                        <label className="prediction-modal-label pointer">Text</label>
                    </div>
                    <div className="signin-radio-container" onClick={() => this.setState({channel: "email"})}>
                        <div className={`prediction-modal-radio ${channel === "email" ? "selected" : ""}`}></div>
                        <label className="prediction-modal-label pointer">Email</label>
                    </div>
                </div>
            </div>
        )
    }

    renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }
    
    renderSentSuccessfully() {
        const {magicLink} = this.props;
        const {channel} = this.state;
        if(magicLink) {
            return (
                <div className="signin-subtext">
                    We've sent a link to your {channel === "sms" ? "phone" : "email"} that will allow you to login.
                    <div><span className="span-link" onClick={this.props.clearCustomerState}>Click here</span> if you need to request a new link.</div>
                </div>
            )
        }
    }

    renderLoading() {
        const {gettingMagicLink} = this.props;
        if(gettingMagicLink) {
            return (
                <div className="signin-subtext">
                    Working our magic...
                </div>
            )
        }
    }

    renderSignUp() {
        const {noUser} = this.props;
        const {email, phone, channel} = this.state;

        if(noUser) {
            return (
                <div className="signin-subtext">
                    Hmm... we don't have <b>{channel === "sms" ? this.reverseFormatPhone(phone) : email}</b> in our system.<br/>
                    Would you like to <span className="span-link" onClick={() => this.props.history.push('/start')}>sign up</span> or <span className="span-link" onClick={this.props.clearCustomerState}>try entering your {channel === "sms" ? "phone" : "email"} again?</span>
                </div>
            )
        }
    }

    renderError() {
        const {magicLink, magicLinkErr} = this.props;
        if(magicLinkErr && !magicLink) {
            return (
                <div className="signin-subtext">
                    We've encountered an error whle creating your login link.
                    <div><span className="span-link" onClick={this.props.clearCustomerState}>Click here</span> to try again.</div>
                </div>
            )
        }
    }

    renderInput() {
        const {channel, email, displayNumber, isPhoneInvalid} = this.state;
        if(channel === "email") {
            return (
                <input
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email address"
                    className="signin-modal-input"
                    onChange={(e) => this.setState({email: e.target.value})}
                />
            )
        } else {
            return (
                <InputMask
                mask="(999) 999-9999"
                value={displayNumber}
                onChange={(e) => this.setState({displayNumber: e.target.value})}
                onBlur={(e) => this.validateField('phone', e.target.value)}
            >
               {() => <input
                    className={`signin-modal-input${!isPhoneInvalid ? '' : ' invalid'}`}
                    type="tel"
                    placeholder="Mobile number"
                />}
            </InputMask>
            )
        }
    }

    renderMain() {
        const {magicLink, magicLinkErr, noUser} = this.props;
        const {errMessage, email, channel, displayNumber} = this.state;
        if(!magicLink && !magicLinkErr && !noUser) {
            return (
                <div>
                    <div>
                        {this.renderInput()}
                    </div>
                    {this.renderChannel()}
                    <Alert variant="warning" className={errMessage ? "signin-modal-alert" : "hide"}>{errMessage}</Alert>
                    <div className="interview-continue-wrapper">
                        <button className="interview-continue-button" disabled={(!email && channel === "email") || (!displayNumber && channel === "sms")} onClick={this.requestLink}>Send Link</button>
                        <p className="interview-enter-text">press <b>enter</b></p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
            <Header history={this.props.history} />
            {this.renderInfo()}
            <div className="interview-outer">
                <div className="interview-nav-container">
                    <div className="interview-top-row signin">
                        <div></div>
                        <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                    </div>
                </div>
                <div className="interview-inner text-center">
                    <div className="interview-header-img"><img src={images.login} alt="Security" /></div>
                    <div className="signin-main-header">Your account</div>
                    {this.renderSentSuccessfully()}
                    {this.renderLoading()}
                    {this.renderMain()}
                    {this.renderError()}
                    {this.renderSignUp()}
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {magicLink, magicLinkErr, noUser, gettingMagicLink} = state.user;
    return {magicLink, magicLinkErr, noUser, gettingMagicLink};
}

export default connect(mapStateToProps, {
    getMagicLink,
    clearCustomerState
})(SignIn);