import React from 'react';

const ProgressBar = ({progress}) => {

    const styles = {
        width: `${progress}%`
    }

    return (
        <div className="progress-bar animated fadeInLeft">
            <div className="progress" style={styles}></div>
        </div>
    )
}

export default ProgressBar;