import React, {Component} from 'react';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import Counter from '../Counter';
import MoreInfo from '../MoreInfo';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Cleaning extends Component {
    constructor(props) {
        super(props);

        this.state = {
            frequency: 1,
            moreInfoCopy: (
                <div>
                    Some of us are clean freaks and others just tidy up from time to time.
                    Help us understand how much you clean your home, so we can make sure to send you the right amount of cleaning supplies.
                </div>
            )
        };

        this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.continue = this.continue.bind(this);
		this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.continue();
            }
        }
    }

  	componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({isModal: this.props.isModal});

        const cookingFrequency = JSON.parse(sessionStorage.getItem('cookingFrequency'));
        if(cookingFrequency === undefined) this.props.history.push('/kitchen/1');

        const dishwashing = JSON.parse(sessionStorage.getItem('dishwashing'));
        if(!dishwashing) {
            this.props.history.push('/kitchen/2');
        } else if(!dishwashing.frequency === undefined) {
            this.props.history.push('/kitchen/2');
        } else if(!dishwashing.products) {
            this.props.history.push('/kitchen/3');
        } else {
            this.setState({dishwashing});
        }

		const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
	}

	continue() {
        const {frequency, isModal} = this.state;
        const housekeeping = frequency
        sessionStorage.setItem('housekeeping', JSON.stringify(housekeeping));

        if(isModal) this.props.next({housekeeping})
        else this.props.history.push('/prediction');
	}

	decrement() {
		let {frequency} = this.state;
		if(frequency > 0) frequency--;
		this.setState({ frequency })
	}

	increment() {
		let {frequency} = this.state;
		frequency++;
		this.setState({ frequency })
	}

	renderTopRow() {
        const {dishwashing, isModal} = this.state;
        if(!isModal) {
            return (
                <div className="interview-nav-container">
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.push('/kitchen/2')} className="interview-icon interview-back-arrow" />
                        <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                    </div>
                </div>
            )
        }
    }

	renderContinue() {
        const {isModal} = this.state;
        if(isModal) {
            return (
                <div className="interview-modal-button-row">
                    <button className="dashboard-btn dashboard-submit white" onClick={this.props.back}>Back</button>
                    <button className="dashboard-btn dashboard-save" onClick={this.continue}>Continue</button>
                </div>
            )
        } else {
            return (
                <div className="interview-continue-wrapper">
                    <button className="interview-continue-button" onClick={this.continue}>Continue</button>
                    <p className="interview-enter-text">press <b>enter</b></p>
                </div>
            )
        }
	}

	renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }
    
    renderHeader() {
        const { isModal, zipcode  } = this.state;
        if (!isModal) {
            return <Header history={this.props.history} spaceTopLg={zipcode} />
        }
    }

    renderProgressBar() {
        const { isModal } = this.state;
        if (!isModal) {
            return <ProgressBar progress={90} />
        }
    }

    renderFeedback() {
        const { isModal } = this.state;
        if (!isModal) return <Feedback question="housekeeping" />
    }

    renderCountdown() {
        if(this.state.zipcode && !this.state.isModal) return <Countdown spaceTop={true} />
    }

	render() {
        const {frequency, isModal} = this.state;
		return(
			<div>
				{this.renderProgressBar()}
                {this.renderCountdown()}
                {this.renderHeader()}
				{this.renderInfo()}
                <div className={isModal ? "interview-modal-outer" : "interview-outer"}>
                    {this.renderTopRow()}
                    <div className={`${isModal ? "interview-modal-inner" : "interview-inner"} fadeInRight animated-fast text-center`}>
                        <div className="interview-header-img"><img src={images.housekeeping} alt="Broom" /></div>
                        <p className="interview-copy">How many times a month do you clean your home?</p>
						<Counter type="interview" min={0} max={31} count={frequency} increment={this.increment} decrement={this.decrement} />
                        {this.renderContinue()}
                    </div>
                    {this.renderFeedback()}
                </div>
            </div>			
		);
	}
}

export default Cleaning;