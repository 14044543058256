import React, {Component} from 'react';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import MoreInfo from '../MoreInfo';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Countdown from '../../Countdown';
import {Alert} from 'react-bootstrap';
import { marginForCountdown } from '../../../actions';

class StaysHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isolated: false,
            moreInfoCopy: (
                <div>
                    The amount of time your family spends in the house directly affects how many supplies you use.
                </div>
            )
        };

        this.toggleStaysHome = this.toggleStaysHome.bind(this);
        this.continue = this.continue.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const {showInfo} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if(showInfo) {
                this.setState({showInfo: false});
            } else {
                this.continue();
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const family = JSON.parse(sessionStorage.getItem('family'));
        if(!family){
            this.props.history.push('/start/1');
        } else {
            this.setState({family});
        }

        const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    continue() {
        const {family, isolated} = this.state;

        family.forEach(member => {
            member.isolated = !!isolated;
        })

        sessionStorage.setItem('family', JSON.stringify(family));
        this.props.history.push('/start/3');
    }

    toggleStaysHome(id) {
        const {family} = this.state;
        const member = family.filter(m => m.id === id)[0];
        const newFamily = family.filter(m => m.id !== id);
        member.staysHome = !member.staysHome;
        
        newFamily.push(member);
        newFamily.sort((a, b) => {
            return a.id - b.id;
        });

        this.setState({family: newFamily});
    }

    renderTopRow() {
        return (
            <div className="interview-nav-container">
                <div className="interview-top-row">
                    <img src={images.back_arrow} onClick={() => this.props.history.push('/start/1')} className="interview-icon interview-back-arrow" />
                    <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" />
                </div>
            </div>
        )
    }

    renderFamily() {
        const {family} = this.state;
        if(family) {
            const rows = [];
            family.map(member => {
                rows.push(
                    <div className={`interview-household-img ${member.staysHome ? 'active' : ''}`} onClick={() => this.toggleStaysHome(member.id)}><img src={images[`${member.gender.toLowerCase()}_${member.type.toLowerCase()}`]} className="household-icon" /></div>
                )
            })
            return (
                <div className="household-icons-row">
                    {rows}
                </div>

            )
        }
    }

    renderInfo() {
        const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <MoreInfo close={() => this.setState({showInfo: false})} copy={moreInfoCopy} />
            )
        }
    }

    renderContinue() {
        return (
            <div className="interview-continue-wrapper">
                <button className="interview-continue-button" onClick={this.continue}>Continue</button>
                <p className="interview-enter-text">press <b>enter</b></p>
            </div>
        );
    }

    renderCountdown() {
        if(this.state.zipcode) return <Countdown spaceTop={true} />
    }

    renderCovidBox() {
        const {isolated} = this.state;
        return (
            <Alert className="covid-alert">
                <div className="checkbox-container">
                    <div className="checkbox" onClick={() => this.setState({isolated: !isolated})}>
                        <img src={images.checkmark} alt="checkbox" className={isolated ? "" : "hide"} />
                    </div>
                </div>
                <div>If you are currently staying home due to COVID-19 please check this box and continue to answer the question as you would under normal circumstances.</div>
            </Alert>
        )
    }

    render() {
        const hasKitchen = JSON.parse(sessionStorage.getItem('hasKitchen'));
        const {zipcode} = this.state;
        return (
            <div>
                <ProgressBar progress={hasKitchen ? 40 : 50} />
                {this.renderCountdown()}
                <Header history={this.props.history} spaceTopLg={zipcode} />
                {this.renderInfo()}
                <div className="interview-outer">
                    {this.renderTopRow()}
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        <div className="interview-header-img"><img src={images.house} alt="House" /></div>
                        <p className="interview-copy">Who leaves for work or school most<br/>days of the week?</p>
                        {this.renderCovidBox()}
                        {this.renderFamily()}
                        {this.renderContinue()}
                    </div>
                    <Feedback question="staysHome" />
                </div>
            </div>
        )
    }
}

export default StaysHome;