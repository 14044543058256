import React, {Fragment} from 'react';
import * as Sentry from '@sentry/browser';
import FontAwesome from "react-fontawesome";
import { CartSummary } from "./cart-summary";
import { Option } from "@nozzlegear/railway";
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
    validateCheckoutForm,
    validateCityAndNames,
    validateEmail,
    validatePassword
} from '../../actions';
import {Col, Row, Alert} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import InputMask from 'react-input-mask';
import DocDialog from "../DocModal";
import images from '../../img/images';
import '../../Style/checkout.css';
import '../../Style/interview.css';
export * from "./cart-summary";
export * from "./types";

class CustomerForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRate: Option.ofNone(),
            isEmailValid: true,
            isFirstNameValid: true,
            isLastNameValid: true,
            isPasswordValid: true,
            isPhoneValid: true
        }
        this.agree = this.agree.bind(this);
        this.showTerms = this.showTerms.bind(this);
        this.hideTerms = this.hideTerms.bind(this);
        this.updateStateFromEvent = this.updateStateFromEvent.bind(this);
        this.signIn = this.signIn.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.verify = this.verify.bind(this);
        this.requestCode = this.requestCode.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.continue = this.continue.bind(this);
    }

    componentDidMount() {
        Auth.configure({
            authenticationFlowType: 'USER_SRP_AUTH'
        });
        const aptCampaignShipping = JSON.parse(sessionStorage.getItem('aptCampaignShipping'));
        if(aptCampaignShipping) {
            const {firstName, lastName} = aptCampaignShipping;
            this.setState({firstName, lastName});
        }
        window.scrollTo(0, 0);
        this.handleResize();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const {isMobile} = this.state;
        if(window.innerWidth < 992 && !isMobile) {
            this.setState({isMobile: true})
        }
        if(window.innerWidth >= 991 && isMobile) {
            this.setState({isMobile: false})
        }
    }

    componentDidUpdate() {
        if(this.props.interviewPostError && !this.state.interviewPostError) {
            this.setState({interviewPostError: this.props.interviewPostError, unconfirmed: false, userExists: false});
        }
    }
    
    updateStateFromEvent(name, value) {
        try {
            this.setState({[name]: value, showErrors: false, errMessage: ''});
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    handleKeydown(e) {
        if(e.key && e.key.toLowerCase() === 'enter') {
            this.verify()
        }
    }

    renderInterviewError() {
        const {interviewPostError} = this.state;

        if(interviewPostError) {
            return (
                <Alert variant="danger">Something went wrong. Please try again later.</Alert>
            )
        }
    }

    async verify() {
        const {
            email,
            otp,
            password,
            firstName,
            lastName,
            phone
            } = this.state;
        this.setState({ verifying: true });
        try {
            Auth.confirmSignUp(email.toLowerCase(), otp, {
                forceAliasCreation: true
            }).then(() => {
                Auth.signIn(email.toLowerCase(), password).then(() => {
                    const customer = {
                        email: email.toLowerCase(),
                        firstName,
                        lastName,
                        phone
                    };
                    let interview = JSON.parse(sessionStorage.getItem('interview'));
                    interview.phone = phone;
                    const referrer = sessionStorage.getItem('referrer') ? JSON.parse(sessionStorage.getItem('referrer')) : null;
                    const campaignCode = sessionStorage.getItem('campaignCode') ? JSON.parse(sessionStorage.getItem('campaignCode')) : null;
                    if(campaignCode) interview.campaignCode = campaignCode;
                    if(referrer) interview.referrer = referrer;
                    this.props.postInterview(interview, this.props.unauthedId);
                    sessionStorage.setItem('customer', JSON.stringify(customer));
                    this.props.history.push('/checkout/shipping');
                }).catch(signInErr => {
                    this.setState({ errMessage: signInErr.message ? signInErr.message : signInErr, verifying: false });
                    Sentry.captureException(signInErr);
                    console.error(signInErr);
                })
            }).catch(otpErr => {
                if(otpErr.message === "Invalid verification code provided, please try again.") {
                    this.setState({ errMessage: "Code does not match. Please try again or request new code.", verifying: false });
                } else {
                    this.setState({ errMessage: "Something went wrong. Please contact care@supplydrop.com for assistance.", verifying: false });
                }
                console.error(otpErr);
            })  
        } catch (error) {
            Sentry.captureException(error);
            console.error(error);
        }
    }

    async continue() {
        const {agreed} = this.state;
        const invalidFields = await this.validateForm();
        if(!invalidFields && agreed) {
            this.setToVerification();
        } else {
            this.setState({ invalidFields, showErrors: true })
        }
    }

    async validateForm() {
        let {
            email,
            displayNumber,
            password,
            firstName,
            lastName,
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isPasswordValid,
            isPhoneValid
        } = this.state;
        
        if (isEmailValid) isEmailValid = email;
        if (isFirstNameValid) isFirstNameValid = firstName;
        if (isLastNameValid) isLastNameValid = lastName;
        if (isPasswordValid) isPasswordValid = password;
        if (isPhoneValid) isPhoneValid = displayNumber;
        

        const fields = [
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isPasswordValid,
            isPhoneValid
        ];
        this.setState({
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isPasswordValid,
            isPhoneValid
        })
        return validateCheckoutForm(fields);
    }

    async setToVerification() {
        const {            
            email,
            password,
            phone,
            firstName,
            lastName
        } = this.state;
        try {
            await Auth.signUp({
                username: email.toLowerCase(),
                password: password,
                attributes: {
                    email: email.toLowerCase(),
                    phone_number: phone,
                    given_name: firstName,
                    family_name: lastName
                }
            }).then(data => {
                this.setState({unconfirmed: true});
            }).catch(err => {
                if(err.code === "UsernameExistsException") {
                    Auth.signIn(email.toLowerCase(), password).then(() => {
                        const customer = {
                            email: email.toLowerCase(),
                            firstName,
                            lastName,
                            phone
                        };
                        let interview = JSON.parse(sessionStorage.getItem('interview'));
                        const campaignCode = JSON.parse(sessionStorage.getItem('campaignCode'));
                        interview.phone = phone;
                        if(campaignCode) interview.campaignCode = campaignCode;
                        this.props.postInterview(interview, this.props.unauthedId);
                        sessionStorage.setItem('customer', JSON.stringify(customer));
                        this.props.history.push('/checkout/shipping');
                    }).catch(async error => {
                        console.error(error);
                        if(error.code === "UserNotConfirmedException") {
                            await Auth.resendSignUp(email.toLowerCase())
                            this.setState({ unconfirmed: true});
                        } else {
                            this.setState({userExists: true})
                        }
                    })
                } else {
                    Sentry.captureException(err);
                    console.error(err)
                    this.setState({ errMessage: err.message, verifying: false })
                }
            })
        } catch (err) {
            Sentry.captureException(err);
            err.code ? this.setState({ signUpError: err.code }) :
                console.error(err)
        }    
    }    

    agree() {
        const {agreed} = this.state;
        this.setState({agreed: !agreed, showErrors: false})
    }

    showTerms() {
        this.setState({ showTerms: true})
    }

    hideTerms() {
        this.setState({ showTerms: false })
    }

    validateField (name, value) {
        try {
            switch (name) {
                case 'firstName':
                    this.setState({ isFirstNameValid: validateCityAndNames(value) });
                    break;
                case 'lastName':
                        this.setState({ isLastNameValid: validateCityAndNames(value) });
                        break;
                case 'email':
                    this.setState({ isEmailValid: validateEmail(value) });
                    break;
                case 'password':
                    this.setState({ isPasswordValid: validatePassword(value) });
                    break;
                case 'phone':
                    const phone = this.formatPhone(value); 
                    const isPhoneValid = isValidPhoneNumber(phone);
                    this.setState({ isPhoneValid, phone });
                    break;
                default:
                    console.error(name, value, 'invalid')
                    break;
            }
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    formatPhone(value) {
        let num = '';
        num = "+1" + value.slice(1,4) + value.slice(6, 9) + value.slice(10,14);
        return num;
    }

    async signIn() {
        const {email, firstName, lastName, password, phone} = this.state;
        this.setState({ verifying: true });
        try {
            Auth.signIn(email.toLowerCase(), password).then(() => {
                const customer = {
                    email,
                    firstName,
                    lastName,
                    phone
                };
                let interview = JSON.parse(sessionStorage.getItem('interview'));
                const campaignCode = sessionStorage.getItem('campaignCode') ? JSON.parse(sessionStorage.getItem('campaignCode')) : null;
                const referrer = sessionStorage.getItem('referrer') ? JSON.parse(sessionStorage.getItem('referrer')) : null;
                interview.phone = phone;
                if(campaignCode) interview.campaignCode = campaignCode;
                if(referrer) interview.referrer = referrer;
                this.props.postInterview(interview, this.props.unauthedId);
                sessionStorage.setItem('customer', JSON.stringify(customer));
                this.props.history.push('/checkout/shipping');
            }).catch(err => {
                if(err.code === "UserNotConfirmedException") {
                    Auth.resendSignUp(email.toLowerCase())
                        .then(() => {
                            this.setState({ unconfirmed: true, verifying: false});
                        }).catch(e => {
                            Sentry.captureException(err);
                            console.error('failed with errror ', e)
                            this.setState({errMessage: e.message, verifying: false})
                        });

                    } else if(err.message === "Incorrect username or password." || err.message === "Password attempts exceeded") {
                        this.setState({errMessage: err.message, verifying: false});
                    } else {
                        Sentry.captureException(err);
                        console.error('failed with errror ', err)
                        this.setState({errMessage: "Something went wrong. Please contact care@supplydrop.com for assistance.", verifying: false})
                    }
            })
        } catch (error) {
            this.setState({error, verifying: false});
        }
    }

    reverseFormatPhone(value) {
        let num = '';
        num = `(${value.slice(2,5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    }

    requestCode() {
        const {email} = this.state;
        if(email) {
          Auth.resendSignUp(email.toLowerCase())
            .then(() => {
              this.setState({errMessage: "", sentNewCode: true})
              setTimeout(() => {
                  this.setState({sentNewCode: false})
              }, 3000)
            }).catch(e => {
                if(e.message === "User cannot be confirmed. Current status is CONFIRMED") {
                    this.setState({userExists: true, unconfirmed: false});
                } else {
                    Sentry.captureException(e);
                    console.error('failed with errror ', e)
                    this.setState({errorMessage: e.message})
                }
            });
    
        }
      }

    renderTerms() {
        const {showTerms} = this.state;
        if(showTerms) {
            return (
                <DocDialog doc={'Terms_of_Use'} hide={this.hideTerms}/>
            )
        }
    }

    renderCustomerInformation() {
        const {
            email,
            displayNumber,
            password,
            firstName,
            lastName,
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isPasswordValid,
            isPhoneValid,
            error,
            agreed
        } = this.state;
        return (
            <section>
                <form>
                    <div className="form-group">
                        <div className="input-wrapper flex-row flex-wrap">
                            <div className="modal-input-container">
                                <label className="modal-label">First Name</label>
                                <input
                                    className={`modal-input${isFirstNameValid ? '' : ' invalid'}`}
                                    type="text"
                                    id="first-name"
                                    value={firstName}
                                    placeholder="Jane"
                                    onChange={(e) => this.updateStateFromEvent('firstName', e.target.value)}
                                    onBlur={(e) => this.validateField('firstName', e.target.value)}
                                />
                            </div>
                            <div className="modal-input-container">
                                <label className="modal-label">Last Name</label>
                                <input
                                    className={`modal-input ${isLastNameValid ? '' : ' invalid'}`}
                                    type="text"
                                    id="last-name"
                                    placeholder="Smith"
                                    value={lastName}
                                    onChange={(e) => this.updateStateFromEvent('lastName', e.target.value)}
                                    onBlur={(e) => this.validateField('lastName', e.target.value)}
                                />
                            </div>
                            <div className="modal-input-container">
                                <label className="modal-label">Email</label>
                                <input
                                    className={`modal-input${isEmailValid ? '' : ' invalid'}`}
                                    type="email"
                                    placeholder="jane@smith.com"
                                    id="email"
                                    value={email}
                                    onChange={(e) => this.updateStateFromEvent('email', e.target.value)}
                                    onBlur={(e) => this.validateField('email', e.target.value)}
                                />
                            </div>
                            <div className="modal-input-container">
                            <label className="modal-label">Mobile Number</label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={displayNumber}
                                    onChange={(e) => this.updateStateFromEvent('displayNumber', e.target.value)}
                                    onBlur={(e) => this.validateField('phone', e.target.value)}
                                >
                                   {() => <input
                                        className={`modal-input${isPhoneValid ? '' : ' invalid'}`}
                                        placeholder="(512) 555-1234"
                                        id="phone"
                                        type="tel"
                                    />}
                                </InputMask>
                            </div>
                            <div className="modal-input-container">
                                <label className="modal-label">Password</label>
                                <input
                                    className={`modal-input${isPasswordValid ? '' : ' invalid'}`}
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => this.updateStateFromEvent('password', e.target.value)}
                                    onBlur={(e) => this.validateField('password', e.target.value)}
                                />
                                <div className="mobile-note">Must be between 8-20 characters with at least one captial letter, one lowercase and a number</div>
                                <p className="mobile-note space-top">*You will receive occasional <b>text messages</b> from us to help tune your orders. Unsubscribe at any time (although that will limit the magic of our service).</p>
                            </div>
                        </div>
                    </div>
                    <div className="checkbox-container">
                        <div className="checkbox" onClick={this.agree}>
                            <img src={images.checkmark} alt="checkbox" className={agreed ? "" : "hide"} />
                        </div>
                        <label className="terms-text">I have read and agree to the <span id="terms-link" onClick={this.showTerms}>Terms of Use.</span></label>
                    </div>    
                </form>
                {this.renderErrorMessage()}
            </section>
        )
    }

    renderErrorMessage() {
        const { 
            invalidFields,
            isPasswordValid,
            isEmailValid,
            isFirstNameValid,
            isLastNameValid,
            isPhoneValid,
            showErrors,
            agreed
        } = this.state;
        const {signUpError} = this.props;
        if(showErrors) {
            if(signUpError) {
                if(signUpError === 'An account with the given email already exists.'
                    && this.state.isEmailValid) {
                        this.setState({isEmailValid: false})
                    }
                return (
                    <div className="alert alert-danger">{signUpError}</div>
                )
            } else if (invalidFields) {
                let errors = 0;
                let validations = [
                    isPasswordValid,
                    isEmailValid,
                    isFirstNameValid,
                    isLastNameValid,
                    isPhoneValid
                ]
                validations.forEach(v => {
                    if(!v) errors++
                })
                if(errors > 1) {
                    return (
                        <div className="alert alert-danger">Please fill in all required fields</div>
                    )
                } else {
                    if(!isPasswordValid) {
                        return (
                            <div className="alert alert-danger">Password does not match the necessary criteria</div>
                        )
                    } else if (!isPhoneValid) {
                        return (
                            <div className="alert alert-danger">Please enter a valid phone number</div>
                        )
                    } else if (!isFirstNameValid) {
                        return (
                            <div className="alert alert-danger">Please enter first name</div>
                        )
                    } else if (!isLastNameValid) {
                        return (
                            <div className="alert alert-danger">Please enter last name</div>
                        )
                    } else {
                        return (
                            <div className="alert alert-danger">Please enter a valid email</div>
                        )
                    }
                }
            } else if(!agreed) {
                return (
                    <Alert variant="warning">Please read and agree to terms of use before proceeding.</Alert>
                )
            }
        }
    }    

    renderDesktopCartSummary() {
        const {isMobile} = this.state;
        if(!isMobile) {
            return (
                <div className="desktop-cart-summary">
                    <CartSummary
                        coupon={this.props.coupon}
                        totals={this.props.totals}
                        lineItems={this.props.items}
                    />
                </div>
            );   
        }
    }

    renderSignIn() {
        const {
            userExists,
            email,
            password,
            isEmailValid,
            isPasswordValid,
            unconfirmed,
            credentialsFailed,
            otp,
            errMessage,
            verifying,
            sentNewCode
        } = this.state;
        if(unconfirmed) {
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal small">
                        <img onClick={() => this.setState({unconfirmed: false})} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                        <div className="signin-modal-tab-container">
                            <div className="cancel-modal-body">
                                <p className="modal-title">
                                    Verification
                                </p>
                                <hr />
                                <div className="prediction-modal-form">
                                        <p className="full-color">Enter the code you received via text message</p>
                                        <p className={errMessage ? "invalid" : "hide"}>{errMessage}</p>
                                        <div className="input-wrapper">
                                            <div className="modal-input-container">
                                                <label className="modal-input-label">Code</label>
                                                <input
                                                    name="otp"
                                                    className={!credentialsFailed ? "signin-input fullsize-input" : "invalid-input fullsize-input"}
                                                    value={otp}
                                                    onChange={(e) => this.updateStateFromEvent('otp', e.target.value)}
                                                    onKeyDown={this.handleKeydown}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="flex-row button-wrapper">
                            <button className="dashboard-btn dashboard-submit white" onClick={() => this.setState({unconfirmed: false})}>Cancel</button>
                            <button disabled={verifying} className="dashboard-btn dashboard-submit" onClick={this.verify}><span className={verifying ? '' : 'hide'}><FontAwesome
                                key={"placing-order-spinner"}
                                name="spinner"
                                className="marRight5"
                                spin
                            /></span>Sign In</button>
                        </div>
                        <div className="sd-modal-footer">
                            <p className="flex-col-left no-margin full-color">
                                Didn't receive a confirmation code?<br/>
                                <span id="request-code" onClick={this.requestCode}>Request new code.</span><span className={sentNewCode ? "green" : "hide"}> A new code has been sent!</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            )
        } else if(userExists) {
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={() => this.setState({userExists: false})} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                            <div className="signin-modal-tab-container">
                                <div className="cancel-modal-body">
                                    <p className="modal-title">
                                        Sign In
                                    </p>
                                    <hr />
                                    <div className="prediction-modal-form">
                                            <p className="full-color">Username already exists, did you mean to sign in?</p>
                                            <p className={errMessage ? "invalid" : "hide"}>{errMessage}</p>
                                            <div className="input-wrapper flex-row flex-wrap">
                                                <div className="modal-input-container">
                                                    <label className="modal-input-label">Email</label>
                                                    <input
                                                        className={`modal-input${isEmailValid ? "" : "invalid-input"}`}
                                                        type="email"
                                                        value={email}
                                                        onChange={(e) => this.updateStateFromEvent('email', e.target.value)}
                                                        onBlur={(e) => this.validateField('email', e.target.value)}
                                                    />
                                                </div>
                                                <div className="modal-input-container">
                                                    <label className="modal-input-label">Password</label>
                                                    <input
                                                        className={`modal-input${isPasswordValid ? "" : "invalid-input"}`}
                                                        type="password"
                                                        value={password}
                                                        onChange={(e) => this.updateStateFromEvent('password', e.target.value)}
                                                        onBlur={(e) => this.validateField('password', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="cancel-modal-button-row">
                                <button className="dashboard-btn dashboard-submit white" onClick={() => this.setState({userExists: false})}>Cancel</button>
                                <button className="dashboard-btn dashboard-submit" disabled={verifying} onClick={this.signIn}><span className={verifying ? '' : 'hide'}><FontAwesome
                                    key={"placing-order-spinner"}
                                    name="spinner"
                                    className="marRight5"
                                    spin
                                /></span>Sign In</button>
                            </div>
                        </div>
                    </div>
                </div>               
            )
        }
    }

    render() {        
        return (
            <Fragment>
                <div className="checkout-main">
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span className="checkout-stage-name">Account</span>
                    </div>
                    {this.renderTerms()}
                    {this.renderCustomerInformation()}
                    {this.renderSignIn()}
                    {this.renderInterviewError()}
                    <button className="dashboard-btn dashboard-save cta" onClick={this.continue}>Continue</button>
                </div>
                {this.renderDesktopCartSummary()}
            </Fragment>
        )
    }
}

export default CustomerForm;