import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import images from '../../img/images';
import { Auth } from 'aws-amplify';
import Feedback from '../Interview/Feedback';

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.logout = this.logout.bind(this);
        this.toggleList = this.toggleList.bind(this);
    }

    componentDidMount() {
        switch(window.location.hash) {
            case "#/dashboard":
                this.setState({upcoming: true});
                break;
            case "#/dashboard/orders":
                this.setState({pastOrders: true});
                break;
            case "#/dashboard/account":
                this.setState({account: true});
                break;
            default:
                break;
        }
    }

    logout() {
        Auth.signOut().then(() => {
            this.props.history.push('/');
        })
    }

    toggleList() {
        const {showList} = this.state;
        this.setState({showList: !showList})
    }

    renderFeedback() {
        const {showFeedback} = this.state;
        return <Feedback showFeedback={showFeedback} dashboard={true} close={() => this.setState({showFeedback: false})} />
    }

    render() {
        const {
            showList,
            upcoming,
            account,
            pastOrders
        } = this.state;
        return (
            <div id="menu-container">
                <div id="menu-container-desktop">
                    {this.renderFeedback()}
                    <Row>
                        <img src={images.logo_beta} className="pointer" id="menu-logo-desktop" onClick={() => this.props.history.push('/')}/>
                    </Row>
                    <nav className="menu-nav-container container">
                        <Row className="menu-nav-row" onClick={upcoming ? "" : () => this.props.history.push('/dashboard')}>
                            <div className="menu-icon" id="menu-home-icon"></div>
                            <a className="row menu-nav-link">Home</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={pastOrders ? "" : () => this.props.history.push('/dashboard/orders')}>
                            <div className="menu-icon" id="menu-parachute-icon"></div>
                            <a className="row menu-nav-link">Order History</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={account ? "" :() => this.props.history.push('/dashboard/account')}>
                            <div className="menu-icon" id="menu-edit-icon"></div>
                            <a className="row menu-nav-link">My Account</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={() => this.setState({showFeedback: true})}>
                            <div className="menu-icon" id="menu-feedback-icon">[+]</div>
                            <a className="row menu-nav-link">Give Feedback</a>
                        </Row>
                        <hr />
                        <Row className="menu-nav-row">
                            <div className="menu-icon" id="menu-logout-icon"></div>
                            <a className="row menu-nav-link" onClick={this.logout}>Logout</a>
                        </Row>
                    </nav>
                </div>
                <div id="menu-container-mobile">
                    {this.renderFeedback()}
                    <div id="menu-mobile-header">
                        <img src={images.logo_beta} className="pointer" id="menu-logo-mobile" onClick={() => this.props.history.push('/')}/>
                        <div id="menu-hamburger-container"><i id="menu-hamburger" className="fa fas fa-bars" onClick={this.toggleList}></i></div>
                    </div>
                    <div className={`menu-mobile-list ${showList ? "" : "hide"}`}>
                        <Row className="menu-nav-row" onClick={upcoming ? this.toggleList : () => this.props.history.push('/dashboard')}>
                            <div className="menu-icon" id="menu-home-icon"></div>
                            <a className="row menu-nav-link">Home</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={pastOrders ? this.toggleList : () => this.props.history.push('/dashboard/orders')}>
                            <div className="menu-icon" id="menu-parachute-icon"></div>
                            <a className="row menu-nav-link">Order History</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={account ? this.toggleList : () => this.props.history.push('/dashboard/account')}>
                            <div className="menu-icon" id="menu-edit-icon"></div>
                            <a className="row menu-nav-link">My Account</a>
                        </Row>
                        <Row className="menu-nav-row" onClick={() => this.setState({showFeedback: true})}>
                            <div className="menu-icon" id="menu-feedback-icon">[+]</div>
                            <a className="row menu-nav-link">Give Feedback</a>
                        </Row>
                        <hr />
                        <Row onClick={this.logout} className="menu-nav-row">
                            <div className="menu-icon" id="menu-logout-icon"></div>
                            <a className="row menu-nav-link">Logout</a>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;