import React, {Component, Fragment} from 'react';
import * as Sentry from '@sentry/browser';
import {Auth} from 'aws-amplify';
import { connect } from 'react-redux';
import Welcome from './Welcome';
import Loading from './Loading';
import Saving from './Saving';
import AddProducts from './AddProducts';
import Footer from '../Footer';
import { 
    getUser,
    getManifest,
    putManifest,
    clearOrdersState,
    clearCustomerState,
    getProductGroups,
    formatType,
    formatInterview,
    putInterview,
    clearInterviews
} from '../../actions';
import Menu from './Menu';
import Order from '../Order';
import '../../Style/dashboard.css';
import ProductDetail from '../Interview/ProductDetail';
import {Alert} from 'react-bootstrap';
import images from '../../img/images';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orderGroups: {},
            productChanges: {},
            quantityChanges: {},
            subtotal: 0,
            removedCount: 0
        };

        this.setOptions = this.setOptions.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.confirmChanges = this.confirmChanges.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.showDescription = this.showDescription.bind(this);
        this.setProductsMap = this.setProductsMap.bind(this);
        this.submitProductChange = this.submitProductChange.bind(this);
        this.validateQuantityModal = this.validateQuantityModal.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.addProducts = this.addProducts.bind(this);
        this.updateInterview = this.updateInterview.bind(this);
        this.setProductToRemove = this.setProductToRemove.bind(this);
        this.setRemovalPeriod = this.setRemovalPeriod.bind(this);
        this.setSubtotal = this.setSubtotal.bind(this);
        this.cancelProductRemoval = this.cancelProductRemoval.bind(this);
        this.clearLimits = this.clearLimits.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then(data => {
            Sentry.addBreadcrumb({
                category: "username",
                message: `User: ${data.username}`,
                level: Sentry.Severity.Info
            })
            this.setState({signedIn: true})
            this.props.getProductGroups();
            this.props.getUser();
        }).catch(err => {
            // check for query params
            let params = this.props.location.search;

            if(params) {
                params = JSON.parse(('{"' + decodeURI(params.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'));
                const {u, c, s} = params;
                
                if(u) {
                    Auth.configure({
                        authenticationFlowType: 'CUSTOM_AUTH'
                    });
                    Auth.signIn(u).then(user => {
                        Auth
                        .sendCustomChallengeAnswer(user, c)
                        .then(() => {
                            this.setState({signedIn: true})
                            this.props.getProductGroups();
                            this.props.getUser();
                        }).catch(err => {
                            this.setState({codeExpired: true})
                            Sentry.captureException(err);
                            console.error(err);
                            this.props.history.push(`/magiclink?u=${u}&s=${s}`)
                        })
                    }).catch(error => {
                        console.error(error);
                        this.props.history.push(`/magiclink?u=${u}&s=${s}`)
                    })
                } else {
                    this.props.history.push('/login');
                }
            } else {
                this.props.history.push('/login');
            }
        })
    }

    componentDidUpdate() {
        const {
            user,
            putManifestError,
            updatingManifest,
            putManifestSuccess,
            getUserError,
            productGroups,
            putInterviewError,
            putInterviewSuccess
        } = this.props;
        const {customer, productsErr} = this.state;

        const {loading, productsMap, products} = this.state;

        if(user && !customer) {
            const {interview, error} = formatInterview(user.customer.interview);
            if (error) {
                Sentry.captureException(error);
                console.error(error)
                this.setState({interview: user.customer.interview, customer: user.customer, manifest: user.manifest})
            }  else {
                this.setState({interview, customer: user.customer, manifest: user.manifest});
            }
            
            if(user.customer.shipping.state === "TX") {
                this.setState({isTax: true})
            }
            if(productsMap && !productsErr) {
                this.setOptions(user.roundedProducts.productSets, user.manifest.products, productsMap);
            }
        }

        if(user && customer && productsMap && !products && !productsErr) {
            if(productsMap) {
                this.setOptions(user.roundedProducts.productSets, user.manifest.products, productsMap);
            }
        }

        if(productsMap && customer && !products && !this.state.manifest && !productsErr) {
            this.setOptions(user.roundedProducts.productSets, user.manifest.products, productsMap);
            this.setState({manifest: user.manifest});
        }

        if(customer && loading) {
            this.setState({loading: false})
        }

        if(this.state.updating && !updatingManifest ) {
            if(putManifestSuccess) {
                setTimeout(() => {
                    this.setState({updating: false, edits: false});
                    this.props.clearOrdersState();
                }, 3000);
            }
            if(putManifestError) {
                setTimeout(() => {
                    this.setState({error: putManifestError});
                    this.setState({updating: false});
                    this.props.clearOrdersState();
                }, 3000);
            }
        }
        if(getUserError && !this.state.error) {
            this.setState({error: getUserError});
            this.props.clearCustomerState(true);
            Auth.signOut().then(() => {
                this.props.history.push('/login');
            })
        }

        if(productGroups && !this.state.productGroups) {
            this.setState({productGroups});
            this.setProductsMap(productGroups);
        }
        if(putInterviewSuccess && this.state.manifest) {
            this.props.getUser();
            this.setState({manifest: undefined, customer: undefined});
            this.props.clearInterviews();
        }
        if(putInterviewError && !this.state.putInterviewError) {
            this.setState({putInterviewError});
            Sentry.captureException(putManifestError);
            console.error(putManifestError);
        }
    }

    setSubtotal(products) {
        try {
            let subtotal = 0;
            Object.values(products).forEach(product => {
                const {edited} = product;
                if(Object.values(edited).length) {
                    const {articles, pricePerArticle} = edited;
                    subtotal += (articles * pricePerArticle / 100);
                }
            })
            this.setState({subtotal})
            return subtotal;
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    setProductsMap(productGroups) {
        const productsMap = {};
        productGroups.map(pg => {
            productsMap[pg.groupName] = {};
            productsMap[pg.groupName].groupName = pg.groupName;
            productsMap[pg.groupName].brandName = pg.brandName;
            productsMap[pg.groupName].displayName = pg.displayName;
            productsMap[pg.groupName].pricePerArticle = pg.pricePerArticle;
            productsMap[pg.groupName].articleType = pg.articleType;
            productsMap[pg.groupName].badges = pg.badges;
            productsMap[pg.groupName].imageUrl = pg.imageUrl;
            productsMap[pg.groupName].description = pg.description;
            productsMap[pg.groupName].productType = pg.productType;
            productsMap[pg.groupName].possibleArticleCounts = pg.possibleArticleCounts;
            productsMap[pg.groupName].productType = pg.productType;
        })
        this.setState({productsMap});
    }

    increment(selectedProduct) {
        const {products, quantityChanges} = this.state;
        try {
            const {possibleArticleCounts} = selectedProduct;
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);
            const proposedArticles = selectedProduct.proposedArticles ? selectedProduct.proposedArticles : possibleArticleCounts[0];
            const max = selectedProduct.productType.toLowerCase() === "toiletpaper" || selectedProduct.productType.toLowerCase() === "papertowels" ? 2 : 1.25;
            if(possibleArticleCounts.length <= index + 1 || possibleArticleCounts[index + 1] > (proposedArticles * max)) {
                const quantityLimitReached = `${selectedProduct.brandName} ${selectedProduct.displayName}`
                this.setState({quantityLimitReached});
                setTimeout(() => {
                    this.setState({quantityLimitReached: ""});
                }, 3000);
            } else {
                if(!quantityChanges[selectedProduct.groupName]) {
                    quantityChanges[selectedProduct.groupName] = {};
                    quantityChanges[selectedProduct.groupName].originalCount = selectedProduct.articles;
                    quantityChanges[selectedProduct.groupName].productType = selectedProduct.productType;
                }
                selectedProduct.articles = possibleArticleCounts[index + 1];
                products[selectedProduct.productType].edited = selectedProduct;
                quantityChanges[selectedProduct.groupName].newCount = selectedProduct.articles;
                this.setState({products, quantityChanges, quantityLimitReached: false, edits: true});
                this.setSubtotal(products)
            }
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    decrement(selectedProduct) {
        const {products, quantityChanges} = this.state;
        try {
            const {possibleArticleCounts} = selectedProduct;
            const index = possibleArticleCounts.indexOf(selectedProduct.articles);
            // add groupName and original quantity to quantityChanges obj if it is not already present
            if(!quantityChanges[selectedProduct.groupName]) {
                quantityChanges[selectedProduct.groupName] = {};
                quantityChanges[selectedProduct.groupName].originalCount = selectedProduct.articles;
                quantityChanges[selectedProduct.groupName].productType = selectedProduct.productType;
            }
            if(index > 0) selectedProduct.articles = possibleArticleCounts[index - 1];
            else selectedProduct.articles = 0;

            quantityChanges[selectedProduct.groupName].newCount = selectedProduct.articles;
            products[selectedProduct.productType].edited = selectedProduct;
            this.setState({products, quantityChanges, quantityLimitReached: false, edits: true});
            this.setSubtotal(products)
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    updateSelection(selectedProduct) {
        const {products} = this.state;
        products[selectedProduct.productType].edited = selectedProduct;

        const changedProduct = {};
        changedProduct.groupName = selectedProduct.groupName;
        this.setSubtotal(products)
        this.setState({products, changedProduct, edits: true});
    }

    clearLimits() {
        this.setState({quantityLimitReached: false});
    }

    confirmChanges() {
        const { products, productChanges, quantityChanges } = this.state;
        if (products) {
            const order = {
                products: {}
            };

            Object.entries(products).map(entry => {
                products[entry[0]].selected = products[entry[0]].edited;
                const {selected} = entry[1];
                if(Object.values(selected).length) {
                    order.products[selected.groupName] = {articleCount: selected.articles, quantityChangeReason: "None"};
                    
                    if(quantityChanges[selected.groupName]) {
                        if(quantityChanges[selected.groupName].originalCount === quantityChanges[selected.groupName].newCount) {
                            delete quantityChanges[selected.groupName];
                        } else {
                            quantityChanges[selected.groupName].incremented = quantityChanges[selected.groupName].newCount > quantityChanges[selected.groupName].originalCount;
                        }
                    }
                }
            });
            
            
            Object.entries(order.products).map(entry => {
                const groupName = entry[0];
                if(productChanges[groupName]) {
                    entry[1].productChangeReason = productChanges[groupName];
                } else {
                    entry[1].productChangeReason = "None";
                }
            })

            if(Object.values(quantityChanges).length) {
                this.setState({quantityChanges, order, productChanges: {}});
            } else {
                this.props.putManifest(order.products);

                this.setState({products, updating: true, order: undefined, quantityChanges: {}, productChanges: {}}); 
            }
        }
    }  
    
    cancelChanges() {
        const {products} = this.state;
        
        Object.entries(products).map(entry => {
            products[entry[0]].edited = products[entry[0]].selected;
        })
        this.setState({edits: false, products})
    }

    setOptions(rounder, manifest, productsMap) {
        try {
            const products = {};
            Object.entries(rounder).map(entry => {
                const {preferred, additional} = entry[1];
                if(!productsMap[preferred.groupName]) productsMap[preferred.groupName] = {};
                productsMap[preferred.groupName].preferred = true;

                additional.forEach(p => {
                    productsMap[p.groupName].proposedArticles = p.proposedArticles;
                    if(p.proposedArticles > productsMap[p.groupName].possibleArticleCounts[productsMap[p.groupName].possibleArticleCounts.length - 1]) {
                        productsMap[p.groupName].possibleArticleCounts.push(p.proposedArticles);
                    }
                    if(productsMap[p.groupName].possibleArticleCounts.indexOf(p.proposedArticles) < 0) {
                        productsMap[p.groupName].possibleArticleCounts.push(p.proposedArticles);
                        productsMap[p.groupName].possibleArticleCounts.sort((a,b) => {return a-b})
                    }
                })

                if(preferred.proposedArticles > productsMap[preferred.groupName].possibleArticleCounts[productsMap[preferred.groupName].possibleArticleCounts.length - 1]) {
                    productsMap[preferred.groupName].possibleArticleCounts.push(preferred.proposedArticles)
                } 
                if(productsMap[preferred.groupName].possibleArticleCounts.indexOf(preferred.proposedArticles) < 0) {
                    productsMap[preferred.groupName].possibleArticleCounts.push(preferred.proposedArticles);
                    productsMap[preferred.groupName].possibleArticleCounts.sort(function(a, b){return a-b});
                }

                productsMap[preferred.groupName].articles = preferred.proposedArticles;
                productsMap[preferred.groupName].proposedArticles = preferred.proposedArticles;
                products[entry[0]] = {
                    selected: {},
                    edited: {},
                    preferred: productsMap[preferred.groupName],
                    additional: [...additional, preferred]
                };
                additional.map(p => {
                    productsMap[p.groupName].articles = p.proposedArticles
                })
            });    
            Object.entries(manifest).map(entry => {
                const p = entry[1];
                productsMap[p.groupName].articles = p.articleCount;
                if(p.articleCount > productsMap[p.groupName].possibleArticleCounts[productsMap[p.groupName].possibleArticleCounts.length - 1]) {
                    productsMap[p.groupName].possibleArticleCounts.push(p.articleCount)
                } 
                if(productsMap[p.groupName].possibleArticleCounts.indexOf(p.articleCount) < 0) {
                    productsMap[p.groupName].possibleArticleCounts.push(p.articleCount);
                    productsMap[p.groupName].possibleArticleCounts.sort(function(a, b){return a-b});
                }
                productsMap[p.groupName].moreThanAMonth = p.moreThanAMonth;
                if(!products[entry[0]]) products[entry[0]] = {selected: {}, edited: {}, preferred: {}, additional: []};
                products[entry[0]].selected = productsMap[p.groupName];
                products[entry[0]].edited = productsMap[p.groupName];
                const index = products[entry[0]].additional.map( item => { return item.groupName }).indexOf(p.groupName);
                if(index < 0) {
                    products[entry[0]].additional.push(productsMap[p.groupName]);
                }
            })
            this.setState({products, productsMap, newOptions: true});
            this.setSubtotal(products);
        } catch(err) {
            Sentry.addBreadcrumb({
                category: "rounder",
                message: rounder,
                level: Sentry.Severity.Info
            })
            Sentry.addBreadcrumb({
                category: "manifest",
                message: manifest,
                level: Sentry.Severity.Info
            })
            Sentry.addBreadcrumb({
                category: "productsMap",
                message: productsMap,
                level: Sentry.Severity.Info
            })
            Sentry.captureException(err);
            console.error(err);
            this.setState({productsErr: err})
        }
    }

    handleQuantityChangeReason(groupName, reason) {
        const {quantityChanges} = this.state;
        try {
            quantityChanges[groupName].reason = reason;
            this.setState({quantityChanges})
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    handleProductChangeReason(reason) {
        const {changedProduct} = this.state;
        changedProduct.reason = reason;
        this.setState({changedProduct});
    }

    validateQuantityModal() {
        this.clearLimits();
        const {quantityChanges, order} = this.state;

        let needReasons = false;

        for(let i = 0; i < Object.values(quantityChanges).length; i++) {
            if(!Object.values(quantityChanges)[i].reason) {
                needReasons = true;
                break;
            }
        }

        if(needReasons) {
            this.setState({needReasons});
        } else {
            try {
                Object.entries(quantityChanges).map(entry => {
                    const groupName = entry[0];
                    if(order.products[groupName]) {
                        const {reason} = entry[1];
                        order.products[groupName].quantityChangeReason = reason;
                    }
                })

                this.props.putManifest(order.products);
                this.setState({quantityChanges: {}, order: undefined, edits: false, updating: true});
            } catch (err) {
                Sentry.captureException(err);
                console.error(err);
            }
        }
    }

    submitProductChange() {
        this.clearLimits();
        const {changedProduct, productChanges} = this.state;
        if(!productChanges[changedProduct.groupName]) productChanges[changedProduct.groupName] = {};
        productChanges[changedProduct.groupName] = changedProduct.reason;
        this.setState({changedProduct: undefined, productChanges});
    }

    renderQuantityLimitReached() {
        const {quantityLimitReached} = this.state;
        if(quantityLimitReached) {
            return (
                <Alert variant="warning" className="alert-fixed-bottom">Maximum quantity reached for {quantityLimitReached}</Alert>
            )
        }
    }

    renderQuantityModal() {
        const {quantityChanges, needReasons, order} = this.state;
        if(quantityChanges && order && Object.values(quantityChanges).length) {
            const rows = [];
            Object.entries(quantityChanges).map(entry => {
                const {incremented, reason, productType} = entry[1];
                const groupName = entry[0];
                rows.push(
                    <div className="modal-prediction-question">
                        <div className="prediction-modal-header">
                            We are in beta and your feedback helps us tune your recommendation. Please let us know why you need {incremented ? "more" : "less"} {formatType(productType).toLowerCase()}?
                        </div>
                        <div className="prediction-modal-form">
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(groupName, incremented ? "EstimateTooLow" : "EstimateTooHigh")}>
                                <div className={`prediction-modal-radio ${reason === "EstimateTooLow" || reason === "EstimateTooHigh" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I use {incremented ? "more" : "less"}</label>
                            </div>
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(groupName, incremented ? "PoorlyStocked" : "WellStocked")}>
                                <div className={`prediction-modal-radio ${reason === "PoorlyStocked" || reason === "WellStocked" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I'm {incremented ? "totally out" : "well stocked"}</label>
                            </div>
                            <div className="prediction-modal-form-row" onClick={() => this.handleQuantityChangeReason(groupName, incremented ? "HavingGuests" : "Traveling")}>
                                <div className={`prediction-modal-radio ${reason === "HavingGuests" || reason === "Traveling" ? "selected" : ""}`}></div>
                                <label className="prediction-modal-label">I'm {incremented ? "having guests" : "traveling"}</label>
                            </div>
                        </div>
                    </div>
                )
            })
            return (
                <div className="survey-modal-container">
                    <div className="signin-modal-body">
                        <div className="signin-modal-tab-container">
                            <div className="prediction-modal-body">
                                {rows}
                                <Alert variant="warning" className={needReasons ? "signin-modal-alert" : "hide"}>Please select a response for each.</Alert>
                            </div>
                        </div>
                        <button className="interview-continue-button" onClick={this.validateQuantityModal}>Submit</button>
                    </div>
                </div>
            )
        }
    }

    renderProductChangeModal() {
        const {changedProduct} = this.state;
        if(changedProduct) {
            return (
                <div className="modal-container light">
                    <div className="signin-modal-body">
                            <div className="prediction-modal-body">
                                <div className="modal-prediction-question">
                                    <div className="prediction-modal-header">
                                        We are in beta and your feedback helps us tune your recommendation. Please let us know why you made this product change.
                                    </div>
                                    <div className="prediction-modal-form">
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("AlreadyUse")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "AlreadyUse" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">I already use this brand and want to stick with it</label>
                                        </div>
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("WantToTry")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "WantToTry" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">I have heard of this brand and want to try it</label>
                                        </div>
                                        <div className="prediction-modal-form-row" onClick={() => this.handleProductChangeReason("TooExpensive")}>
                                            <div className={`prediction-modal-radio ${changedProduct.reason === "TooExpensive" ? "selected" : ""}`}></div>
                                            <label className="prediction-modal-label">The recommended brand was too expensive</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <button disabled={!changedProduct.reason} className="interview-continue-button" onClick={this.submitProductChange}>Submit</button>
                    </div>
                </div>
            )
        }
    }

    renderManifest() {
        const {
            manifest,
            isTax,
            products,
            productsMap,
            edits,
            addingProducts,
            subtotal,
            productsErr
        } = this.state;
        if(products && manifest && !addingProducts) {
            const rows = [];
            rows.push(
                <Order 
                    key={0}
                    productTypes={products}
                    productsMap={productsMap}
                    deliverAt={manifest.deliverAt}
                    updateSelection={this.updateSelection}
                    showDescription={this.showDescription}
                    increment={this.increment}
                    decrement={this.decrement}
                    edits={edits}
                    clearLimits={this.clearLimits}
                    subtotal={subtotal}
                    addProducts={() => {this.setState({addingProducts: true})}}
                    confirmChanges={this.confirmChanges}
                    cancelChanges={this.cancelChanges}
                    removeProduct={this.setProductToRemove}
                    isTax={isTax}
                />
            )
            return (
                <div className="col-12">
                    <div className="space-bottom lg">
                        {rows}
                    </div>
                </div>
            )
        } else if(productsErr) {
            return(
                <Alert variant="danger" className="checkout-error text-center">
                    Something went wrong.<br/>Please contact Supply Drop support for further assistance.<br/>
                    (205) 583-6916 
                </Alert>
            )
        }
    }

    addProducts(products) {
        this.setState({products, addingProducts: false, edits: true});
        this.setSubtotal(products);
    } 

    renderAddProducts() {
        const {
            interview,
            newOptions,
            products,
            productsMap,
            subtotal,
            addingProducts
        } = this.state;

        if(addingProducts) {
            return (
                <AddProducts 
                    key={0}
                    productTypes={products}
                    productsMap={productsMap}
                    updateSelection={this.updateSelection}
                    showDescription={this.showDescription}
                    updateInterview={this.updateInterview}
                    newOptions={newOptions}
                    clearNewOptions={() => this.setState({newOptions: false})}
                    subtotal={subtotal}
                    interview={interview}
                    addProducts={this.addProducts}
                    cancelChanges={() => this.setState({addingProducts: false})}
                />
            )
        }
    }

    updateInterview(interview) {
        const {puttingInterview} = this.props;
        if(!puttingInterview) {
            this.setState({interview});
            this.props.putInterview(interview);
        }
    }

    showDescription(product) {
        this.setState({showProduct: product})
    }

    renderProductDetail() {
        const {showProduct} = this.state;
        if(showProduct) {
            return <ProductDetail product={showProduct} close={() => {this.setState({showProduct: undefined})}} />
        }
    }

    setProductToRemove(product) {
        this.setState({toRemove: product})
    }

    setRemovalPeriod(setToZero) {
        this.setState({setToZero})
    }

    cancelProductRemoval() {
        this.setState({toRemove: undefined})
    }

    removeProduct() {
        const {products, toRemove, setToZero, quantityChanges} = this.state;
        let {removedCount} = this.state;
        try {
            if(setToZero) {
                products[toRemove.productType].edited.articles = 0;
                if(!quantityChanges[toRemove.groupName]) {
                    quantityChanges[toRemove.groupName] = {};
                    quantityChanges[toRemove.groupName].originalCount = toRemove.articles;
                    quantityChanges[toRemove.groupName].productType = toRemove.productType;
                }
            } 
            else {
                if (quantityChanges[toRemove.groupName]) delete quantityChanges[toRemove.groupName];
                products[toRemove.productType].edited = {};
            }
            removedCount++;
            this.setSubtotal(products);
            this.setState({products, edits: true, setToZero: undefined, toRemove: undefined, removedCount})
        } catch(err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderWelcome() {
        const {customer, addingProducts} = this.state;
        if(customer && !addingProducts) {
            return (
                <div>
                    <Welcome customer={customer} />
                </div>
            )
        }
    }

    renderRemoveModal() {
        const {toRemove, setToZero, removedCount} = this.state;
        if(toRemove) {
            return (
                <div className="modal-container transparent">
                    <div className="interview-question-modal">
                        <img onClick={this.cancelProductRemoval} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                        <div className="interview-modal-body$ no-padding">
                        <div className="signin-modal-tab-container">
                            <div className="cancel-modal-body">
                                <p className="modal-title">
                                    Remove {formatType(toRemove.productType)}
                                </p>
                                <hr />
                                <div className="prediction-modal-form">
                                    <div className="prediction-modal-form-row" onClick={() => this.setRemovalPeriod(true)}>
                                        <div className={`prediction-modal-radio ${setToZero ? "selected" : ""}`}></div>
                                        <label className="prediction-modal-label">From this order only</label>
                                    </div>
                                    <div className="prediction-modal-form-row" onClick={() => this.setRemovalPeriod(false)}>
                                        <div className={`prediction-modal-radio ${setToZero === false ? "selected" : ""}`}></div>
                                        <label className="prediction-modal-label">From this order and all future orders</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cancel-modal-button-row">
                            <button className="dashboard-btn dashboard-submit white" onClick={this.cancelProductRemoval}>Cancel</button>
                            <button disabled={setToZero === undefined} className="dashboard-btn dashboard-cancel-btn" onClick={this.removeProduct}>Remove</button>
                        </div>
                        <div className={removedCount ? "mobile-note space-top" : "hide"}>
                            Are you trying to pause or cancel your subscription? Please text Supply Drop support for further assistance @ <b>(205) 583-6916 </b>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }

    renderLoadingModal() {
        const {loading} = this.state;
        if(loading) {
            return (
                <Loading />
            )
        }
    }

    renderPutModal() {
        const {updating} = this.state;
        const {putManifestError, putManifestSuccess} = this.props;

        if (updating) {
            return (
                <Saving success={putManifestSuccess} error={putManifestError} />
            )
        }
    }
                
    render() {
        const {signedIn, customer, addingProducts} = this.state;

        if(signedIn) {
            return (
                <div className="no-cloud">
                    {this.renderQuantityModal()}
                    {this.renderProductChangeModal()}
                    {this.renderProductDetail()}
                    {this.renderPutModal()}
                    <Menu history={this.props.history} signedIn={() => {this.setState({signedIn: true})}} />
                    <div id="dashboard-body-container">
                        {this.renderWelcome()}
                        {this.renderLoadingModal()}
                        <div className={`dashboard-orders flex-wrap${addingProducts ? ' add-products' : ''}`}>
                            {this.renderAddProducts()}
                            {this.renderManifest()}
                            {this.renderRemoveModal()}
                            {this.renderQuantityLimitReached()}
                        </div>
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <Menu history={this.props.history} />
                    <div id="dashboard-body-container">
                        {this.renderLoadingModal()}
                    </div>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError } = state.user;
    const { productGroups, productGroupsError } = state.product;
    const {putInterviewError, puttingInterview, putInterviewSuccess} = state.interview;
    const { manifest, getManifestError, putManifestError, putManifestSuccess, updating } = state.order;
    return { 
        user,
        manifest,
        getManifestError,
        putManifestError,
        putManifestSuccess,
        getUserError,
        updating,
        productGroups,
        productGroupsError,
        putInterviewError,
        puttingInterview,
        putInterviewSuccess
    };
}

export default connect(mapStateToProps, {
    getUser,
    getManifest,
    putManifest,
    clearOrdersState,
    clearCustomerState,
    getProductGroups,
    putInterview,
    clearInterviews
})(Dashboard);