import React, { Component } from 'react';
import Feedback from '../Feedback';
import images from '../../../img/images';
import Header from '../../Header';
import ProgressBar from '../ProgressBar';
import Counter from '../Counter';
import MoreInfo from '../MoreInfo';
import Countdown from '../../Countdown';
import { marginForCountdown } from '../../../actions';

class Cooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            error: '',
            cookingFrequency: 1,
            moreInfoCopy: (
                <div>
                    The amount you cook has a direct impact on the amount of kitchen supplies you use.
				</div>
            )
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.continue = this.continue.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        const { showInfo } = this.state;
        if (e.keyCode && e.keyCode === 13) {
            if (showInfo) {
                this.setState({ showInfo: false });
            } else {
                this.continue();
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({ isModal: this.props.isModal });

        const cookingFrequency = JSON.parse(sessionStorage.getItem('cookingFrequency'));
        if (cookingFrequency) {
            this.setState({ cookingFrequency });
        }

		const zipcode = sessionStorage.getItem('zipcode') ? JSON.parse(sessionStorage.getItem('zipcode')) : null;
        this.setState({zipcode})

        if(zipcode) marginForCountdown();
    }

    continue() {
        const { cookingFrequency, isModal } = this.state;
        sessionStorage.setItem('cookingFrequency', JSON.stringify(cookingFrequency));
        if (isModal) this.props.next({ cookingFrequency })
        else this.props.history.push('/kitchen/2');
    }

    decrement() {
        let { cookingFrequency } = this.state;
        if (cookingFrequency > 0) cookingFrequency -= 1;
        this.setState({ cookingFrequency })
    }

    increment() {
        let { cookingFrequency } = this.state;
        cookingFrequency += 1;
        this.setState({ cookingFrequency })
    }

    renderTopRow() {
        const { isModal } = this.state;
        if (!isModal) {
            return (
                <div className="interview-nav-container">
                    <div className="interview-top-row">
                        <img src={images.back_arrow} onClick={() => this.props.history.push('/start/5')} className="interview-icon interview-back-arrow" />
                        <img src={images.info} onClick={() => this.setState({ showInfo: true })} className="interview-icon interview-info-icon" />
                    </div>
                </div>
            )
        }
    }

    renderContinue() {
        const { isModal } = this.state;
        if (isModal) {
            return (
                <div className="interview-modal-button-row">
                    <button className="dashboard-btn dashboard-submit white" onClick={this.props.back}>Back</button>
                    <button className="dashboard-btn dashboard-save" onClick={this.continue}>Continue</button>
                </div>
            )
        } else {
            return (
                <div className="interview-continue-wrapper">
                    <button className="interview-continue-button" onClick={this.continue}>Continue</button>
                    <p className="interview-enter-text">press <b>enter</b></p>
                </div>
            )
        }
    }

    renderInfo() {
        const { showInfo, moreInfoCopy } = this.state;
        if (showInfo) {
            return (
                <MoreInfo close={() => this.setState({ showInfo: false })} copy={moreInfoCopy} />
            )
        }
    }

    renderHeader() {
        const { isModal, zipcode } = this.state;
        if (!isModal) return <Header history={this.props.history} spaceTopLg={zipcode} />
    }

    renderProgressBar() {
        const { isModal } = this.state;
        if (!isModal) return <ProgressBar progress={75} />
    }

    renderFeedback() {
        const { isModal } = this.state;
        if (!isModal) return <Feedback question="cookingFrequency" />
    }

    renderCountdown() {
        if(this.state.zipcode && !this.state.isModal) return <Countdown spaceTop={true} />
    }

    render() {
        const { cookingFrequency, isModal } = this.state;
        return (
            <div>
                {this.renderProgressBar()}
                {this.renderInfo()}
                {this.renderCountdown()}
                {this.renderHeader()}
                <div className={isModal ? 'interview-modal-outer' : 'interview-outer'}>
                    {this.renderTopRow()}
                    <div className={`${isModal ? 'interview-modal-inner' : 'interview-inner'} fadeInRight animated-fast text-center`}>
                        <div className="interview-header-img"><img src={images.cookingFrequency} alt="cooking" /></div>
                        <p className="interview-copy">How many nights a week do you cook at home?</p>
                        <Counter type="interview" min={0} max={7} count={cookingFrequency} increment={this.increment} decrement={this.decrement} />
                        {this.renderContinue()}
                    </div>
                    {this.renderFeedback()}
                </div>
            </div>
        );
    }
}

export default Cooking;