import React, {Component, Fragment} from 'react';
import * as Sentry from '@sentry/browser';
import {postFeedback} from '../../actions';
import { connect } from 'react-redux';
import images from '../../img/images';

class Feedback extends Component{
    constructor(props) {
        super(props);

        this.state = {
            feedback: '',
            liked: true
        };

        this.sendFeedback = this.sendFeedback.bind(this);
        this.close = this.close.bind(this);
    }

    handleChange(change) {
        this.setState({feedback: change});
    }

    componentDidUpdate() {
        const {feedback, error, dashboard, showFeedback} = this.props;
        if(this.state.loading && feedback) {            
            setTimeout(() => {
                if(dashboard) this.closeDashboardFeedback()
                this.setState({loading: false, active: false});
            }, 3000);
        }

        if(this.state.loading && error) {
            setTimeout(() => {
                if(dashboard) this.closeDashboardFeedback()
                this.setState({loading: false, active: false});
            }, 3000);
            console.error(error);
        }
        if(dashboard && showFeedback !== this.state.active) {
            this.setState({active: showFeedback})
        }
    }

    close() {
        const {dashboard} = this.props;
        if(dashboard)
            this.closeDashboardFeedback();
        else
            this.setState({active: false});
    }

    sendFeedback() {
        const {feedback, liked} = this.state;
        const {question} = this.props;
        if(feedback) {
            this.props.postFeedback(`${question} ${new Date()}`, liked, feedback);
            this.setState({loading: true, feedback: ""})
        }
    }

    closeDashboardFeedback() {
        this.props.close();
    }

    render() {
        const {active, loading} = this.state;
        const {dashboard, question} = this.props;

        if(!active && !dashboard) {
            return (
                <div className={`feedback-container${question === "order-complete" ? " complete" : ""}`}>
                    <div className="feedback-btn-container">
                        <div onClick={() => this.setState({active: true})} className="interview-feedback-btn"><span className="interview-feedback-icon">[+]</span>FEEDBACK</div>
                    </div>
                </div>
            )
        } else if (!active && dashboard) {
            return <div></div>
        } else {
            if(loading) {
                return (
                    <div className="modal-container light">
                        <div className="interview-inner feedback rounded">
                            <div className="interview-feedback-container">
                                <h2 className="interview-question-header">Thank you for your feedback!</h2>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="modal-container light">
                        <div className="interview-outer">
                            <div className="interview-nav-container">
                                <div className="interview-top-row">
                                    <div></div>
                                    <img src={images.x_icon} onClick={this.close} className="interview-icon interview-info-icon" />
                                </div>
                            </div>
                            <div className="interview-inner feedback">
                                <div className="interview-feedback-container">
                                    <div className="interview-copy-container">
                                        <p className="interview-copy small">
                                            Part of being in Beta is learning a lot from our customers.
                                            Please provide any feedback on the questions and sign-up process.
                                            And yes, we want to hear it all!
                                        </p>
                                        <textarea 
                                            type="text"
                                            className="interview-feedback-input"
                                            placeholder="I love it so far..."
                                            value={this.state.feedback}
                                            onChange={(e) => this.handleChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="interview-continue-wrapper">
                                    <button onClick={this.sendFeedback} className="dashboard-btn dashboard-submit">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
	const { feedback, error } = state.interview;
	return { feedback, error };
}

export default connect(mapStateToProps, {
	postFeedback
})(Feedback);
