import React, {Component} from 'react';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';
import images from '../../img/images';

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameValid: true,
            addressValid: true,
            zipcodeValid: true,
            cityValid: true
        };

        this.updateBilling = this.updateBilling.bind(this);
        this.cancelBillingEdits = this.cancelBillingEdits.bind(this);
    }

    componentDidMount() {
        const {customer} = this.props;
        const {paymentSource, shipping} = customer;
        this.setState({customer, shipping});

        if(paymentSource && !this.state.card) {
            this.setState({card: paymentSource});
        }
    }

    componentDidUpdate() {
        const {
            editing,
            states
        } = this.props;
        if(editing !== this.state.editing) {
            this.setState({ editing });
        }
        if(states && !this.state.states) {
            this.setState({states});
        }
    }

    cancelBillingEdits() {
        this.setState({sourceError: undefined})
        this.props.cancel();
    }

    async updateBilling() {
        const {
            customer
        } = this.state;
        this.setState({sourceError: undefined})

        const {source} = await this.props.stripe.createSource({
            type: 'card',
            owner: {
                email: customer.email.toLowerCase(),
                phone: customer.phone,
            }
        });

        if(source) {
            this.props.updateSource(source.id);
        } else {
            this.setState({sourceError: true});
        }
    }

    renderError() {

    }
    
    render() {
        const { sourceError } = this.state;
        return (
            <div className="modal-container transparent">
                <div className="interview-question-modal">
                    <img onClick={this.cancelBillingEdits} className="raised text-right order-x-icon" src={images.btn_close_x} alt="close" />
                    <div className="interview-modal-body$ no-padding">
                        <div className="signin-modal-tab-container">
                            <div className="cancel-modal-body">
                                <p className="modal-title">
                                    Update Payment Info
                                </p>
                                <hr />
                                <div className="modal-input-container full">
                                    <label className="modal-label">Credit Card</label>
                                    <CardElement className={`stripe-input${sourceError ? " invalid" : ""}`} />
                                </div>
                                {this.renderError()}
                            </div>
                            <div className="cancel-modal-button-row">
                                <button onClick={this.cancelBillingEdits} className="dashboard-btn dashboard-submit white">Cancel</button>
                                <button onClick={this.updateBilling} className="dashboard-btn dashboard-save">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectStripe(StripeForm);